
import login$LoginLayout from '@/section/login/layouts/LoginLayout.vue';
import partnerapp$RootLayout from '@/section/partnerapp/layouts/RootLayout.vue';
import partnerapp$PartnerLayout from '@/section/partnerapp/layouts/PartnerLayout.vue';
import partnerapp$InstitutionalPartnerLayout from '@/section/partnerapp/layouts/InstitutionalPartnerLayout.vue';
import partnerapp$AdminLayout from '@/section/partnerapp/layouts/AdminLayout.vue';
import affiliateapp$ClientLayout from '@/section/affiliateapp/layouts/ClientLayout.vue';
import affiliateapp$CFTaskDetailsLayout from '@/section/affiliateapp/layouts/CFTaskDetailsLayout.vue';
import affiliateapp$CFTaskLayout from '@/section/affiliateapp/layouts/CFTaskLayout.vue';
import affiliateapp$CFileLayout from '@/section/affiliateapp/layouts/CFileLayout.vue';
import affiliateapp$TaskListLayout from '@/section/affiliateapp/layouts/TaskListLayout.vue';
import affiliateapp$CFNoteLayout from '@/section/affiliateapp/layouts/CFNoteLayout.vue';
import affiliateapp$PartnerAffiliateLayout from '@/section/affiliateapp/layouts/PartnerAffiliateLayout.vue';
import affiliateapp$UserLayout from '@/section/affiliateapp/layouts/UserLayout.vue';
import login$Login from '@/section/login/views/Login.vue';
import login$AffiliateLogin from '@/section/login/views/AffiliateLogin.vue';
import partnerapp$ForgotPassword from '@/section/partnerapp/views/ForgotPassword.vue';
import partnerapp$ResetPassword from '@/section/partnerapp/views/ResetPassword.vue';
import partnerapp$PartnerCalculator from '@/section/partnerapp/views/PartnerCalculator.vue';
import partnerapp$Home from '@/section/partnerapp/views/Home.vue';
import partnerapp$Dashboard from '@/section/partnerapp/views/Dashboard.vue';
import partnerapp$PartnerLearningvideo from '@/section/partnerapp/views/PartnerLearningvideo.vue';
import partnerapp$UploadDocuments from '@/section/partnerapp/views/UploadDocuments.vue';
import partnerapp$Onboarding from '@/section/partnerapp/views/Onboarding.vue';
import partnerapp$PartnerCertificate from '@/section/partnerapp/views/PartnerCertificate.vue';
import partnerapp$PartnerProfile from '@/section/partnerapp/views/PartnerProfile.vue';
import partnerapp$ClientFileInfo from '@/section/partnerapp/views/ClientFileInfo.vue';
import partnerapp$PartnerProfileUpdate from '@/section/partnerapp/views/PartnerProfileUpdate.vue';
import partnerapp$SuccessRequest from '@/section/partnerapp/views/SuccessRequest.vue';
import partnerapp$ClientFileSearch from '@/section/partnerapp/views/institution/ClientFileSearch.vue';
import partnerapp$ClientFileLookup from '@/section/partnerapp/views/institution/ClientFileLookup.vue';
import partnerapp$InstitutionDashboard from '@/section/partnerapp/views/institution/InstitutionDashboard.vue';
import partnerapp$InstitutionClientFileInfo from '@/section/partnerapp/views/institution/InstitutionClientFileInfo.vue';
import partnerapp$MonthlySales from '@/section/partnerapp/views/institution/MonthlySales.vue';
import partnerapp$UnregisteredCustomers from '@/section/partnerapp/views/institution/UnregisteredCustomers.vue';
import partnerapp$MonthlyCollection from '@/section/partnerapp/views/institution/MonthlyCollection.vue';
import partnerapp$AdminDashboard from '@/section/partnerapp/views/AdminDashboard.vue';
import affiliateapp$CFActiveTasks from '@/section/affiliateapp/views/file/task/CFActiveTasks.vue';
import affiliateapp$CFLeftNav from '@/section/affiliateapp/views/file/drp/CFLeftNav.vue';
import affiliateapp$CFSummary from '@/section/affiliateapp/views/file/drp/CFSummary.vue';
import affiliateapp$ChangePassword from '@/section/affiliateapp/views/user/ChangePassword.vue';
import affiliateapp$TaskPool from '@/section/affiliateapp/views/task/TaskPool.vue';
import affiliateapp$MyCFFiles from '@/section/affiliateapp/views/MyCFFiles.vue';
import affiliateapp$CFNoteHighlightList from '@/section/affiliateapp/views/file/notes/CFNoteHighlightList.vue';
import affiliateapp$DRPPaymentCalculator from '@/section/affiliateapp/views/calculator/DRPPaymentCalculator.vue';
import affiliateapp$CFFileRedirect from '@/section/affiliateapp/views/file/CFFileRedirect.vue';
import affiliateapp$TaskCompleted from '@/section/affiliateapp/views/task/TaskCompleted.vue';
import affiliateapp$CFCompletedTasks from '@/section/affiliateapp/views/file/task/CFCompletedTasks.vue';
import affiliateapp$CFTaskRedirect from '@/section/affiliateapp/views/file/task/CFTaskRedirect.vue';
import affiliateapp$TaskAssignedToMe from '@/section/affiliateapp/views/task/TaskAssignedToMe.vue';
import affiliateapp$CFTaskDetails from '@/section/affiliateapp/views/file/task/CFTaskDetails.vue';
import affiliateapp$CFNoteAllList from '@/section/affiliateapp/views/file/notes/CFNoteAllList.vue';
import affiliateapp$AffiliateHome from '@/section/affiliateapp/views/AffiliateHome.vue';
import affiliateapp$TaskSuspended from '@/section/affiliateapp/views/task/TaskSuspended.vue';
import affiliateapp$RegisterClient from '@/section/affiliateapp/views/client/RegisterClient.vue';

// tslint:disable
export default [

 { path: '/login', meta : {},   components : { default : login$LoginLayout} ,
	children : [
 { path: '', meta : {},  name: 'Login.Login', components : { default : login$Login}  }, { path: 'affiliate', meta : {},  name: 'Login.AffiliateLogin', components : { default : login$AffiliateLogin}  }
] },
{ path: '/', meta : {},   components : { default : partnerapp$RootLayout} ,
	children : [
 { path: '', redirect: { name : 'Root.Home'}}, { path: 'home', meta : {},  name: 'Root.Home', components : { default : partnerapp$Home}  }, { path: 'calculator', meta : {},  name: 'Root.PartnerCalculator', components : { default : partnerapp$PartnerCalculator}  }, { path: 'forgot-password', meta : {},  name: 'Root.ForgotPassword', components : { default : partnerapp$ForgotPassword}  }, { path: 'reset-password', meta : {},  name: 'Root.ResetPassword', components : { default : partnerapp$ResetPassword}  }
] },
{ path: '/partner', meta : {},   components : { default : partnerapp$PartnerLayout} ,
	children : [
 { path: 'onboarding', meta : {},  name: 'Partner.Onboarding', components : { default : partnerapp$Onboarding}  }, { path: 'success-request', meta : {},  name: 'Partner.SuccessRequest', components : { default : partnerapp$SuccessRequest}  }, { path: 'dashboard', meta : {},  name: 'Partner.Dashboard', components : { default : partnerapp$Dashboard}  }, { path: 'profile', meta : {},  name: 'Partner.PartnerProfile', components : { default : partnerapp$PartnerProfile}  }, { path: 'profile-update', meta : {},  name: 'Partner.PartnerProfileUpdate', components : { default : partnerapp$PartnerProfileUpdate}  }, { path: 'certificate', meta : {},  name: 'Partner.PartnerCertificate', components : { default : partnerapp$PartnerCertificate}  }, { path: 'learning-video', meta : {},  name: 'Partner.PartnerLearningvideo', components : { default : partnerapp$PartnerLearningvideo}  }, { path: 'upload-documents', meta : {},  name: 'Partner.UploadDocuments', components : { default : partnerapp$UploadDocuments}  }, { path: 'calculator', meta : {},  name: 'Partner.PartnerCalculator', components : { default : partnerapp$PartnerCalculator}  }, { path: 'client-file-info/:clientFileId', meta : {},  name: 'Partner.ClientFileInfo', components : { default : partnerapp$ClientFileInfo}  }
] },
{ path: '/partner/institution', meta : {},   components : { default : partnerapp$InstitutionalPartnerLayout} ,
	children : [
 { path: 'dashboard', meta : {},  name: 'InstitutionalPartner.InstitutionDashboard', components : { default : partnerapp$InstitutionDashboard}  }, { path: 'client-file-search', meta : {},  name: 'InstitutionalPartner.ClientFileSearch', components : { default : partnerapp$ClientFileSearch}  }, { path: 'client-file-info/:clientFileId', meta : {},  name: 'InstitutionalPartner.InstitutionClientFileInfo', components : { default : partnerapp$InstitutionClientFileInfo}  }, { path: 'monthly-sales', meta : {},  name: 'InstitutionalPartner.MonthlySales', components : { default : partnerapp$MonthlySales}  }, { path: 'monthly-collection', meta : {},  name: 'InstitutionalPartner.MonthlyCollection', components : { default : partnerapp$MonthlyCollection}  }, { path: 'client-file-lookup', meta : {},  name: 'InstitutionalPartner.ClientFileLookup', components : { default : partnerapp$ClientFileLookup}  }, { path: 'unregistered-customers', meta : {},  name: 'InstitutionalPartner.UnregisteredCustomers', components : { default : partnerapp$UnregisteredCustomers}  }
] },
{ path: '/admin', meta : {},   components : { default : partnerapp$AdminLayout} ,
	children : [
 { path: 'admin-dashboard', meta : {},  name: 'Admin.AdminDashboard', components : { default : partnerapp$AdminDashboard}  }
] },
{ path: '/affiliate', meta : {},   components : { default : affiliateapp$PartnerAffiliateLayout} ,
	children : [
 { path: 'my-client-files', meta : {"authenticated":true},  name: 'PartnerAffiliate.MyClientFiles', components : { default : affiliateapp$MyCFFiles}  }, { path: 'home', meta : {"authenticated":true},  name: 'PartnerAffiliate.AffiliateHome', components : { default : affiliateapp$AffiliateHome}  }, { path: 'client/register', meta : {"authenticated":true},  name: 'PartnerAffiliate.RegisterClient', components : { default : affiliateapp$RegisterClient}  }, { path: 'drp/calculator', meta : {},  name: 'PartnerAffiliate.DRPPaymentCalculator', components : { default : affiliateapp$DRPPaymentCalculator}  }, { path: 'cftask/:clientFileNumber/:taskId', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFTaskRedirect', components : { default : affiliateapp$CFTaskRedirect}  }, { path: 'cf/:clientFileNumber', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFFileRedirect', components : { default : affiliateapp$CFFileRedirect}  }, { path: 'client/:clientId', meta : {},   components : { default : affiliateapp$ClientLayout}  }, { path: 'tasks', meta : {},   components : { default : affiliateapp$TaskListLayout} ,
	children : [
 { path: 'pool', meta : {"authenticated":true},  name: 'PartnerAffiliate.TaskList.TaskPool', components : { default : affiliateapp$TaskPool}  }, { path: 'assigned-to-me', meta : {"authenticated":true},  name: 'PartnerAffiliate.TaskList.TaskAssignedToMe', components : { default : affiliateapp$TaskAssignedToMe}  }, { path: 'completed', meta : {"authenticated":true},  name: 'PartnerAffiliate.TaskList.TaskCompleted', components : { default : affiliateapp$TaskCompleted}  }, { path: 'suspended', meta : {"authenticated":true},  name: 'PartnerAffiliate.TaskList.TaskSuspended', components : { default : affiliateapp$TaskSuspended}  }
] }, { path: 'cf/:clientFileId', meta : {},   components : { default : affiliateapp$CFileLayout} ,
	children : [
 { path: 'task', meta : {},   components : { default : affiliateapp$CFTaskLayout, leftNav : affiliateapp$CFLeftNav, summary : affiliateapp$CFSummary} ,
	children : [
 { path: '', redirect: { name : 'PartnerAffiliate.CFile.CFTask.CFActiveTasks'}}, { path: 'list/active', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFile.CFTask.CFActiveTasks', components : { default : affiliateapp$CFActiveTasks}  }, { path: 'list/completed', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFile.CFTask.CFCompletedTasks', components : { default : affiliateapp$CFCompletedTasks}  }, { path: ':taskId', meta : {},   components : { default : affiliateapp$CFTaskDetailsLayout} ,
	children : [
 { path: 'details', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFile.CFTask.CFTaskDetails.CFTaskDetails', components : { default : affiliateapp$CFTaskDetails}  }
] }
] }, { path: 'note', meta : {},   components : { default : affiliateapp$CFNoteLayout, leftNav : affiliateapp$CFLeftNav, summary : affiliateapp$CFSummary} ,
	children : [
 { path: '', redirect: { name : 'PartnerAffiliate.CFile.CFNote.CFNoteHighlightList'}}, { path: 'list/highlight', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFile.CFNote.CFNoteHighlightList', components : { default : affiliateapp$CFNoteHighlightList}  }, { path: 'list/all', meta : {"authenticated":true},  name: 'PartnerAffiliate.CFile.CFNote.CFNoteAllList', components : { default : affiliateapp$CFNoteAllList}  }
] }
] }, { path: 'user', meta : {},   components : { default : affiliateapp$UserLayout} ,
	children : [
 { path: 'change-password', meta : {"authenticated":true},  name: 'PartnerAffiliate.User.ChangePassword', components : { default : affiliateapp$ChangePassword}  }
] }
] }

];


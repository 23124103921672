
// tslint:disable

export interface Decorator  {
  loadOptions(key: string, options: any[]): void;
}


export namespace Login {
  



  


export class LoginDetails {

   
    public static fromJsonString(jsonString: string): LoginDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return LoginDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): LoginDetails {
        const json: any = o ;
        return new LoginDetails(
         (json.userName != null) ? json.userName : '',
(json.roleList != null) ? json.roleList : [],
(json.loggedInUser != null) ? LoggedInUser.fromJson(json.loggedInUser) : new LoggedInUser()
        );
    }



  


   
    constructor(
        public userName: string = '',
public roleList: string[] = [],
public loggedInUser: LoggedInUser = new LoggedInUser()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
roleList: (this.roleList != null) ? this.roleList : [],
loggedInUser: (this.loggedInUser != null) ? this.loggedInUser.toJson() : undefined
        };
    }
    



}



export class LoggedInUser {

   
    public static fromJsonString(jsonString: string): LoggedInUser {
       const jsonObj: object = JSON.parse(jsonString);
       return LoggedInUser.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): LoggedInUser {
        const json: any = o ;
        return new LoggedInUser(
         (json.userId != null) ? json.userId : '',
(json.userName != null) ? json.userName : '',
(json.emailId != null) ? json.emailId : '',
(json.title != null) ? json.title : undefined,
(json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : undefined,
(json.fullName != null) ? json.fullName : '',
(json.mobile != null) ? json.mobile : undefined,
(json.active != null) ? json.active : false
        );
    }



  


   
    constructor(
        public userId: string = '',
public userName: string = '',
public emailId: string = '',
public title: string|undefined = undefined,
public firstName: string = '',
public lastName: string|undefined = undefined,
public fullName: string = '',
public mobile: string|undefined = undefined,
public active: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userId: (this.userId != null) ? this.userId : undefined,
userName: (this.userName != null) ? this.userName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
title: (this.title != null) ? this.title : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
active: (this.active != null) ? this.active : undefined
        };
    }
    



}



export class LoginForm {

   
    public static fromJsonString(jsonString: string): LoginForm {
       const jsonObj: object = JSON.parse(jsonString);
       return LoginForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): LoginForm {
        const json: any = o ;
        return new LoginForm(
         (json.userName != null) ? json.userName : '',
(json.password != null) ? json.password : '',
(json.appId != null) ? json.appId : 'partnerapp'
        );
    }



  


   
    constructor(
        public userName: string = '',
public password: string = '',
public appId: string = 'partnerapp'
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
password: (this.password != null) ? this.password : undefined,
appId: (this.appId != null) ? this.appId : undefined
        };
    }
    



}



export class MyAppId {

   
    public static fromJsonString(jsonString: string): MyAppId {
       const jsonObj: object = JSON.parse(jsonString);
       return MyAppId.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyAppId {
        const json: any = o ;
        return new MyAppId(
         (json.appId != null) ? json.appId : 'partnerapp'
        );
    }



  


   
    constructor(
        public appId: string = 'partnerapp'
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          appId: (this.appId != null) ? this.appId : undefined
        };
    }
    



}



export class ResetPasswordInput {

   
    public static fromJsonString(jsonString: string): ResetPasswordInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ResetPasswordInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ResetPasswordInput {
        const json: any = o ;
        return new ResetPasswordInput(
         (json.mobile != null) ? json.mobile : '',
(json.newPassword != null) ? json.newPassword : ''
        );
    }



  


   
    constructor(
        public mobile: string = '',
public newPassword: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
newPassword: (this.newPassword != null) ? this.newPassword : undefined
        };
    }
    



}



}



export namespace Partner {
  

export class AGE_RANGE {

     public static get TWENTY_SIX_TO_THIRTY() { return new AGE_RANGE('TWENTY_SIX_TO_THIRTY', '26-30'); } 
public static get FOURTY_ONE_AND_ABOVE() { return new AGE_RANGE('FOURTY_ONE_AND_ABOVE', '41+'); } 
public static get THIRTY_SIX_TO_FOURTY() { return new AGE_RANGE('THIRTY_SIX_TO_FOURTY', '36-40'); } 
public static get THIRTY_ONE_TO_THIRTY_FIVE() { return new AGE_RANGE('THIRTY_ONE_TO_THIRTY_FIVE', '31-35'); } 
public static get EIGHTEEN_TO_TWENTY_FIVE() { return new AGE_RANGE('EIGHTEEN_TO_TWENTY_FIVE', '18-25'); } 

     public static get DEFAULT_VALUE() { return AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE; }

     public static withIdOption(id: string): AGE_RANGE| undefined  {
        try {
            return AGE_RANGE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return AGE_RANGE.withIdOption(id) !== undefined; }
    public static list(): AGE_RANGE[]  { return [AGE_RANGE.THIRTY_ONE_TO_THIRTY_FIVE, AGE_RANGE.FOURTY_ONE_AND_ABOVE, AGE_RANGE.TWENTY_SIX_TO_THIRTY, AGE_RANGE.THIRTY_SIX_TO_FOURTY, AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE]; }
    public static idList(): string[]  { return ['EIGHTEEN_TO_TWENTY_FIVE', 'TWENTY_SIX_TO_THIRTY', 'THIRTY_ONE_TO_THIRTY_FIVE', 'THIRTY_SIX_TO_FOURTY', 'FOURTY_ONE_AND_ABOVE']; }
    public static nameList(): string[]  { return ['18-25', '26-30', '31-35', '41+', '36-40']; }


    public static withId(id: string): AGE_RANGE  {
        switch (id) {
            case 'EIGHTEEN_TO_TWENTY_FIVE': return AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE; 
case 'THIRTY_SIX_TO_FOURTY': return AGE_RANGE.THIRTY_SIX_TO_FOURTY; 
case 'FOURTY_ONE_AND_ABOVE': return AGE_RANGE.FOURTY_ONE_AND_ABOVE; 
case 'TWENTY_SIX_TO_THIRTY': return AGE_RANGE.TWENTY_SIX_TO_THIRTY; 
case 'THIRTY_ONE_TO_THIRTY_FIVE': return AGE_RANGE.THIRTY_ONE_TO_THIRTY_FIVE; 
            default: throw new Error('Invalid id for AGE_RANGE: ' + id);
        }
    }

    public static withName(name: string): AGE_RANGE  {
        switch (name) {
            case '31-35': return AGE_RANGE.THIRTY_ONE_TO_THIRTY_FIVE; 
case '36-40': return AGE_RANGE.THIRTY_SIX_TO_FOURTY; 
case '41+': return AGE_RANGE.FOURTY_ONE_AND_ABOVE; 
case '26-30': return AGE_RANGE.TWENTY_SIX_TO_THIRTY; 
case '18-25': return AGE_RANGE.EIGHTEEN_TO_TWENTY_FIVE; 
            default: throw new Error('Invalid id for AGE_RANGE: ' + name);
        }
    }

    public static copyMe(given: AGE_RANGE): AGE_RANGE  {
            return AGE_RANGE.withId(given.id)
    }

    public static fromJson(json: string): AGE_RANGE { return AGE_RANGE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return AGE_RANGE.list().indexOf(this); }
}
 

export class DOCUMENT_TYPE {

     public static get CERTIFICATE() { return new DOCUMENT_TYPE('CERTIFICATE', 'CERTIFICATE'); } 
public static get IDENTIFICATION() { return new DOCUMENT_TYPE('IDENTIFICATION', 'IDENTIFICATION'); } 

     public static get DEFAULT_VALUE() { return DOCUMENT_TYPE.CERTIFICATE; }

     public static withIdOption(id: string): DOCUMENT_TYPE| undefined  {
        try {
            return DOCUMENT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return DOCUMENT_TYPE.withIdOption(id) !== undefined; }
    public static list(): DOCUMENT_TYPE[]  { return [DOCUMENT_TYPE.CERTIFICATE, DOCUMENT_TYPE.IDENTIFICATION]; }
    public static idList(): string[]  { return ['CERTIFICATE', 'IDENTIFICATION']; }
    public static nameList(): string[]  { return ['CERTIFICATE', 'IDENTIFICATION']; }


    public static withId(id: string): DOCUMENT_TYPE  {
        switch (id) {
            case 'CERTIFICATE': return DOCUMENT_TYPE.CERTIFICATE; 
case 'IDENTIFICATION': return DOCUMENT_TYPE.IDENTIFICATION; 
            default: throw new Error('Invalid id for DOCUMENT_TYPE: ' + id);
        }
    }

    public static withName(name: string): DOCUMENT_TYPE  {
        switch (name) {
            case 'CERTIFICATE': return DOCUMENT_TYPE.CERTIFICATE; 
case 'IDENTIFICATION': return DOCUMENT_TYPE.IDENTIFICATION; 
            default: throw new Error('Invalid id for DOCUMENT_TYPE: ' + name);
        }
    }

    public static copyMe(given: DOCUMENT_TYPE): DOCUMENT_TYPE  {
            return DOCUMENT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): DOCUMENT_TYPE { return DOCUMENT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return DOCUMENT_TYPE.list().indexOf(this); }
}
 

export class PARTNER_STATUS {

     public static get INACTIVE() { return new PARTNER_STATUS('INACTIVE', 'INACTIVE'); } 
public static get ACTIVE() { return new PARTNER_STATUS('ACTIVE', 'ACTIVE'); } 
public static get REJECTED() { return new PARTNER_STATUS('REJECTED', 'REJECTED'); } 
public static get ONBOARDING_REQUESTED() { return new PARTNER_STATUS('ONBOARDING_REQUESTED', 'ONBOARDING_REQUESTED'); } 
public static get SIGNED_UP() { return new PARTNER_STATUS('SIGNED_UP', 'SIGNED_UP'); } 

     public static get DEFAULT_VALUE() { return PARTNER_STATUS.SIGNED_UP; }

     public static withIdOption(id: string): PARTNER_STATUS| undefined  {
        try {
            return PARTNER_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PARTNER_STATUS.withIdOption(id) !== undefined; }
    public static list(): PARTNER_STATUS[]  { return [PARTNER_STATUS.REJECTED, PARTNER_STATUS.INACTIVE, PARTNER_STATUS.ONBOARDING_REQUESTED, PARTNER_STATUS.ACTIVE, PARTNER_STATUS.SIGNED_UP]; }
    public static idList(): string[]  { return ['REJECTED', 'ONBOARDING_REQUESTED', 'ACTIVE', 'SIGNED_UP', 'INACTIVE']; }
    public static nameList(): string[]  { return ['REJECTED', 'ONBOARDING_REQUESTED', 'ACTIVE', 'SIGNED_UP', 'INACTIVE']; }


    public static withId(id: string): PARTNER_STATUS  {
        switch (id) {
            case 'REJECTED': return PARTNER_STATUS.REJECTED; 
case 'ONBOARDING_REQUESTED': return PARTNER_STATUS.ONBOARDING_REQUESTED; 
case 'INACTIVE': return PARTNER_STATUS.INACTIVE; 
case 'SIGNED_UP': return PARTNER_STATUS.SIGNED_UP; 
case 'ACTIVE': return PARTNER_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for PARTNER_STATUS: ' + id);
        }
    }

    public static withName(name: string): PARTNER_STATUS  {
        switch (name) {
            case 'REJECTED': return PARTNER_STATUS.REJECTED; 
case 'ONBOARDING_REQUESTED': return PARTNER_STATUS.ONBOARDING_REQUESTED; 
case 'INACTIVE': return PARTNER_STATUS.INACTIVE; 
case 'SIGNED_UP': return PARTNER_STATUS.SIGNED_UP; 
case 'ACTIVE': return PARTNER_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for PARTNER_STATUS: ' + name);
        }
    }

    public static copyMe(given: PARTNER_STATUS): PARTNER_STATUS  {
            return PARTNER_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PARTNER_STATUS { return PARTNER_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PARTNER_STATUS.list().indexOf(this); }
}
 

export class PARTNER_TYPE {

     public static get AGENT() { return new PARTNER_TYPE('AGENT', 'AGENT'); } 
public static get INSTITUTIONAL() { return new PARTNER_TYPE('INSTITUTIONAL', 'INSTITUTIONAL'); } 

     public static get DEFAULT_VALUE() { return PARTNER_TYPE.AGENT; }

     public static withIdOption(id: string): PARTNER_TYPE| undefined  {
        try {
            return PARTNER_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PARTNER_TYPE.withIdOption(id) !== undefined; }
    public static list(): PARTNER_TYPE[]  { return [PARTNER_TYPE.AGENT, PARTNER_TYPE.INSTITUTIONAL]; }
    public static idList(): string[]  { return ['AGENT', 'INSTITUTIONAL']; }
    public static nameList(): string[]  { return ['AGENT', 'INSTITUTIONAL']; }


    public static withId(id: string): PARTNER_TYPE  {
        switch (id) {
            case 'AGENT': return PARTNER_TYPE.AGENT; 
case 'INSTITUTIONAL': return PARTNER_TYPE.INSTITUTIONAL; 
            default: throw new Error('Invalid id for PARTNER_TYPE: ' + id);
        }
    }

    public static withName(name: string): PARTNER_TYPE  {
        switch (name) {
            case 'AGENT': return PARTNER_TYPE.AGENT; 
case 'INSTITUTIONAL': return PARTNER_TYPE.INSTITUTIONAL; 
            default: throw new Error('Invalid id for PARTNER_TYPE: ' + name);
        }
    }

    public static copyMe(given: PARTNER_TYPE): PARTNER_TYPE  {
            return PARTNER_TYPE.withId(given.id)
    }

    public static fromJson(json: string): PARTNER_TYPE { return PARTNER_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PARTNER_TYPE.list().indexOf(this); }
}
 

export class PROGRAM_CODE {

     public static get DRP() { return new PROGRAM_CODE('DRP', 'DRP'); } 
public static get DCP() { return new PROGRAM_CODE('DCP', 'DCP'); } 

     public static get DEFAULT_VALUE() { return PROGRAM_CODE.DRP; }

     public static withIdOption(id: string): PROGRAM_CODE| undefined  {
        try {
            return PROGRAM_CODE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PROGRAM_CODE.withIdOption(id) !== undefined; }
    public static list(): PROGRAM_CODE[]  { return [PROGRAM_CODE.DRP, PROGRAM_CODE.DCP]; }
    public static idList(): string[]  { return ['DRP', 'DCP']; }
    public static nameList(): string[]  { return ['DRP', 'DCP']; }


    public static withId(id: string): PROGRAM_CODE  {
        switch (id) {
            case 'DRP': return PROGRAM_CODE.DRP; 
case 'DCP': return PROGRAM_CODE.DCP; 
            default: throw new Error('Invalid id for PROGRAM_CODE: ' + id);
        }
    }

    public static withName(name: string): PROGRAM_CODE  {
        switch (name) {
            case 'DRP': return PROGRAM_CODE.DRP; 
case 'DCP': return PROGRAM_CODE.DCP; 
            default: throw new Error('Invalid id for PROGRAM_CODE: ' + name);
        }
    }

    public static copyMe(given: PROGRAM_CODE): PROGRAM_CODE  {
            return PROGRAM_CODE.withId(given.id)
    }

    public static fromJson(json: string): PROGRAM_CODE { return PROGRAM_CODE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PROGRAM_CODE.list().indexOf(this); }
}
 


  


export class ApplicantDetails {

   
    public static fromJsonString(jsonString: string): ApplicantDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return ApplicantDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ApplicantDetails {
        const json: any = o ;
        return new ApplicantDetails(
         (json.PartnerInfo != null) ? PartnerSummary.fromJson(json.PartnerInfo) : undefined,
(json.GetReferralOrgCodeInfo != null) ? GetReferralOrgCodeOutput.fromJson(json.GetReferralOrgCodeInfo) : new GetReferralOrgCodeOutput()
        );
    }



  


   
    constructor(
        public PartnerInfo: PartnerSummary|undefined = undefined,
public GetReferralOrgCodeInfo: GetReferralOrgCodeOutput = new GetReferralOrgCodeOutput()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          PartnerInfo: (this.PartnerInfo != null) ? this.PartnerInfo.toJson() : undefined,
GetReferralOrgCodeInfo: (this.GetReferralOrgCodeInfo != null) ? this.GetReferralOrgCodeInfo.toJson() : undefined
        };
    }
    



}



export class SignUpInput {

   
    public static fromJsonString(jsonString: string): SignUpInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SignUpInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SignUpInput {
        const json: any = o ;
        return new SignUpInput(
         (json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : undefined,
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : '',
(json.password != null) ? json.password : '',
(json.ageRange != null) ? AGE_RANGE.fromJson(json.ageRange) : undefined,
(json.state != null) ? json.state : undefined
        );
    }



  


   
    constructor(
        public firstName: string = '',
public lastName: string|undefined = undefined,
public mobile: string = '',
public emailId: string = '',
public password: string = '',
public ageRange: AGE_RANGE|undefined = undefined,
public state: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
password: (this.password != null) ? this.password : undefined,
ageRange: (this.ageRange != null) ? AGE_RANGE.copyMe(this.ageRange).toJson() : undefined,
state: (this.state != null) ? this.state : undefined
        };
    }
    



}



export class PartnerDocument {

   
    public static fromJsonString(jsonString: string): PartnerDocument {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerDocument.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerDocument {
        const json: any = o ;
        return new PartnerDocument(
         (json.documentType != null) ? DOCUMENT_TYPE.fromJson(json.documentType) : DOCUMENT_TYPE.DEFAULT_VALUE,
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.uploadedOn != null) ? json.uploadedOn : ''
        );
    }



  


   
    constructor(
        public documentType: DOCUMENT_TYPE = DOCUMENT_TYPE.DEFAULT_VALUE,
public documentPath: string = '',
public documentDetails: string|undefined = undefined,
public uploadedOn: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentType: (this.documentType != null) ?  DOCUMENT_TYPE.copyMe(this.documentType).toJson() : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
uploadedOn: (this.uploadedOn != null) ? this.uploadedOn : undefined
        };
    }
    



}



export class PartnerSummary {

   
    public static fromJsonString(jsonString: string): PartnerSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerSummary {
        const json: any = o ;
        return new PartnerSummary(
         (json.partnerId != null) ? json.partnerId : '',
(json.fullname != null) ? json.fullname : '',
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : '',
(json.status != null) ? PARTNER_STATUS.fromJson(json.status) : PARTNER_STATUS.DEFAULT_VALUE,
(json.totalEarnings != null) ? json.totalEarnings : 0,
(json.currentEarnings != null) ? json.currentEarnings : 0,
(json.onboardedFilesCount != null) ? json.onboardedFilesCount : 0,
(json.totalDebtEnrolled != null) ? json.totalDebtEnrolled : 0,
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : '',
(json.documentList != null) ? (json.documentList as object[]).map((x) => PartnerDocument.fromJson(x)) : [],
(json.registeredOn != null) ? json.registeredOn : '',
(json.activatedOn != null) ? json.activatedOn : undefined,
(json.partnerType != null) ? PARTNER_TYPE.fromJson(json.partnerType) : PARTNER_TYPE.DEFAULT_VALUE,
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public partnerId: string = '',
public fullname: string = '',
public mobile: string = '',
public emailId: string = '',
public status: PARTNER_STATUS = PARTNER_STATUS.DEFAULT_VALUE,
public totalEarnings: number = 0,
public currentEarnings: number = 0,
public onboardedFilesCount: number = 0,
public totalDebtEnrolled: number = 0,
public uniqueReferralCode: string = '',
public documentList: PartnerDocument[] = [],
public registeredOn: string = '',
public activatedOn: string|undefined = undefined,
public partnerType: PARTNER_TYPE = PARTNER_TYPE.DEFAULT_VALUE,
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
fullname: (this.fullname != null) ? this.fullname : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
status: (this.status != null) ?  PARTNER_STATUS.copyMe(this.status).toJson() : undefined,
totalEarnings: (this.totalEarnings != null) ? this.totalEarnings : undefined,
currentEarnings: (this.currentEarnings != null) ? this.currentEarnings : undefined,
onboardedFilesCount: (this.onboardedFilesCount != null) ? this.onboardedFilesCount : undefined,
totalDebtEnrolled: (this.totalDebtEnrolled != null) ? this.totalDebtEnrolled : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined,
documentList: (this.documentList != null) ? this.documentList.map((x) => x.toJson()) : [],
registeredOn: (this.registeredOn != null) ? this.registeredOn : undefined,
activatedOn: (this.activatedOn != null) ? this.activatedOn : undefined,
partnerType: (this.partnerType != null) ?  PARTNER_TYPE.copyMe(this.partnerType).toJson() : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class GetReferralOrgCodeInput {

   
    public static fromJsonString(jsonString: string): GetReferralOrgCodeInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetReferralOrgCodeInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetReferralOrgCodeInput {
        const json: any = o ;
        return new GetReferralOrgCodeInput(
         (json.uniqueReferralCode != null) ? json.uniqueReferralCode : ''
        );
    }



  


   
    constructor(
        public uniqueReferralCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined
        };
    }
    



}



export class GetReferralOrgCodeOutput {

   
    public static fromJsonString(jsonString: string): GetReferralOrgCodeOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetReferralOrgCodeOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetReferralOrgCodeOutput {
        const json: any = o ;
        return new GetReferralOrgCodeOutput(
         (json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class ChangeDetailsInput {

   
    public static fromJsonString(jsonString: string): ChangeDetailsInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ChangeDetailsInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ChangeDetailsInput {
        const json: any = o ;
        return new ChangeDetailsInput(
         (json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : undefined,
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : ''
        );
    }



  


   
    constructor(
        public firstName: string = '',
public lastName: string|undefined = undefined,
public mobile: string = '',
public emailId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined
        };
    }
    



}



export class OnboardedFileInput {

   
    public static fromJsonString(jsonString: string): OnboardedFileInput {
       const jsonObj: object = JSON.parse(jsonString);
       return OnboardedFileInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): OnboardedFileInput {
        const json: any = o ;
        return new OnboardedFileInput(
         (json.partnerId != null) ? json.partnerId : '',
(json.fromDate != null) ? json.fromDate : undefined,
(json.toDate != null) ? json.toDate : undefined
        );
    }



  


   
    constructor(
        public partnerId: string = '',
public fromDate: string|undefined = undefined,
public toDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class UploadCertificateInput {

   
    public static fromJsonString(jsonString: string): UploadCertificateInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UploadCertificateInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UploadCertificateInput {
        const json: any = o ;
        return new UploadCertificateInput(
         (json.partnerId != null) ? json.partnerId : '',
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined
        );
    }



  


   
    constructor(
        public partnerId: string = '',
public documentPath: string = '',
public documentDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerId: (this.partnerId != null) ? this.partnerId : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined
        };
    }
    



}



export class AddPartnerInput {

   
    public static fromJsonString(jsonString: string): AddPartnerInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AddPartnerInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddPartnerInput {
        const json: any = o ;
        return new AddPartnerInput(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.mobile != null) ? json.mobile : '',
(json.emailId != null) ? json.emailId : '',
(json.leadSource != null) ? json.leadSource : undefined,
(json.note != null) ? json.note : undefined,
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : '',
(json.addedBy != null) ? json.addedBy : undefined,
(json.totalCreditCardDebt != null) ? json.totalCreditCardDebt : undefined,
(json.totalPersonalLoanDebt != null) ? json.totalPersonalLoanDebt : undefined,
(json.missedEmiInLast3Months != null) ? json.missedEmiInLast3Months : undefined
        );
    }



  


   
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public mobile: string = '',
public emailId: string = '',
public leadSource: string|undefined = undefined,
public note: string|undefined = undefined,
public uniqueReferralCode: string = '',
public addedBy: string|undefined = undefined,
public totalCreditCardDebt: number|undefined = undefined,
public totalPersonalLoanDebt: number|undefined = undefined,
public missedEmiInLast3Months: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined,
note: (this.note != null) ? this.note : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined,
addedBy: (this.addedBy != null) ? this.addedBy : undefined,
totalCreditCardDebt: (this.totalCreditCardDebt != null) ? this.totalCreditCardDebt : undefined,
totalPersonalLoanDebt: (this.totalPersonalLoanDebt != null) ? this.totalPersonalLoanDebt : undefined,
missedEmiInLast3Months: (this.missedEmiInLast3Months != null) ? this.missedEmiInLast3Months : undefined
        };
    }
    



}



export class SendOTPInput {

   
    public static fromJsonString(jsonString: string): SendOTPInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SendOTPInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SendOTPInput {
        const json: any = o ;
        return new SendOTPInput(
         (json.mobile != null) ? json.mobile : '',
(json.uniqueReferralCode != null) ? json.uniqueReferralCode : ''
        );
    }



  


   
    constructor(
        public mobile: string = '',
public uniqueReferralCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
uniqueReferralCode: (this.uniqueReferralCode != null) ? this.uniqueReferralCode : undefined
        };
    }
    



}



export class ValidateAndRegisterPartnerClientFileInput {

   
    public static fromJsonString(jsonString: string): ValidateAndRegisterPartnerClientFileInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ValidateAndRegisterPartnerClientFileInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ValidateAndRegisterPartnerClientFileInput {
        const json: any = o ;
        return new ValidateAndRegisterPartnerClientFileInput(
         (json.partnerClientId != null) ? json.partnerClientId : '',
(json.otp != null) ? json.otp : ''
        );
    }



  


   
    constructor(
        public partnerClientId: string = '',
public otp: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          partnerClientId: (this.partnerClientId != null) ? this.partnerClientId : undefined,
otp: (this.otp != null) ? this.otp : undefined
        };
    }
    



}



export class ClientCFBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientCFBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientCFBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientCFBasicInfo {
        const json: any = o ;
        return new ClientCFBasicInfo(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? json.clientFileStatus : '',
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.wad != null) ? json.wad : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.isSpineUser != null) ? json.isSpineUser : false,
(json.programCode != null) ? PROGRAM_CODE.fromJson(json.programCode) : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: string = '',
public isFirstMSFPaid: boolean = false,
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public wad: number|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public isSpineUser: boolean = false,
public programCode: PROGRAM_CODE|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ? this.clientFileStatus : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
isSpineUser: (this.isSpineUser != null) ? this.isSpineUser : undefined,
programCode: (this.programCode != null) ? PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined
        };
    }
    



}



export class ClientBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientBasicInfo {
        const json: any = o ;
        return new ClientBasicInfo(
         (json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.pan != null) ? json.pan : undefined,
(json.city != null) ? json.city : undefined,
(json.state != null) ? json.state : undefined,
(json.clientFileBasicInfoList != null) ? (json.clientFileBasicInfoList as object[]).map((x) => ClientCFBasicInfo.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientId: string = '',
public fullName: string = '',
public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public pan: string|undefined = undefined,
public city: string|undefined = undefined,
public state: string|undefined = undefined,
public clientFileBasicInfoList: ClientCFBasicInfo[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
pan: (this.pan != null) ? this.pan : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
clientFileBasicInfoList: (this.clientFileBasicInfoList != null) ? this.clientFileBasicInfoList.map((x) => x.toJson()) : []
        };
    }
    



}



export class ClientFileBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientFileBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileBasicInfo {
        const json: any = o ;
        return new ClientFileBasicInfo(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? json.clientFileStatus : '',
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.clientBasicInfo != null) ? ClientBasicInfo.fromJson(json.clientBasicInfo) : new ClientBasicInfo(),
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.wad != null) ? json.wad : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.isSpineUser != null) ? json.isSpineUser : false,
(json.programCode != null) ? PROGRAM_CODE.fromJson(json.programCode) : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: string = '',
public isFirstMSFPaid: boolean = false,
public clientBasicInfo: ClientBasicInfo = new ClientBasicInfo(),
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public wad: number|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public isSpineUser: boolean = false,
public programCode: PROGRAM_CODE|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ? this.clientFileStatus : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
clientBasicInfo: (this.clientBasicInfo != null) ? this.clientBasicInfo.toJson() : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
isSpineUser: (this.isSpineUser != null) ? this.isSpineUser : undefined,
programCode: (this.programCode != null) ? PROGRAM_CODE.copyMe(this.programCode).toJson() : undefined
        };
    }
    



}



export class FiPaymentCalculator {

   
    public static fromJsonString(jsonString: string): FiPaymentCalculator {
       const jsonObj: object = JSON.parse(jsonString);
       return FiPaymentCalculator.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiPaymentCalculator {
        const json: any = o ;
        return new FiPaymentCalculator(
         (json.ppCode != null) ? json.ppCode : '',
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 0,
(json.firstDraftDate != null) ? json.firstDraftDate : '',
(json.feeCode != null) ? json.feeCode : '',
(json.feeFirstDraftDate != null) ? json.feeFirstDraftDate : '',
(json.msfDraftDay != null) ? json.msfDraftDay : undefined,
(json.msfSlabAmount != null) ? json.msfSlabAmount : 0,
(json.msfDiscountAmount != null) ? json.msfDiscountAmount : undefined,
(json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.spaDraftDay != null) ? json.spaDraftDay : undefined,
(json.spaDraftAmount != null) ? json.spaDraftAmount : undefined,
(json.totalMonthlyObligation != null) ? json.totalMonthlyObligation : undefined,
(json.repaymentAmount != null) ? json.repaymentAmount : undefined
        );
    }



  


   
    constructor(
        public ppCode: string = '',
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 0,
public firstDraftDate: string = '',
public feeCode: string = '',
public feeFirstDraftDate: string = '',
public msfDraftDay: number|undefined = undefined,
public msfSlabAmount: number = 0,
public msfDiscountAmount: number|undefined = undefined,
public msfDraftAmount: number = 0,
public spaDraftDay: number|undefined = undefined,
public spaDraftAmount: number|undefined = undefined,
public totalMonthlyObligation: number|undefined = undefined,
public repaymentAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          ppCode: (this.ppCode != null) ? this.ppCode : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
firstDraftDate: (this.firstDraftDate != null) ? this.firstDraftDate : undefined,
feeCode: (this.feeCode != null) ? this.feeCode : undefined,
feeFirstDraftDate: (this.feeFirstDraftDate != null) ? this.feeFirstDraftDate : undefined,
msfDraftDay: (this.msfDraftDay != null) ? this.msfDraftDay : undefined,
msfSlabAmount: (this.msfSlabAmount != null) ? this.msfSlabAmount : undefined,
msfDiscountAmount: (this.msfDiscountAmount != null) ? this.msfDiscountAmount : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
spaDraftDay: (this.spaDraftDay != null) ? this.spaDraftDay : undefined,
spaDraftAmount: (this.spaDraftAmount != null) ? this.spaDraftAmount : undefined,
totalMonthlyObligation: (this.totalMonthlyObligation != null) ? this.totalMonthlyObligation : undefined,
repaymentAmount: (this.repaymentAmount != null) ? this.repaymentAmount : undefined
        };
    }
    



}



export class MSFPayment {

   
    public static fromJsonString(jsonString: string): MSFPayment {
       const jsonObj: object = JSON.parse(jsonString);
       return MSFPayment.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MSFPayment {
        const json: any = o ;
        return new MSFPayment(
         (json.draftDate != null) ? json.draftDate : '',
(json.amount != null) ? json.amount : 0,
(json.status != null) ? json.status : '',
(json.statusUpdatedOn != null) ? json.statusUpdatedOn : undefined
        );
    }



  


   
    constructor(
        public draftDate: string = '',
public amount: number = 0,
public status: string = '',
public statusUpdatedOn: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          draftDate: (this.draftDate != null) ? this.draftDate : undefined,
amount: (this.amount != null) ? this.amount : undefined,
status: (this.status != null) ? this.status : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn : undefined
        };
    }
    



}



export class OnboardedClientFileDetails {

   
    public static fromJsonString(jsonString: string): OnboardedClientFileDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return OnboardedClientFileDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): OnboardedClientFileDetails {
        const json: any = o ;
        return new OnboardedClientFileDetails(
         (json.clientFileDetails != null) ? ClientFileBasicInfo.fromJson(json.clientFileDetails) : new ClientFileBasicInfo(),
(json.psPlan != null) ? FiPaymentCalculator.fromJson(json.psPlan) : undefined,
(json.msfPaymentList != null) ? (json.msfPaymentList as object[]).map((x) => MSFPayment.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientFileDetails: ClientFileBasicInfo = new ClientFileBasicInfo(),
public psPlan: FiPaymentCalculator|undefined = undefined,
public msfPaymentList: MSFPayment[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileDetails: (this.clientFileDetails != null) ? this.clientFileDetails.toJson() : undefined,
psPlan: (this.psPlan != null) ? this.psPlan.toJson() : undefined,
msfPaymentList: (this.msfPaymentList != null) ? this.msfPaymentList.map((x) => x.toJson()) : []
        };
    }
    



}



export class NotEnrolledPartnerClientListInput {

   
    public static fromJsonString(jsonString: string): NotEnrolledPartnerClientListInput {
       const jsonObj: object = JSON.parse(jsonString);
       return NotEnrolledPartnerClientListInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): NotEnrolledPartnerClientListInput {
        const json: any = o ;
        return new NotEnrolledPartnerClientListInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.offset != null) ? json.offset : 0,
(json.count != null) ? json.count : 0
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public offset: number = 0,
public count: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
offset: (this.offset != null) ? this.offset : undefined,
count: (this.count != null) ? this.count : undefined
        };
    }
    



}



export class NotEnrolledPartnerClient {

   
    public static fromJsonString(jsonString: string): NotEnrolledPartnerClient {
       const jsonObj: object = JSON.parse(jsonString);
       return NotEnrolledPartnerClient.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): NotEnrolledPartnerClient {
        const json: any = o ;
        return new NotEnrolledPartnerClient(
         (json.name != null) ? json.name : '',
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.registeredOn != null) ? json.registeredOn : '',
(json.fppClientRemoteId != null) ? json.fppClientRemoteId : undefined
        );
    }



  


   
    constructor(
        public name: string = '',
public emailId: string = '',
public mobile: string = '',
public registeredOn: string = '',
public fppClientRemoteId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          name: (this.name != null) ? this.name : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
registeredOn: (this.registeredOn != null) ? this.registeredOn : undefined,
fppClientRemoteId: (this.fppClientRemoteId != null) ? this.fppClientRemoteId : undefined
        };
    }
    



}



export class NotEnrolledPartnerClientListOutput {

   
    public static fromJsonString(jsonString: string): NotEnrolledPartnerClientListOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return NotEnrolledPartnerClientListOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): NotEnrolledPartnerClientListOutput {
        const json: any = o ;
        return new NotEnrolledPartnerClientListOutput(
         (json.totalCount != null) ? json.totalCount : 0,
(json.notEnrolledPartnerClientList != null) ? (json.notEnrolledPartnerClientList as object[]).map((x) => NotEnrolledPartnerClient.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public totalCount: number = 0,
public notEnrolledPartnerClientList: NotEnrolledPartnerClient[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
notEnrolledPartnerClientList: (this.notEnrolledPartnerClientList != null) ? this.notEnrolledPartnerClientList.map((x) => x.toJson()) : []
        };
    }
    



}



}



export namespace Insight {
  

export class CLIENT_FILE_STATUS {

     public static get HOLD() { return new CLIENT_FILE_STATUS('HOLD', 'HOLD'); } 
public static get PENDING_CANCELLED() { return new CLIENT_FILE_STATUS('PENDING_CANCELLED', 'PENDING_CANCELLED'); } 
public static get AGREEMENT_SIGNED() { return new CLIENT_FILE_STATUS('AGREEMENT_SIGNED', 'AGREEMENT_SIGNED'); } 
public static get PARTIALLY_GRADUATED() { return new CLIENT_FILE_STATUS('PARTIALLY_GRADUATED', 'PARTIALLY_GRADUATED'); } 
public static get ACTIVE() { return new CLIENT_FILE_STATUS('ACTIVE', 'ACTIVE'); } 
public static get AGREEMENT_SIGNED_REVOKED() { return new CLIENT_FILE_STATUS('AGREEMENT_SIGNED_REVOKED', 'AGREEMENT_SIGNED_REVOKED'); } 
public static get CANCELLED() { return new CLIENT_FILE_STATUS('CANCELLED', 'CANCELLED'); } 
public static get REJECTED() { return new CLIENT_FILE_STATUS('REJECTED', 'REJECTED'); } 
public static get GRADUATED() { return new CLIENT_FILE_STATUS('GRADUATED', 'GRADUATED'); } 
public static get LEAD() { return new CLIENT_FILE_STATUS('LEAD', 'LEAD'); } 

     public static get DEFAULT_VALUE() { return CLIENT_FILE_STATUS.LEAD; }

     public static withIdOption(id: string): CLIENT_FILE_STATUS| undefined  {
        try {
            return CLIENT_FILE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CLIENT_FILE_STATUS.withIdOption(id) !== undefined; }
    public static list(): CLIENT_FILE_STATUS[]  { return [CLIENT_FILE_STATUS.PARTIALLY_GRADUATED, CLIENT_FILE_STATUS.CANCELLED, CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED, CLIENT_FILE_STATUS.ACTIVE, CLIENT_FILE_STATUS.REJECTED, CLIENT_FILE_STATUS.PENDING_CANCELLED, CLIENT_FILE_STATUS.GRADUATED, CLIENT_FILE_STATUS.AGREEMENT_SIGNED, CLIENT_FILE_STATUS.LEAD, CLIENT_FILE_STATUS.HOLD]; }
    public static idList(): string[]  { return ['LEAD', 'REJECTED', 'AGREEMENT_SIGNED_REVOKED', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'PARTIALLY_GRADUATED']; }
    public static nameList(): string[]  { return ['LEAD', 'REJECTED', 'AGREEMENT_SIGNED_REVOKED', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'PARTIALLY_GRADUATED']; }


    public static withId(id: string): CLIENT_FILE_STATUS  {
        switch (id) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return CLIENT_FILE_STATUS.REJECTED; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'PARTIALLY_GRADUATED': return CLIENT_FILE_STATUS.PARTIALLY_GRADUATED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'AGREEMENT_SIGNED_REVOKED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + id);
        }
    }

    public static withName(name: string): CLIENT_FILE_STATUS  {
        switch (name) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return CLIENT_FILE_STATUS.REJECTED; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'PARTIALLY_GRADUATED': return CLIENT_FILE_STATUS.PARTIALLY_GRADUATED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'AGREEMENT_SIGNED_REVOKED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + name);
        }
    }

    public static copyMe(given: CLIENT_FILE_STATUS): CLIENT_FILE_STATUS  {
            return CLIENT_FILE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): CLIENT_FILE_STATUS { return CLIENT_FILE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CLIENT_FILE_STATUS.list().indexOf(this); }
}
 

export class CF_OM_FILTER {

     public static get ONBOARDED() { return new CF_OM_FILTER('ONBOARDED', 'ONBOARDED'); } 
public static get MANDATE() { return new CF_OM_FILTER('MANDATE', 'MANDATE'); } 

     public static get DEFAULT_VALUE() { return CF_OM_FILTER.ONBOARDED; }

     public static withIdOption(id: string): CF_OM_FILTER| undefined  {
        try {
            return CF_OM_FILTER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CF_OM_FILTER.withIdOption(id) !== undefined; }
    public static list(): CF_OM_FILTER[]  { return [CF_OM_FILTER.ONBOARDED, CF_OM_FILTER.MANDATE]; }
    public static idList(): string[]  { return ['ONBOARDED', 'MANDATE']; }
    public static nameList(): string[]  { return ['ONBOARDED', 'MANDATE']; }


    public static withId(id: string): CF_OM_FILTER  {
        switch (id) {
            case 'ONBOARDED': return CF_OM_FILTER.ONBOARDED; 
case 'MANDATE': return CF_OM_FILTER.MANDATE; 
            default: throw new Error('Invalid id for CF_OM_FILTER: ' + id);
        }
    }

    public static withName(name: string): CF_OM_FILTER  {
        switch (name) {
            case 'ONBOARDED': return CF_OM_FILTER.ONBOARDED; 
case 'MANDATE': return CF_OM_FILTER.MANDATE; 
            default: throw new Error('Invalid id for CF_OM_FILTER: ' + name);
        }
    }

    public static copyMe(given: CF_OM_FILTER): CF_OM_FILTER  {
            return CF_OM_FILTER.withId(given.id)
    }

    public static fromJson(json: string): CF_OM_FILTER { return CF_OM_FILTER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CF_OM_FILTER.list().indexOf(this); }
}
 

export class TXN_PURPOSE {

     public static get SPA() { return new TXN_PURPOSE('SPA', 'SPA'); } 
public static get SERVICE_FEE() { return new TXN_PURPOSE('SERVICE_FEE', 'SERVICE_FEE'); } 
public static get SLEEPING_CHARGES() { return new TXN_PURPOSE('SLEEPING_CHARGES', 'SLEEPING_CHARGES'); } 
public static get ONBOARDING_FEE() { return new TXN_PURPOSE('ONBOARDING_FEE', 'ONBOARDING_FEE'); } 
public static get MSF() { return new TXN_PURPOSE('MSF', 'MSF'); } 
public static get SETTLEMENT() { return new TXN_PURPOSE('SETTLEMENT', 'SETTLEMENT'); } 
public static get OTHER_FEE() { return new TXN_PURPOSE('OTHER_FEE', 'OTHER_FEE'); } 

     public static get DEFAULT_VALUE() { return TXN_PURPOSE.SPA; }

     public static withIdOption(id: string): TXN_PURPOSE| undefined  {
        try {
            return TXN_PURPOSE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return TXN_PURPOSE.withIdOption(id) !== undefined; }
    public static list(): TXN_PURPOSE[]  { return [TXN_PURPOSE.ONBOARDING_FEE, TXN_PURPOSE.SERVICE_FEE, TXN_PURPOSE.SPA, TXN_PURPOSE.MSF, TXN_PURPOSE.SLEEPING_CHARGES, TXN_PURPOSE.OTHER_FEE, TXN_PURPOSE.SETTLEMENT]; }
    public static idList(): string[]  { return ['SPA', 'MSF', 'SLEEPING_CHARGES', 'OTHER_FEE', 'ONBOARDING_FEE', 'SERVICE_FEE', 'SETTLEMENT']; }
    public static nameList(): string[]  { return ['SPA', 'MSF', 'SLEEPING_CHARGES', 'OTHER_FEE', 'ONBOARDING_FEE', 'SERVICE_FEE', 'SETTLEMENT']; }


    public static withId(id: string): TXN_PURPOSE  {
        switch (id) {
            case 'SLEEPING_CHARGES': return TXN_PURPOSE.SLEEPING_CHARGES; 
case 'SPA': return TXN_PURPOSE.SPA; 
case 'OTHER_FEE': return TXN_PURPOSE.OTHER_FEE; 
case 'SERVICE_FEE': return TXN_PURPOSE.SERVICE_FEE; 
case 'SETTLEMENT': return TXN_PURPOSE.SETTLEMENT; 
case 'ONBOARDING_FEE': return TXN_PURPOSE.ONBOARDING_FEE; 
case 'MSF': return TXN_PURPOSE.MSF; 
            default: throw new Error('Invalid id for TXN_PURPOSE: ' + id);
        }
    }

    public static withName(name: string): TXN_PURPOSE  {
        switch (name) {
            case 'SLEEPING_CHARGES': return TXN_PURPOSE.SLEEPING_CHARGES; 
case 'SPA': return TXN_PURPOSE.SPA; 
case 'OTHER_FEE': return TXN_PURPOSE.OTHER_FEE; 
case 'SERVICE_FEE': return TXN_PURPOSE.SERVICE_FEE; 
case 'SETTLEMENT': return TXN_PURPOSE.SETTLEMENT; 
case 'ONBOARDING_FEE': return TXN_PURPOSE.ONBOARDING_FEE; 
case 'MSF': return TXN_PURPOSE.MSF; 
            default: throw new Error('Invalid id for TXN_PURPOSE: ' + name);
        }
    }

    public static copyMe(given: TXN_PURPOSE): TXN_PURPOSE  {
            return TXN_PURPOSE.withId(given.id)
    }

    public static fromJson(json: string): TXN_PURPOSE { return TXN_PURPOSE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return TXN_PURPOSE.list().indexOf(this); }
}
 


  


export class InsightStore {

   
    public static fromJsonString(jsonString: string): InsightStore {
       const jsonObj: object = JSON.parse(jsonString);
       return InsightStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InsightStore {
        const json: any = o ;
        return new InsightStore(
         (json.demographyOutput != null) ? (json.demographyOutput as object[]).map((x) => DemographyOutput.fromJson(x)) : [],
(json.partnerClientConversionFunnelOutput != null) ? PartnerClientConversionFunnelOutput.fromJson(json.partnerClientConversionFunnelOutput) : new PartnerClientConversionFunnelOutput(),
(json.dailyFunnelOutput != null) ? (json.dailyFunnelOutput as object[]).map((x) => DailyFunnelOutput.fromJson(x)) : [],
(json.monthlyFunnelOutput != null) ? (json.monthlyFunnelOutput as object[]).map((x) => MonthlyFunnelOutput.fromJson(x)) : [],
(json.conversionSummaryOutput != null) ? ConversionSummaryOutput.fromJson(json.conversionSummaryOutput) : new ConversionSummaryOutput(),
(json.searchCriteria != null) ? SearchClientFileInput.fromJson(json.searchCriteria) : new SearchClientFileInput(),
(json.searchResultList != null) ? SearchClientFileOutput.fromJson(json.searchResultList) : new SearchClientFileOutput()
        );
    }



  


   
    constructor(
        public demographyOutput: DemographyOutput[] = [],
public partnerClientConversionFunnelOutput: PartnerClientConversionFunnelOutput = new PartnerClientConversionFunnelOutput(),
public dailyFunnelOutput: DailyFunnelOutput[] = [],
public monthlyFunnelOutput: MonthlyFunnelOutput[] = [],
public conversionSummaryOutput: ConversionSummaryOutput = new ConversionSummaryOutput(),
public searchCriteria: SearchClientFileInput = new SearchClientFileInput(),
public searchResultList: SearchClientFileOutput = new SearchClientFileOutput()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          demographyOutput: (this.demographyOutput != null) ? this.demographyOutput.map((x) => x.toJson()) : [],
partnerClientConversionFunnelOutput: (this.partnerClientConversionFunnelOutput != null) ? this.partnerClientConversionFunnelOutput.toJson() : undefined,
dailyFunnelOutput: (this.dailyFunnelOutput != null) ? this.dailyFunnelOutput.map((x) => x.toJson()) : [],
monthlyFunnelOutput: (this.monthlyFunnelOutput != null) ? this.monthlyFunnelOutput.map((x) => x.toJson()) : [],
conversionSummaryOutput: (this.conversionSummaryOutput != null) ? this.conversionSummaryOutput.toJson() : undefined,
searchCriteria: (this.searchCriteria != null) ? this.searchCriteria.toJson() : undefined,
searchResultList: (this.searchResultList != null) ? this.searchResultList.toJson() : undefined
        };
    }
    



}



export class SearchClientFileInput {

   
    public static fromJsonString(jsonString: string): SearchClientFileInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SearchClientFileInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SearchClientFileInput {
        const json: any = o ;
        return new SearchClientFileInput(
         (json.fromDate != null) ? json.fromDate : undefined,
(json.toDate != null) ? json.toDate : undefined,
(json.email != null) ? json.email : undefined,
(json.mobile != null) ? json.mobile : undefined,
(json.offset != null) ? json.offset : 0,
(json.count != null) ? json.count : 0,
(json.clientFileStatusList != null) ? (json.clientFileStatusList as string[]).map((x) => CLIENT_FILE_STATUS.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public fromDate: string|undefined = undefined,
public toDate: string|undefined = undefined,
public email: string|undefined = undefined,
public mobile: string|undefined = undefined,
public offset: number = 0,
public count: number = 0,
public clientFileStatusList: CLIENT_FILE_STATUS[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
email: (this.email != null) ? this.email : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
offset: (this.offset != null) ? this.offset : undefined,
count: (this.count != null) ? this.count : undefined,
clientFileStatusList: (this.clientFileStatusList != null) ? this.clientFileStatusList.map((x) => CLIENT_FILE_STATUS.copyMe(x).toJson()) : []
        };
    }
    



}



export class PartnerClientData {

   
    public static fromJsonString(jsonString: string): PartnerClientData {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerClientData.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerClientData {
        const json: any = o ;
        return new PartnerClientData(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.createdOn != null) ? json.createdOn : '',
(json.totalMSFPaid != null) ? json.totalMSFPaid : 0,
(json.lastMSFPaidAt != null) ? json.lastMSFPaidAt : '',
(json.orgCode != null) ? json.orgCode : '',
(json.clientFileStatus != null) ? json.clientFileStatus : '',
(json.fullName != null) ? json.fullName : '',
(json.mobile != null) ? json.mobile : '',
(json.email != null) ? json.email : '',
(json.city != null) ? json.city : '',
(json.isAgreementDone != null) ? json.isAgreementDone : false,
(json.firstMSFPaid != null) ? json.firstMSFPaid : false,
(json.isEmandateDone != null) ? json.isEmandateDone : false,
(json.wad != null) ? json.wad : 0,
(json.creditScore != null) ? json.creditScore : 0,
(json.totalCurrentOutstanding != null) ? json.totalCurrentOutstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.onBoardingFeePaid != null) ? json.onBoardingFeePaid : false,
(json.onBoardingFeeAmount != null) ? json.onBoardingFeeAmount : 0,
(json.onBoardingDate != null) ? json.onBoardingDate : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public createdOn: string = '',
public totalMSFPaid: number = 0,
public lastMSFPaidAt: string = '',
public orgCode: string = '',
public clientFileStatus: string = '',
public fullName: string = '',
public mobile: string = '',
public email: string = '',
public city: string = '',
public isAgreementDone: boolean = false,
public firstMSFPaid: boolean = false,
public isEmandateDone: boolean = false,
public wad: number = 0,
public creditScore: number = 0,
public totalCurrentOutstanding: number = 0,
public tenor: number = 0,
public msfDraftAmount: number = 0,
public onBoardingFeePaid: boolean = false,
public onBoardingFeeAmount: number = 0,
public onBoardingDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
createdOn: (this.createdOn != null) ? this.createdOn : undefined,
totalMSFPaid: (this.totalMSFPaid != null) ? this.totalMSFPaid : undefined,
lastMSFPaidAt: (this.lastMSFPaidAt != null) ? this.lastMSFPaidAt : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined,
clientFileStatus: (this.clientFileStatus != null) ? this.clientFileStatus : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
email: (this.email != null) ? this.email : undefined,
city: (this.city != null) ? this.city : undefined,
isAgreementDone: (this.isAgreementDone != null) ? this.isAgreementDone : undefined,
firstMSFPaid: (this.firstMSFPaid != null) ? this.firstMSFPaid : undefined,
isEmandateDone: (this.isEmandateDone != null) ? this.isEmandateDone : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
totalCurrentOutstanding: (this.totalCurrentOutstanding != null) ? this.totalCurrentOutstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
onBoardingFeePaid: (this.onBoardingFeePaid != null) ? this.onBoardingFeePaid : undefined,
onBoardingFeeAmount: (this.onBoardingFeeAmount != null) ? this.onBoardingFeeAmount : undefined,
onBoardingDate: (this.onBoardingDate != null) ? this.onBoardingDate : undefined
        };
    }
    



}



export class SearchClientFileOutput {

   
    public static fromJsonString(jsonString: string): SearchClientFileOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return SearchClientFileOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SearchClientFileOutput {
        const json: any = o ;
        return new SearchClientFileOutput(
         (json.totalCount != null) ? json.totalCount : 0,
(json.partnerClientDataList != null) ? (json.partnerClientDataList as object[]).map((x) => PartnerClientData.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public totalCount: number = 0,
public partnerClientDataList: PartnerClientData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
partnerClientDataList: (this.partnerClientDataList != null) ? this.partnerClientDataList.map((x) => x.toJson()) : []
        };
    }
    



}



export class GetDailySalesDataInput {

   
    public static fromJsonString(jsonString: string): GetDailySalesDataInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetDailySalesDataInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetDailySalesDataInput {
        const json: any = o ;
        return new GetDailySalesDataInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class DailySalesData {

   
    public static fromJsonString(jsonString: string): DailySalesData {
       const jsonObj: object = JSON.parse(jsonString);
       return DailySalesData.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DailySalesData {
        const json: any = o ;
        return new DailySalesData(
         (json.saleDate != null) ? json.saleDate : '',
(json.aoCount != null) ? json.aoCount : 0,
(json.aomCount != null) ? json.aomCount : 0,
(json.aompCount != null) ? json.aompCount : 0,
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public saleDate: string = '',
public aoCount: number = 0,
public aomCount: number = 0,
public aompCount: number = 0,
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          saleDate: (this.saleDate != null) ? this.saleDate : undefined,
aoCount: (this.aoCount != null) ? this.aoCount : undefined,
aomCount: (this.aomCount != null) ? this.aomCount : undefined,
aompCount: (this.aompCount != null) ? this.aompCount : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class DailySalesDataInfo {

   
    public static fromJsonString(jsonString: string): DailySalesDataInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return DailySalesDataInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DailySalesDataInfo {
        const json: any = o ;
        return new DailySalesDataInfo(
         (json.dailySalesDataList != null) ? (json.dailySalesDataList as object[]).map((x) => DailySalesData.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public dailySalesDataList: DailySalesData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          dailySalesDataList: (this.dailySalesDataList != null) ? this.dailySalesDataList.map((x) => x.toJson()) : []
        };
    }
    



}



export class GetCollectionReportInput {

   
    public static fromJsonString(jsonString: string): GetCollectionReportInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetCollectionReportInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetCollectionReportInput {
        const json: any = o ;
        return new GetCollectionReportInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class CollectionData {

   
    public static fromJsonString(jsonString: string): CollectionData {
       const jsonObj: object = JSON.parse(jsonString);
       return CollectionData.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CollectionData {
        const json: any = o ;
        return new CollectionData(
         (json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.fppClientRemoteId != null) ? json.fppClientRemoteId : '',
(json.msf != null) ? json.msf : 0,
(json.valueDate != null) ? json.valueDate : '',
(json.txnPurpose != null) ? TXN_PURPOSE.fromJson(json.txnPurpose) : TXN_PURPOSE.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileNumber: string = '',
public fppClientRemoteId: string = '',
public msf: number = 0,
public valueDate: string = '',
public txnPurpose: TXN_PURPOSE = TXN_PURPOSE.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
fppClientRemoteId: (this.fppClientRemoteId != null) ? this.fppClientRemoteId : undefined,
msf: (this.msf != null) ? this.msf : undefined,
valueDate: (this.valueDate != null) ? this.valueDate : undefined,
txnPurpose: (this.txnPurpose != null) ?  TXN_PURPOSE.copyMe(this.txnPurpose).toJson() : undefined
        };
    }
    



}



export class CollectionReportInfo {

   
    public static fromJsonString(jsonString: string): CollectionReportInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return CollectionReportInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CollectionReportInfo {
        const json: any = o ;
        return new CollectionReportInfo(
         (json.totalCount != null) ? json.totalCount : 0,
(json.collectionDataList != null) ? (json.collectionDataList as object[]).map((x) => CollectionData.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public totalCount: number = 0,
public collectionDataList: CollectionData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
collectionDataList: (this.collectionDataList != null) ? this.collectionDataList.map((x) => x.toJson()) : []
        };
    }
    



}



export class GetPartnerClientListForRangeInput {

   
    public static fromJsonString(jsonString: string): GetPartnerClientListForRangeInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetPartnerClientListForRangeInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetPartnerClientListForRangeInput {
        const json: any = o ;
        return new GetPartnerClientListForRangeInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.filterBy != null) ? json.filterBy : undefined,
(json.offset != null) ? json.offset : 0,
(json.count != null) ? json.count : 0
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public filterBy: string|undefined = undefined,
public offset: number = 0,
public count: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
filterBy: (this.filterBy != null) ? this.filterBy : undefined,
offset: (this.offset != null) ? this.offset : undefined,
count: (this.count != null) ? this.count : undefined
        };
    }
    



}



export class PartnerClientListForRangeInfo {

   
    public static fromJsonString(jsonString: string): PartnerClientListForRangeInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerClientListForRangeInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerClientListForRangeInfo {
        const json: any = o ;
        return new PartnerClientListForRangeInfo(
         (json.totalCount != null) ? json.totalCount : 0,
(json.PartnerClientList != null) ? (json.PartnerClientList as object[]).map((x) => PartnerClientData.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public totalCount: number = 0,
public PartnerClientList: PartnerClientData[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
PartnerClientList: (this.PartnerClientList != null) ? this.PartnerClientList.map((x) => x.toJson()) : []
        };
    }
    



}



export class GetPartnerClientCountInput {

   
    public static fromJsonString(jsonString: string): GetPartnerClientCountInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetPartnerClientCountInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetPartnerClientCountInput {
        const json: any = o ;
        return new GetPartnerClientCountInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class GetPartnerClientCountOutput {

   
    public static fromJsonString(jsonString: string): GetPartnerClientCountOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetPartnerClientCountOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetPartnerClientCountOutput {
        const json: any = o ;
        return new GetPartnerClientCountOutput(
         (json.totalLeadCount != null) ? json.totalLeadCount : 0,
(json.footfallCount != null) ? json.footfallCount : 0,
(json.onBoardedCount != null) ? json.onBoardedCount : 0,
(json.mandateDoneCount != null) ? json.mandateDoneCount : 0
        );
    }



  


   
    constructor(
        public totalLeadCount: number = 0,
public footfallCount: number = 0,
public onBoardedCount: number = 0,
public mandateDoneCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalLeadCount: (this.totalLeadCount != null) ? this.totalLeadCount : undefined,
footfallCount: (this.footfallCount != null) ? this.footfallCount : undefined,
onBoardedCount: (this.onBoardedCount != null) ? this.onBoardedCount : undefined,
mandateDoneCount: (this.mandateDoneCount != null) ? this.mandateDoneCount : undefined
        };
    }
    



}



export class FindParticularPartnerClientInput {

   
    public static fromJsonString(jsonString: string): FindParticularPartnerClientInput {
       const jsonObj: object = JSON.parse(jsonString);
       return FindParticularPartnerClientInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FindParticularPartnerClientInput {
        const json: any = o ;
        return new FindParticularPartnerClientInput(
         (json.mobile != null) ? json.mobile : undefined,
(json.clientFileNumber != null) ? json.clientFileNumber : undefined
        );
    }



  


   
    constructor(
        public mobile: string|undefined = undefined,
public clientFileNumber: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mobile: (this.mobile != null) ? this.mobile : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined
        };
    }
    



}



export class FindParticularPartnerClientOutput {

   
    public static fromJsonString(jsonString: string): FindParticularPartnerClientOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return FindParticularPartnerClientOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FindParticularPartnerClientOutput {
        const json: any = o ;
        return new FindParticularPartnerClientOutput(
         (json.mayBePartnerClient != null) ? PartnerClientData.fromJson(json.mayBePartnerClient) : undefined,
(json.isPartnerClientDataExist != null) ? json.isPartnerClientDataExist : false
        );
    }



  


   
    constructor(
        public mayBePartnerClient: PartnerClientData|undefined = undefined,
public isPartnerClientDataExist: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          mayBePartnerClient: (this.mayBePartnerClient != null) ? this.mayBePartnerClient.toJson() : undefined,
isPartnerClientDataExist: (this.isPartnerClientDataExist != null) ? this.isPartnerClientDataExist : undefined
        };
    }
    



}



export class GetPartnerClientFunnelForFppInput {

   
    public static fromJsonString(jsonString: string): GetPartnerClientFunnelForFppInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetPartnerClientFunnelForFppInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetPartnerClientFunnelForFppInput {
        const json: any = o ;
        return new GetPartnerClientFunnelForFppInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class PartnerClientConversionFunnelForFpp {

   
    public static fromJsonString(jsonString: string): PartnerClientConversionFunnelForFpp {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerClientConversionFunnelForFpp.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerClientConversionFunnelForFpp {
        const json: any = o ;
        return new PartnerClientConversionFunnelForFpp(
         (json.leadCount != null) ? json.leadCount : 0,
(json.creditReportCount != null) ? json.creditReportCount : 0,
(json.onBoardingFeePaidCount != null) ? json.onBoardingFeePaidCount : 0,
(json.onBoardingFeePaidCountConversionPercentage != null) ? json.onBoardingFeePaidCountConversionPercentage : 0,
(json.eMandateDoneCount != null) ? json.eMandateDoneCount : 0,
(json.eMandateDoneCountConversionPercentage != null) ? json.eMandateDoneCountConversionPercentage : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public creditReportCount: number = 0,
public onBoardingFeePaidCount: number = 0,
public onBoardingFeePaidCountConversionPercentage: number = 0,
public eMandateDoneCount: number = 0,
public eMandateDoneCountConversionPercentage: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
creditReportCount: (this.creditReportCount != null) ? this.creditReportCount : undefined,
onBoardingFeePaidCount: (this.onBoardingFeePaidCount != null) ? this.onBoardingFeePaidCount : undefined,
onBoardingFeePaidCountConversionPercentage: (this.onBoardingFeePaidCountConversionPercentage != null) ? this.onBoardingFeePaidCountConversionPercentage : undefined,
eMandateDoneCount: (this.eMandateDoneCount != null) ? this.eMandateDoneCount : undefined,
eMandateDoneCountConversionPercentage: (this.eMandateDoneCountConversionPercentage != null) ? this.eMandateDoneCountConversionPercentage : undefined
        };
    }
    



}



export class DemographyOutput {

   
    public static fromJsonString(jsonString: string): DemographyOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return DemographyOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DemographyOutput {
        const json: any = o ;
        return new DemographyOutput(
         (json.state != null) ? json.state : '',
(json.ccDebtCount != null) ? json.ccDebtCount : 0,
(json.plDebtCount != null) ? json.plDebtCount : 0,
(json.otherDebtCount != null) ? json.otherDebtCount : 0,
(json.customerCount != null) ? json.customerCount : 0
        );
    }



  


   
    constructor(
        public state: string = '',
public ccDebtCount: number = 0,
public plDebtCount: number = 0,
public otherDebtCount: number = 0,
public customerCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          state: (this.state != null) ? this.state : undefined,
ccDebtCount: (this.ccDebtCount != null) ? this.ccDebtCount : undefined,
plDebtCount: (this.plDebtCount != null) ? this.plDebtCount : undefined,
otherDebtCount: (this.otherDebtCount != null) ? this.otherDebtCount : undefined,
customerCount: (this.customerCount != null) ? this.customerCount : undefined
        };
    }
    



}



export class GetPartnerClientFunnelInput {

   
    public static fromJsonString(jsonString: string): GetPartnerClientFunnelInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetPartnerClientFunnelInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetPartnerClientFunnelInput {
        const json: any = o ;
        return new GetPartnerClientFunnelInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class PartnerClientConversionFunnelOutput {

   
    public static fromJsonString(jsonString: string): PartnerClientConversionFunnelOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return PartnerClientConversionFunnelOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PartnerClientConversionFunnelOutput {
        const json: any = o ;
        return new PartnerClientConversionFunnelOutput(
         (json.leadCount != null) ? json.leadCount : 0,
(json.creditReportCount != null) ? json.creditReportCount : 0,
(json.budgetCount != null) ? json.budgetCount : 0,
(json.budgetCountConversionPercentage != null) ? json.budgetCountConversionPercentage : 0,
(json.programGenCount != null) ? json.programGenCount : 0,
(json.programGenCountConversionPercentage != null) ? json.programGenCountConversionPercentage : 0,
(json.agreementCount != null) ? json.agreementCount : 0,
(json.agreementCountConversionPercentage != null) ? json.agreementCountConversionPercentage : 0,
(json.onBoardingFeePaidCount != null) ? json.onBoardingFeePaidCount : 0,
(json.onBoardingFeePaidCountConversionPercentage != null) ? json.onBoardingFeePaidCountConversionPercentage : 0,
(json.eMandateDoneCount != null) ? json.eMandateDoneCount : 0,
(json.eMandateDoneCountConversionPercentage != null) ? json.eMandateDoneCountConversionPercentage : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public creditReportCount: number = 0,
public budgetCount: number = 0,
public budgetCountConversionPercentage: number = 0,
public programGenCount: number = 0,
public programGenCountConversionPercentage: number = 0,
public agreementCount: number = 0,
public agreementCountConversionPercentage: number = 0,
public onBoardingFeePaidCount: number = 0,
public onBoardingFeePaidCountConversionPercentage: number = 0,
public eMandateDoneCount: number = 0,
public eMandateDoneCountConversionPercentage: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
creditReportCount: (this.creditReportCount != null) ? this.creditReportCount : undefined,
budgetCount: (this.budgetCount != null) ? this.budgetCount : undefined,
budgetCountConversionPercentage: (this.budgetCountConversionPercentage != null) ? this.budgetCountConversionPercentage : undefined,
programGenCount: (this.programGenCount != null) ? this.programGenCount : undefined,
programGenCountConversionPercentage: (this.programGenCountConversionPercentage != null) ? this.programGenCountConversionPercentage : undefined,
agreementCount: (this.agreementCount != null) ? this.agreementCount : undefined,
agreementCountConversionPercentage: (this.agreementCountConversionPercentage != null) ? this.agreementCountConversionPercentage : undefined,
onBoardingFeePaidCount: (this.onBoardingFeePaidCount != null) ? this.onBoardingFeePaidCount : undefined,
onBoardingFeePaidCountConversionPercentage: (this.onBoardingFeePaidCountConversionPercentage != null) ? this.onBoardingFeePaidCountConversionPercentage : undefined,
eMandateDoneCount: (this.eMandateDoneCount != null) ? this.eMandateDoneCount : undefined,
eMandateDoneCountConversionPercentage: (this.eMandateDoneCountConversionPercentage != null) ? this.eMandateDoneCountConversionPercentage : undefined
        };
    }
    



}



export class DailyFunnelOutput {

   
    public static fromJsonString(jsonString: string): DailyFunnelOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return DailyFunnelOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DailyFunnelOutput {
        const json: any = o ;
        return new DailyFunnelOutput(
         (json.leadCount != null) ? json.leadCount : 0,
(json.conversionCount != null) ? json.conversionCount : 0,
(json.ddMM != null) ? json.ddMM : '',
(json.mandateDoneCount != null) ? json.mandateDoneCount : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public conversionCount: number = 0,
public ddMM: string = '',
public mandateDoneCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
conversionCount: (this.conversionCount != null) ? this.conversionCount : undefined,
ddMM: (this.ddMM != null) ? this.ddMM : undefined,
mandateDoneCount: (this.mandateDoneCount != null) ? this.mandateDoneCount : undefined
        };
    }
    



}



export class GetDailyFunnelInput {

   
    public static fromJsonString(jsonString: string): GetDailyFunnelInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetDailyFunnelInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetDailyFunnelInput {
        const json: any = o ;
        return new GetDailyFunnelInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class MonthlyFunnelOutput {

   
    public static fromJsonString(jsonString: string): MonthlyFunnelOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return MonthlyFunnelOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MonthlyFunnelOutput {
        const json: any = o ;
        return new MonthlyFunnelOutput(
         (json.leadCount != null) ? json.leadCount : 0,
(json.conversionCount != null) ? json.conversionCount : 0,
(json.mmYY != null) ? json.mmYY : '',
(json.mandateDoneCount != null) ? json.mandateDoneCount : 0
        );
    }



  


   
    constructor(
        public leadCount: number = 0,
public conversionCount: number = 0,
public mmYY: string = '',
public mandateDoneCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          leadCount: (this.leadCount != null) ? this.leadCount : undefined,
conversionCount: (this.conversionCount != null) ? this.conversionCount : undefined,
mmYY: (this.mmYY != null) ? this.mmYY : undefined,
mandateDoneCount: (this.mandateDoneCount != null) ? this.mandateDoneCount : undefined
        };
    }
    



}



export class GetMonthlyFunnelInput {

   
    public static fromJsonString(jsonString: string): GetMonthlyFunnelInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetMonthlyFunnelInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetMonthlyFunnelInput {
        const json: any = o ;
        return new GetMonthlyFunnelInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined
        };
    }
    



}



export class GetSaleCountDayWiseListInput {

   
    public static fromJsonString(jsonString: string): GetSaleCountDayWiseListInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetSaleCountDayWiseListInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetSaleCountDayWiseListInput {
        const json: any = o ;
        return new GetSaleCountDayWiseListInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class DaywiseCount {

   
    public static fromJsonString(jsonString: string): DaywiseCount {
       const jsonObj: object = JSON.parse(jsonString);
       return DaywiseCount.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DaywiseCount {
        const json: any = o ;
        return new DaywiseCount(
         (json.count != null) ? json.count : 0,
(json.ddMM != null) ? json.ddMM : ''
        );
    }



  


   
    constructor(
        public count: number = 0,
public ddMM: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          count: (this.count != null) ? this.count : undefined,
ddMM: (this.ddMM != null) ? this.ddMM : undefined
        };
    }
    



}



export class SaleCountDayWiseDataInfo {

   
    public static fromJsonString(jsonString: string): SaleCountDayWiseDataInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return SaleCountDayWiseDataInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SaleCountDayWiseDataInfo {
        const json: any = o ;
        return new SaleCountDayWiseDataInfo(
         (json.daywiseCountList != null) ? (json.daywiseCountList as object[]).map((x) => DaywiseCount.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public daywiseCountList: DaywiseCount[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          daywiseCountList: (this.daywiseCountList != null) ? this.daywiseCountList.map((x) => x.toJson()) : []
        };
    }
    



}



export class GetMandateCountDayWiseListInput {

   
    public static fromJsonString(jsonString: string): GetMandateCountDayWiseListInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetMandateCountDayWiseListInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetMandateCountDayWiseListInput {
        const json: any = o ;
        return new GetMandateCountDayWiseListInput(
         (json.fromDate != null) ? json.fromDate : '',
(json.toDate != null) ? json.toDate : '',
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public fromDate: string = '',
public toDate: string = '',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fromDate: (this.fromDate != null) ? this.fromDate : undefined,
toDate: (this.toDate != null) ? this.toDate : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class MandateCountDayWiseDataInfo {

   
    public static fromJsonString(jsonString: string): MandateCountDayWiseDataInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return MandateCountDayWiseDataInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MandateCountDayWiseDataInfo {
        const json: any = o ;
        return new MandateCountDayWiseDataInfo(
         (json.daywiseCountList != null) ? (json.daywiseCountList as object[]).map((x) => DaywiseCount.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public daywiseCountList: DaywiseCount[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          daywiseCountList: (this.daywiseCountList != null) ? this.daywiseCountList.map((x) => x.toJson()) : []
        };
    }
    



}



export class ConversionSummaryOutput {

   
    public static fromJsonString(jsonString: string): ConversionSummaryOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return ConversionSummaryOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ConversionSummaryOutput {
        const json: any = o ;
        return new ConversionSummaryOutput(
         (json.todaysLeadCount != null) ? json.todaysLeadCount : 0,
(json.percentageDifferenceLeads != null) ? json.percentageDifferenceLeads : 0,
(json.todaysOnBoardingDoneConversion != null) ? json.todaysOnBoardingDoneConversion : 0,
(json.todaysEmandateDoneConversion != null) ? json.todaysEmandateDoneConversion : 0,
(json.mtdLeadCount != null) ? json.mtdLeadCount : 0,
(json.mtdOnBoardingDoneConversion != null) ? json.mtdOnBoardingDoneConversion : 0,
(json.mtdEmandateDoneConversion != null) ? json.mtdEmandateDoneConversion : 0,
(json.percentageDifferenceOnBoardingConversion != null) ? json.percentageDifferenceOnBoardingConversion : 0,
(json.percentageDifferenceEmandateConversion != null) ? json.percentageDifferenceEmandateConversion : 0
        );
    }



  


   
    constructor(
        public todaysLeadCount: number = 0,
public percentageDifferenceLeads: number = 0,
public todaysOnBoardingDoneConversion: number = 0,
public todaysEmandateDoneConversion: number = 0,
public mtdLeadCount: number = 0,
public mtdOnBoardingDoneConversion: number = 0,
public mtdEmandateDoneConversion: number = 0,
public percentageDifferenceOnBoardingConversion: number = 0,
public percentageDifferenceEmandateConversion: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          todaysLeadCount: (this.todaysLeadCount != null) ? this.todaysLeadCount : undefined,
percentageDifferenceLeads: (this.percentageDifferenceLeads != null) ? this.percentageDifferenceLeads : undefined,
todaysOnBoardingDoneConversion: (this.todaysOnBoardingDoneConversion != null) ? this.todaysOnBoardingDoneConversion : undefined,
todaysEmandateDoneConversion: (this.todaysEmandateDoneConversion != null) ? this.todaysEmandateDoneConversion : undefined,
mtdLeadCount: (this.mtdLeadCount != null) ? this.mtdLeadCount : undefined,
mtdOnBoardingDoneConversion: (this.mtdOnBoardingDoneConversion != null) ? this.mtdOnBoardingDoneConversion : undefined,
mtdEmandateDoneConversion: (this.mtdEmandateDoneConversion != null) ? this.mtdEmandateDoneConversion : undefined,
percentageDifferenceOnBoardingConversion: (this.percentageDifferenceOnBoardingConversion != null) ? this.percentageDifferenceOnBoardingConversion : undefined,
percentageDifferenceEmandateConversion: (this.percentageDifferenceEmandateConversion != null) ? this.percentageDifferenceEmandateConversion : undefined
        };
    }
    



}



}



export namespace Spine {
  

export class DOCUMENT_REF_TYPE {

     public static get CLIENT_FILE() { return new DOCUMENT_REF_TYPE('CLIENT_FILE', 'CLIENT_FILE'); } 
public static get TICKET() { return new DOCUMENT_REF_TYPE('TICKET', 'TICKET'); } 

     public static get DEFAULT_VALUE() { return DOCUMENT_REF_TYPE.CLIENT_FILE; }

     public static withIdOption(id: string): DOCUMENT_REF_TYPE| undefined  {
        try {
            return DOCUMENT_REF_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return DOCUMENT_REF_TYPE.withIdOption(id) !== undefined; }
    public static list(): DOCUMENT_REF_TYPE[]  { return [DOCUMENT_REF_TYPE.CLIENT_FILE, DOCUMENT_REF_TYPE.TICKET]; }
    public static idList(): string[]  { return ['CLIENT_FILE', 'TICKET']; }
    public static nameList(): string[]  { return ['CLIENT_FILE', 'TICKET']; }


    public static withId(id: string): DOCUMENT_REF_TYPE  {
        switch (id) {
            case 'CLIENT_FILE': return DOCUMENT_REF_TYPE.CLIENT_FILE; 
case 'TICKET': return DOCUMENT_REF_TYPE.TICKET; 
            default: throw new Error('Invalid id for DOCUMENT_REF_TYPE: ' + id);
        }
    }

    public static withName(name: string): DOCUMENT_REF_TYPE  {
        switch (name) {
            case 'CLIENT_FILE': return DOCUMENT_REF_TYPE.CLIENT_FILE; 
case 'TICKET': return DOCUMENT_REF_TYPE.TICKET; 
            default: throw new Error('Invalid id for DOCUMENT_REF_TYPE: ' + name);
        }
    }

    public static copyMe(given: DOCUMENT_REF_TYPE): DOCUMENT_REF_TYPE  {
            return DOCUMENT_REF_TYPE.withId(given.id)
    }

    public static fromJson(json: string): DOCUMENT_REF_TYPE { return DOCUMENT_REF_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return DOCUMENT_REF_TYPE.list().indexOf(this); }
}
 

export class DocumentType {

     public static get PAN() { return new DocumentType('PAN', 'PAN'); } 
public static get INCOME_PROOF_BANK_STATEMENT() { return new DocumentType('INCOME_PROOF_BANK_STATEMENT', 'Income Proof - Bank Statement'); } 
public static get CREDIT_REPORT() { return new DocumentType('CREDIT_REPORT', 'Credit Report'); } 
public static get OTHERS() { return new DocumentType('OTHERS', 'Others'); } 
public static get INCOME_PROOF_RENT_RECEIPTS() { return new DocumentType('INCOME_PROOF_RENT_RECEIPTS', 'Income Proof - Rent Receipts'); } 
public static get INCOME_PROOF_FORM_16() { return new DocumentType('INCOME_PROOF_FORM_16', 'Income Proof - Form 16'); } 
public static get SIF_DOCUMENT() { return new DocumentType('SIF_DOCUMENT', 'SIF Document'); } 
public static get CALL_RECORDING() { return new DocumentType('CALL_RECORDING', 'Call Recordings'); } 
public static get PHOTO() { return new DocumentType('PHOTO', 'PHOTO'); } 
public static get INCOME_PROOF_ITR() { return new DocumentType('INCOME_PROOF_ITR', 'Income Proof - ITR'); } 
public static get INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT() { return new DocumentType('INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT', 'Income Proof - Balance Sheet for Deposit'); } 
public static get INCOME_PROOF_SALARY_SLIP() { return new DocumentType('INCOME_PROOF_SALARY_SLIP', 'Income Proof - Salary Slip'); } 
public static get CHPP() { return new DocumentType('CHPP', 'CHPP'); } 

     public static get DEFAULT_VALUE() { return DocumentType.PAN; }

     public static withIdOption(id: string): DocumentType| undefined  {
        try {
            return DocumentType.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return DocumentType.withIdOption(id) !== undefined; }
    public static list(): DocumentType[]  { return [DocumentType.INCOME_PROOF_SALARY_SLIP, DocumentType.INCOME_PROOF_BANK_STATEMENT, DocumentType.INCOME_PROOF_FORM_16, DocumentType.INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT, DocumentType.CALL_RECORDING, DocumentType.CREDIT_REPORT, DocumentType.OTHERS, DocumentType.PAN, DocumentType.SIF_DOCUMENT, DocumentType.INCOME_PROOF_RENT_RECEIPTS, DocumentType.INCOME_PROOF_ITR, DocumentType.CHPP, DocumentType.PHOTO]; }
    public static idList(): string[]  { return ['PHOTO', 'CHPP', 'SIF_DOCUMENT', 'OTHERS', 'INCOME_PROOF_RENT_RECEIPTS', 'INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT', 'CREDIT_REPORT', 'INCOME_PROOF_BANK_STATEMENT', 'CALL_RECORDING', 'INCOME_PROOF_ITR', 'INCOME_PROOF_FORM_16', 'INCOME_PROOF_SALARY_SLIP', 'PAN']; }
    public static nameList(): string[]  { return ['PHOTO', 'CHPP', 'Income Proof - ITR', 'Credit Report', 'SIF Document', 'Call Recordings', 'Income Proof - Form 16', 'Others', 'Income Proof - Salary Slip', 'Income Proof - Bank Statement', 'Income Proof - Rent Receipts', 'PAN', 'Income Proof - Balance Sheet for Deposit']; }


    public static withId(id: string): DocumentType  {
        switch (id) {
            case 'CALL_RECORDING': return DocumentType.CALL_RECORDING; 
case 'CHPP': return DocumentType.CHPP; 
case 'SIF_DOCUMENT': return DocumentType.SIF_DOCUMENT; 
case 'INCOME_PROOF_BANK_STATEMENT': return DocumentType.INCOME_PROOF_BANK_STATEMENT; 
case 'INCOME_PROOF_RENT_RECEIPTS': return DocumentType.INCOME_PROOF_RENT_RECEIPTS; 
case 'INCOME_PROOF_SALARY_SLIP': return DocumentType.INCOME_PROOF_SALARY_SLIP; 
case 'PAN': return DocumentType.PAN; 
case 'INCOME_PROOF_FORM_16': return DocumentType.INCOME_PROOF_FORM_16; 
case 'INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT': return DocumentType.INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT; 
case 'PHOTO': return DocumentType.PHOTO; 
case 'CREDIT_REPORT': return DocumentType.CREDIT_REPORT; 
case 'OTHERS': return DocumentType.OTHERS; 
case 'INCOME_PROOF_ITR': return DocumentType.INCOME_PROOF_ITR; 
            default: throw new Error('Invalid id for DocumentType: ' + id);
        }
    }

    public static withName(name: string): DocumentType  {
        switch (name) {
            case 'Income Proof - Form 16': return DocumentType.INCOME_PROOF_FORM_16; 
case 'SIF Document': return DocumentType.SIF_DOCUMENT; 
case 'Income Proof - ITR': return DocumentType.INCOME_PROOF_ITR; 
case 'Credit Report': return DocumentType.CREDIT_REPORT; 
case 'Income Proof - Salary Slip': return DocumentType.INCOME_PROOF_SALARY_SLIP; 
case 'Income Proof - Rent Receipts': return DocumentType.INCOME_PROOF_RENT_RECEIPTS; 
case 'CHPP': return DocumentType.CHPP; 
case 'Call Recordings': return DocumentType.CALL_RECORDING; 
case 'PAN': return DocumentType.PAN; 
case 'Income Proof - Balance Sheet for Deposit': return DocumentType.INCOME_PROOF_BALANCE_SHEET_FOR_DEPOSIT; 
case 'Others': return DocumentType.OTHERS; 
case 'Income Proof - Bank Statement': return DocumentType.INCOME_PROOF_BANK_STATEMENT; 
case 'PHOTO': return DocumentType.PHOTO; 
            default: throw new Error('Invalid id for DocumentType: ' + name);
        }
    }

    public static copyMe(given: DocumentType): DocumentType  {
            return DocumentType.withId(given.id)
    }

    public static fromJson(json: string): DocumentType { return DocumentType.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return DocumentType.list().indexOf(this); }
}
 

export class CREDIT_BUREAU {

     public static get CIBIL() { return new CREDIT_BUREAU('CIBIL', 'CIBIL'); } 
public static get EXPERIAN() { return new CREDIT_BUREAU('EXPERIAN', 'EXPERIAN'); } 
public static get EQUIFAX() { return new CREDIT_BUREAU('EQUIFAX', 'EQUIFAX'); } 

     public static get DEFAULT_VALUE() { return CREDIT_BUREAU.CIBIL; }

     public static withIdOption(id: string): CREDIT_BUREAU| undefined  {
        try {
            return CREDIT_BUREAU.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CREDIT_BUREAU.withIdOption(id) !== undefined; }
    public static list(): CREDIT_BUREAU[]  { return [CREDIT_BUREAU.CIBIL, CREDIT_BUREAU.EXPERIAN, CREDIT_BUREAU.EQUIFAX]; }
    public static idList(): string[]  { return ['CIBIL', 'EXPERIAN', 'EQUIFAX']; }
    public static nameList(): string[]  { return ['CIBIL', 'EXPERIAN', 'EQUIFAX']; }


    public static withId(id: string): CREDIT_BUREAU  {
        switch (id) {
            case 'CIBIL': return CREDIT_BUREAU.CIBIL; 
case 'EXPERIAN': return CREDIT_BUREAU.EXPERIAN; 
case 'EQUIFAX': return CREDIT_BUREAU.EQUIFAX; 
            default: throw new Error('Invalid id for CREDIT_BUREAU: ' + id);
        }
    }

    public static withName(name: string): CREDIT_BUREAU  {
        switch (name) {
            case 'CIBIL': return CREDIT_BUREAU.CIBIL; 
case 'EXPERIAN': return CREDIT_BUREAU.EXPERIAN; 
case 'EQUIFAX': return CREDIT_BUREAU.EQUIFAX; 
            default: throw new Error('Invalid id for CREDIT_BUREAU: ' + name);
        }
    }

    public static copyMe(given: CREDIT_BUREAU): CREDIT_BUREAU  {
            return CREDIT_BUREAU.withId(given.id)
    }

    public static fromJson(json: string): CREDIT_BUREAU { return CREDIT_BUREAU.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CREDIT_BUREAU.list().indexOf(this); }
}
 

export class AccountType {

     public static get SAVINGS() { return new AccountType('SAVINGS', 'SAVINGS'); } 
public static get CURRENT() { return new AccountType('CURRENT', 'CURRENT'); } 

     public static get DEFAULT_VALUE() { return AccountType.SAVINGS; }

     public static withIdOption(id: string): AccountType| undefined  {
        try {
            return AccountType.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return AccountType.withIdOption(id) !== undefined; }
    public static list(): AccountType[]  { return [AccountType.SAVINGS, AccountType.CURRENT]; }
    public static idList(): string[]  { return ['SAVINGS', 'CURRENT']; }
    public static nameList(): string[]  { return ['SAVINGS', 'CURRENT']; }


    public static withId(id: string): AccountType  {
        switch (id) {
            case 'SAVINGS': return AccountType.SAVINGS; 
case 'CURRENT': return AccountType.CURRENT; 
            default: throw new Error('Invalid id for AccountType: ' + id);
        }
    }

    public static withName(name: string): AccountType  {
        switch (name) {
            case 'SAVINGS': return AccountType.SAVINGS; 
case 'CURRENT': return AccountType.CURRENT; 
            default: throw new Error('Invalid id for AccountType: ' + name);
        }
    }

    public static copyMe(given: AccountType): AccountType  {
            return AccountType.withId(given.id)
    }

    public static fromJson(json: string): AccountType { return AccountType.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return AccountType.list().indexOf(this); }
}
 


  


export class RouterStore {

   
    public static fromJsonString(jsonString: string): RouterStore {
       const jsonObj: object = JSON.parse(jsonString);
       return RouterStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): RouterStore {
        const json: any = o ;
        return new RouterStore(
         (json.flattenedRouterList != null) ? json.flattenedRouterList : []
        );
    }



  


   
    constructor(
        public flattenedRouterList: object[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          flattenedRouterList: (this.flattenedRouterList != null) ? this.flattenedRouterList : []
        };
    }
    



}



export class ConfirmationAlertInfo {

   
    public static fromJsonString(jsonString: string): ConfirmationAlertInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ConfirmationAlertInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ConfirmationAlertInfo {
        const json: any = o ;
        return new ConfirmationAlertInfo(
         (json.message != null) ? json.message : '',
(json.show != null) ? json.show : false,
(json.onConfirm != null) ? json.onConfirm : {}
        );
    }



  


   
    constructor(
        public message: string = '',
public show: boolean = false,
public onConfirm: object = {}
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          message: (this.message != null) ? this.message : undefined,
show: (this.show != null) ? this.show : undefined,
onConfirm: (this.onConfirm != null) ? this.onConfirm : undefined
        };
    }
    



}



export class GenericStore {

   
    public static fromJsonString(jsonString: string): GenericStore {
       const jsonObj: object = JSON.parse(jsonString);
       return GenericStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GenericStore {
        const json: any = o ;
        return new GenericStore(
         (json.confirmationAlertInfo != null) ? ConfirmationAlertInfo.fromJson(json.confirmationAlertInfo) : new ConfirmationAlertInfo()
        );
    }



  


   
    constructor(
        public confirmationAlertInfo: ConfirmationAlertInfo = new ConfirmationAlertInfo()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          confirmationAlertInfo: (this.confirmationAlertInfo != null) ? this.confirmationAlertInfo.toJson() : undefined
        };
    }
    



}



export class AttachUploadedDocumentInput {

   
    public static fromJsonString(jsonString: string): AttachUploadedDocumentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AttachUploadedDocumentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AttachUploadedDocumentInput {
        const json: any = o ;
        return new AttachUploadedDocumentInput(
         (json.documentRefType != null) ? DOCUMENT_REF_TYPE.fromJson(json.documentRefType) : DOCUMENT_REF_TYPE.DEFAULT_VALUE,
(json.documentRefId != null) ? json.documentRefId : '',
(json.documentType != null) ? json.documentType : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.documentPath != null) ? json.documentPath : ''
        );
    }



  


   
    constructor(
        public documentRefType: DOCUMENT_REF_TYPE = DOCUMENT_REF_TYPE.DEFAULT_VALUE,
public documentRefId: string = '',
public documentType: string = '',
public documentDetails: string|undefined = undefined,
public documentPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentRefType: (this.documentRefType != null) ?  DOCUMENT_REF_TYPE.copyMe(this.documentRefType).toJson() : undefined,
documentRefId: (this.documentRefId != null) ? this.documentRefId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined
        };
    }
    



}



export class GetPresignedURLForUploadInput {

   
    public static fromJsonString(jsonString: string): GetPresignedURLForUploadInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetPresignedURLForUploadInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetPresignedURLForUploadInput {
        const json: any = o ;
        return new GetPresignedURLForUploadInput(
         (json.documentRefType != null) ? DOCUMENT_REF_TYPE.fromJson(json.documentRefType) : DOCUMENT_REF_TYPE.DEFAULT_VALUE,
(json.documentRefId != null) ? json.documentRefId : '',
(json.fileName != null) ? json.fileName : ''
        );
    }



  


   
    constructor(
        public documentRefType: DOCUMENT_REF_TYPE = DOCUMENT_REF_TYPE.DEFAULT_VALUE,
public documentRefId: string = '',
public fileName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentRefType: (this.documentRefType != null) ?  DOCUMENT_REF_TYPE.copyMe(this.documentRefType).toJson() : undefined,
documentRefId: (this.documentRefId != null) ? this.documentRefId : undefined,
fileName: (this.fileName != null) ? this.fileName : undefined
        };
    }
    



}



export class DocumentDetails {

   
    public static fromJsonString(jsonString: string): DocumentDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return DocumentDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DocumentDetails {
        const json: any = o ;
        return new DocumentDetails(
         (json.documentId != null) ? json.documentId : '',
(json.documentType != null) ? json.documentType : undefined,
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined
        );
    }



  


   
    constructor(
        public documentId: string = '',
public documentType: string|undefined = undefined,
public documentPath: string = '',
public documentDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentId: (this.documentId != null) ? this.documentId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined
        };
    }
    



}



export class FiDocument {

   
    public static fromJsonString(jsonString: string): FiDocument {
       const jsonObj: object = JSON.parse(jsonString);
       return FiDocument.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiDocument {
        const json: any = o ;
        return new FiDocument(
         (json.fiDocumentId != null) ? json.fiDocumentId : '',
(json.documentType != null) ? json.documentType : '',
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.uploadedOn != null) ? json.uploadedOn : '',
(json.archived != null) ? json.archived : false
        );
    }



  


   
    constructor(
        public fiDocumentId: string = '',
public documentType: string = '',
public documentPath: string = '',
public documentDetails: string|undefined = undefined,
public uploadedOn: string = '',
public archived: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiDocumentId: (this.fiDocumentId != null) ? this.fiDocumentId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
uploadedOn: (this.uploadedOn != null) ? this.uploadedOn : undefined,
archived: (this.archived != null) ? this.archived : undefined
        };
    }
    



}



export class FileDocument {

   
    public static fromJsonString(jsonString: string): FileDocument {
       const jsonObj: object = JSON.parse(jsonString);
       return FileDocument.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FileDocument {
        const json: any = o ;
        return new FileDocument(
         (json.documentType != null) ? json.documentType : '',
(json.documentPath != null) ? json.documentPath : '',
(json.uploadedOn != null) ? new Date(json.uploadedOn) : new Date(),
(json.documentDescription != null) ? json.documentDescription : '',
(json.fiDocumentId != null) ? json.fiDocumentId : '',
(json.archived != null) ? json.archived : false
        );
    }



  


   
    constructor(
        public documentType: string = '',
public documentPath: string = '',
public uploadedOn: Date = new Date(),
public documentDescription: string = '',
public fiDocumentId: string = '',
public archived: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          documentType: (this.documentType != null) ? this.documentType : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
uploadedOn: (this.uploadedOn != null) ? this.uploadedOn.toISOString() : undefined,
documentDescription: (this.documentDescription != null) ? this.documentDescription : undefined,
fiDocumentId: (this.fiDocumentId != null) ? this.fiDocumentId : undefined,
archived: (this.archived != null) ? this.archived : undefined
        };
    }
    



}



export class AttachDocumentInput {

   
    public static fromJsonString(jsonString: string): AttachDocumentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AttachDocumentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AttachDocumentInput {
        const json: any = o ;
        return new AttachDocumentInput(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.documentType != null) ? json.documentType : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.documentPath != null) ? json.documentPath : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public clientFileNumber: string = '',
public documentType: string = '',
public documentDetails: string|undefined = undefined,
public documentPath: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined
        };
    }
    



}



export class ChangePasswordInput {

   
    public static fromJsonString(jsonString: string): ChangePasswordInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ChangePasswordInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ChangePasswordInput {
        const json: any = o ;
        return new ChangePasswordInput(
         (json.userName != null) ? json.userName : '',
(json.oldPassword != null) ? json.oldPassword : '',
(json.newPassword != null) ? json.newPassword : ''
        );
    }



  


   
    constructor(
        public userName: string = '',
public oldPassword: string = '',
public newPassword: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          userName: (this.userName != null) ? this.userName : undefined,
oldPassword: (this.oldPassword != null) ? this.oldPassword : undefined,
newPassword: (this.newPassword != null) ? this.newPassword : undefined
        };
    }
    



}



export class AssignInput {

   
    public static fromJsonString(jsonString: string): AssignInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AssignInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AssignInput {
        const json: any = o ;
        return new AssignInput(
         (json.taskId != null) ? json.taskId : '',
(json.toUserName != null) ? json.toUserName : '',
(json.whoDidItUserName != null) ? json.whoDidItUserName : undefined
        );
    }



  


   
    constructor(
        public taskId: string = '',
public toUserName: string = '',
public whoDidItUserName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
toUserName: (this.toUserName != null) ? this.toUserName : undefined,
whoDidItUserName: (this.whoDidItUserName != null) ? this.whoDidItUserName : undefined
        };
    }
    



}



export class ReassignInput {

   
    public static fromJsonString(jsonString: string): ReassignInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ReassignInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ReassignInput {
        const json: any = o ;
        return new ReassignInput(
         (json.taskId != null) ? json.taskId : '',
(json.toUserName != null) ? json.toUserName : '',
(json.whoDidItUserName != null) ? json.whoDidItUserName : undefined
        );
    }



  


   
    constructor(
        public taskId: string = '',
public toUserName: string = '',
public whoDidItUserName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
toUserName: (this.toUserName != null) ? this.toUserName : undefined,
whoDidItUserName: (this.whoDidItUserName != null) ? this.whoDidItUserName : undefined
        };
    }
    



}



export class ReceiveFirstMSFPaymentInput {

   
    public static fromJsonString(jsonString: string): ReceiveFirstMSFPaymentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ReceiveFirstMSFPaymentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ReceiveFirstMSFPaymentInput {
        const json: any = o ;
        return new ReceiveFirstMSFPaymentInput(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.msfAmount != null) ? json.msfAmount : 0
        );
    }



  


   
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public msfAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined
        };
    }
    



}



export class UpdateFirstMSFPaymentStatusInput {

   
    public static fromJsonString(jsonString: string): UpdateFirstMSFPaymentStatusInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateFirstMSFPaymentStatusInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateFirstMSFPaymentStatusInput {
        const json: any = o ;
        return new UpdateFirstMSFPaymentStatusInput(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.paymentId != null) ? json.paymentId : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    



}



export class UpdateMsfPaymentStatusInput {

   
    public static fromJsonString(jsonString: string): UpdateMsfPaymentStatusInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateMsfPaymentStatusInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateMsfPaymentStatusInput {
        const json: any = o ;
        return new UpdateMsfPaymentStatusInput(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.paymentId != null) ? json.paymentId : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public paymentId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined
        };
    }
    



}



export class ReceiveMSFPaymentInput {

   
    public static fromJsonString(jsonString: string): ReceiveMSFPaymentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ReceiveMSFPaymentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ReceiveMSFPaymentInput {
        const json: any = o ;
        return new ReceiveMSFPaymentInput(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.msfAmount != null) ? json.msfAmount : 0
        );
    }



  


   
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public msfAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined
        };
    }
    



}



export class Address {

   
    public static fromJsonString(jsonString: string): Address {
       const jsonObj: object = JSON.parse(jsonString);
       return Address.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Address {
        const json: any = o ;
        return new Address(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : 'India',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = 'India',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class UpdateClPersonalInfoInput {

   
    public static fromJsonString(jsonString: string): UpdateClPersonalInfoInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateClPersonalInfoInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateClPersonalInfoInput {
        const json: any = o ;
        return new UpdateClPersonalInfoInput(
         (json.taskId != null) ? json.taskId : undefined,
(json.clientId != null) ? json.clientId : '',
(json.secondaryPhone != null) ? json.secondaryPhone : '',
(json.motherMaidenName != null) ? json.motherMaidenName : '',
(json.fatherName != null) ? json.fatherName : '',
(json.pan != null) ? json.pan : '',
(json.dob != null) ? json.dob : '',
(json.gender != null) ? json.gender : '',
(json.residentialAddress != null) ? Address.fromJson(json.residentialAddress) : new Address(),
(json.clientLanguage != null) ? json.clientLanguage : ''
        );
    }



  


   
    constructor(
        public taskId: string|undefined = undefined,
public clientId: string = '',
public secondaryPhone: string = '',
public motherMaidenName: string = '',
public fatherName: string = '',
public pan: string = '',
public dob: string = '',
public gender: string = '',
public residentialAddress: Address = new Address(),
public clientLanguage: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientId: (this.clientId != null) ? this.clientId : undefined,
secondaryPhone: (this.secondaryPhone != null) ? this.secondaryPhone : undefined,
motherMaidenName: (this.motherMaidenName != null) ? this.motherMaidenName : undefined,
fatherName: (this.fatherName != null) ? this.fatherName : undefined,
pan: (this.pan != null) ? this.pan : undefined,
dob: (this.dob != null) ? this.dob : undefined,
gender: (this.gender != null) ? this.gender : undefined,
residentialAddress: (this.residentialAddress != null) ? this.residentialAddress.toJson() : undefined,
clientLanguage: (this.clientLanguage != null) ? this.clientLanguage : undefined
        };
    }
    



}



export class CreditorFromPdfParse {

   
    public static fromJsonString(jsonString: string): CreditorFromPdfParse {
       const jsonObj: object = JSON.parse(jsonString);
       return CreditorFromPdfParse.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CreditorFromPdfParse {
        const json: any = o ;
        return new CreditorFromPdfParse(
         (json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.ineligible != null) ? json.ineligible : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined
        );
    }



  


   
    constructor(
        public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public ineligible: boolean|undefined = undefined,
public emiAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
ineligible: (this.ineligible != null) ? this.ineligible : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined
        };
    }
    



}



export class AddDetailsFromParsedCreditReportInput {

   
    public static fromJsonString(jsonString: string): AddDetailsFromParsedCreditReportInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AddDetailsFromParsedCreditReportInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddDetailsFromParsedCreditReportInput {
        const json: any = o ;
        return new AddDetailsFromParsedCreditReportInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.creditorList != null) ? (json.creditorList as object[]).map((x) => CreditorFromPdfParse.fromJson(x)) : [],
(json.personalInfo != null) ? CreditReportPersonalInfo.fromJson(json.personalInfo) : new CreditReportPersonalInfo()
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public creditorList: CreditorFromPdfParse[] = [],
public personalInfo: CreditReportPersonalInfo = new CreditReportPersonalInfo()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorList: (this.creditorList != null) ? this.creditorList.map((x) => x.toJson()) : [],
personalInfo: (this.personalInfo != null) ? this.personalInfo.toJson() : undefined
        };
    }
    



}



export class ClCreditorCreditReport {

   
    public static fromJsonString(jsonString: string): ClCreditorCreditReport {
       const jsonObj: object = JSON.parse(jsonString);
       return ClCreditorCreditReport.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClCreditorCreditReport {
        const json: any = o ;
        return new ClCreditorCreditReport(
         (json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : undefined,
(json.daysDelinquentAsOnOnboarding != null) ? json.daysDelinquentAsOnOnboarding : undefined,
(json.daysPastDue != null) ? json.daysPastDue : undefined,
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.settlementStatus != null) ? json.settlementStatus : '',
(json.details != null) ? json.details : undefined,
(json.ineligible != null) ? json.ineligible : undefined,
(json.exceptionTaken != null) ? json.exceptionTaken : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.ineligibleDueToCreditorBalance != null) ? json.ineligibleDueToCreditorBalance : undefined,
(json.ineligibleDueToLastPaymentDate != null) ? json.ineligibleDueToLastPaymentDate : undefined
        );
    }



  


   
    constructor(
        public fiCreditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string|undefined = undefined,
public daysDelinquentAsOnOnboarding: number|undefined = undefined,
public daysPastDue: number|undefined = undefined,
public debtType: string = '',
public accountNumber: string = '',
public settlementStatus: string = '',
public details: string|undefined = undefined,
public ineligible: boolean|undefined = undefined,
public exceptionTaken: boolean|undefined = undefined,
public emiAmount: number|undefined = undefined,
public ineligibleDueToCreditorBalance: number|undefined = undefined,
public ineligibleDueToLastPaymentDate: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
daysDelinquentAsOnOnboarding: (this.daysDelinquentAsOnOnboarding != null) ? this.daysDelinquentAsOnOnboarding : undefined,
daysPastDue: (this.daysPastDue != null) ? this.daysPastDue : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
settlementStatus: (this.settlementStatus != null) ? this.settlementStatus : undefined,
details: (this.details != null) ? this.details : undefined,
ineligible: (this.ineligible != null) ? this.ineligible : undefined,
exceptionTaken: (this.exceptionTaken != null) ? this.exceptionTaken : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
ineligibleDueToCreditorBalance: (this.ineligibleDueToCreditorBalance != null) ? this.ineligibleDueToCreditorBalance : undefined,
ineligibleDueToLastPaymentDate: (this.ineligibleDueToLastPaymentDate != null) ? this.ineligibleDueToLastPaymentDate : undefined
        };
    }
    



}



export class ClCreditorCreditReportInfo {

   
    public static fromJsonString(jsonString: string): ClCreditorCreditReportInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClCreditorCreditReportInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClCreditorCreditReportInfo {
        const json: any = o ;
        return new ClCreditorCreditReportInfo(
         (json.totalDebt != null) ? json.totalDebt : 0,
(json.clCreditorCreditReportList != null) ? (json.clCreditorCreditReportList as object[]).map((x) => ClCreditorCreditReport.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public totalDebt: number = 0,
public clCreditorCreditReportList: ClCreditorCreditReport[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalDebt: (this.totalDebt != null) ? this.totalDebt : undefined,
clCreditorCreditReportList: (this.clCreditorCreditReportList != null) ? this.clCreditorCreditReportList.map((x) => x.toJson()) : []
        };
    }
    



}



export class CreditReportPersonalInfo {

   
    public static fromJsonString(jsonString: string): CreditReportPersonalInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return CreditReportPersonalInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CreditReportPersonalInfo {
        const json: any = o ;
        return new CreditReportPersonalInfo(
         (json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : '',
(json.address != null) ? json.address : '',
(json.pan != null) ? json.pan : '',
(json.gender != null) ? json.gender : '',
(json.mobile != null) ? json.mobile : '',
(json.creditScore != null) ? json.creditScore : '',
(json.bureau != null) ? CREDIT_BUREAU.fromJson(json.bureau) : CREDIT_BUREAU.DEFAULT_VALUE,
(json.fileName != null) ? json.fileName : undefined
        );
    }



  


   
    constructor(
        public firstName: string = '',
public lastName: string = '',
public address: string = '',
public pan: string = '',
public gender: string = '',
public mobile: string = '',
public creditScore: string = '',
public bureau: CREDIT_BUREAU = CREDIT_BUREAU.DEFAULT_VALUE,
public fileName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
address: (this.address != null) ? this.address : undefined,
pan: (this.pan != null) ? this.pan : undefined,
gender: (this.gender != null) ? this.gender : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
bureau: (this.bureau != null) ?  CREDIT_BUREAU.copyMe(this.bureau).toJson() : undefined,
fileName: (this.fileName != null) ? this.fileName : undefined
        };
    }
    



}



export class ParseCreditReportInput {

   
    public static fromJsonString(jsonString: string): ParseCreditReportInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ParseCreditReportInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ParseCreditReportInput {
        const json: any = o ;
        return new ParseCreditReportInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.creditReport != null) ? json.creditReport : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.password != null) ? json.password : undefined,
(json.filename != null) ? json.filename : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public creditReport: File|undefined = undefined,
public creditBureau: string|undefined = undefined,
public password: string|undefined = undefined,
public filename: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditReport: (this.creditReport != null) ? this.creditReport : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
password: (this.password != null) ? this.password : undefined,
filename: (this.filename != null) ? this.filename : undefined
        };
    }
    
    public toFormData(): FormData {
      const fd = new FormData();
      if (this.clientFileId != null) {fd.append('clientFileId', this.clientFileId.toString()); }
if (this.creditReport != null) {fd.append('creditReport', this.creditReport, this.creditReport.name); }
if (this.creditBureau != null) {fd.append('creditBureau', this.creditBureau.toString()); }
if (this.password != null) {fd.append('password', this.password.toString()); }
if (this.filename != null) {fd.append('filename', this.filename.toString()); }
      return fd;
    }
    



}



export class ParseCreditReportOutput {

   
    public static fromJsonString(jsonString: string): ParseCreditReportOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return ParseCreditReportOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ParseCreditReportOutput {
        const json: any = o ;
        return new ParseCreditReportOutput(
         (json.clCreditorCreditReportInfo != null) ? ClCreditorCreditReportInfo.fromJson(json.clCreditorCreditReportInfo) : new ClCreditorCreditReportInfo(),
(json.personalInfo != null) ? CreditReportPersonalInfo.fromJson(json.personalInfo) : new CreditReportPersonalInfo(),
(json.creditorUnparsedDataList != null) ? json.creditorUnparsedDataList : []
        );
    }



  


   
    constructor(
        public clCreditorCreditReportInfo: ClCreditorCreditReportInfo = new ClCreditorCreditReportInfo(),
public personalInfo: CreditReportPersonalInfo = new CreditReportPersonalInfo(),
public creditorUnparsedDataList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorCreditReportInfo: (this.clCreditorCreditReportInfo != null) ? this.clCreditorCreditReportInfo.toJson() : undefined,
personalInfo: (this.personalInfo != null) ? this.personalInfo.toJson() : undefined,
creditorUnparsedDataList: (this.creditorUnparsedDataList != null) ? this.creditorUnparsedDataList : []
        };
    }
    



}



export class CollectClientInfoTask {

   
    public static fromJsonString(jsonString: string): CollectClientInfoTask {
       const jsonObj: object = JSON.parse(jsonString);
       return CollectClientInfoTask.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CollectClientInfoTask {
        const json: any = o ;
        return new CollectClientInfoTask(
         (json.personalInfo != null) ? PersonalInfo.fromJson(json.personalInfo) : new PersonalInfo(),
(json.creditorInfo != null) ? CreditorInfo.fromJson(json.creditorInfo) : new CreditorInfo(),
(json.budgetInfo != null) ? BudgetInfo.fromJson(json.budgetInfo) : new BudgetInfo(),
(json.paymentPlan != null) ? PaymentPlan.fromJson(json.paymentPlan) : new PaymentPlan(),
(json.bankInfo != null) ? BankInfo.fromJson(json.bankInfo) : new BankInfo(),
(json.fileDocumentList != null) ? (json.fileDocumentList as object[]).map((x) => FiDocument.fromJson(x)) : [],
(json.needVerification != null) ? json.needVerification : false,
(json.exceptionTakenList != null) ? json.exceptionTakenList : [],
(json.exceptionApprovedBy != null) ? json.exceptionApprovedBy : '',
(json.collectMSFNow != null) ? json.collectMSFNow : true
        );
    }



  


   
    constructor(
        public personalInfo: PersonalInfo = new PersonalInfo(),
public creditorInfo: CreditorInfo = new CreditorInfo(),
public budgetInfo: BudgetInfo = new BudgetInfo(),
public paymentPlan: PaymentPlan = new PaymentPlan(),
public bankInfo: BankInfo = new BankInfo(),
public fileDocumentList: FiDocument[] = [],
public needVerification: boolean = false,
public exceptionTakenList: string[] = [],
public exceptionApprovedBy: string = '',
public collectMSFNow: boolean = true
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          personalInfo: (this.personalInfo != null) ? this.personalInfo.toJson() : undefined,
creditorInfo: (this.creditorInfo != null) ? this.creditorInfo.toJson() : undefined,
budgetInfo: (this.budgetInfo != null) ? this.budgetInfo.toJson() : undefined,
paymentPlan: (this.paymentPlan != null) ? this.paymentPlan.toJson() : undefined,
bankInfo: (this.bankInfo != null) ? this.bankInfo.toJson() : undefined,
fileDocumentList: (this.fileDocumentList != null) ? this.fileDocumentList.map((x) => x.toJson()) : [],
needVerification: (this.needVerification != null) ? this.needVerification : undefined,
exceptionTakenList: (this.exceptionTakenList != null) ? this.exceptionTakenList : [],
exceptionApprovedBy: (this.exceptionApprovedBy != null) ? this.exceptionApprovedBy : undefined,
collectMSFNow: (this.collectMSFNow != null) ? this.collectMSFNow : undefined
        };
    }
    



}



export class BankInfo {

   
    public static fromJsonString(jsonString: string): BankInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return BankInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BankInfo {
        const json: any = o ;
        return new BankInfo(
         (json.name != null) ? json.name : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.confirmAccountNumber != null) ? json.confirmAccountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? json.accountType : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.bankAddress != null) ? Address.fromJson(json.bankAddress) : new Address(),
(json.nupayBankMasterId != null) ? json.nupayBankMasterId : ''
        );
    }



  


   
    constructor(
        public name: string = '',
public accountNumber: string = '',
public confirmAccountNumber: string = '',
public ifscCode: string = '',
public accountType: string = '',
public accountHolderName: string = '',
public bankAddress: Address = new Address(),
public nupayBankMasterId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          name: (this.name != null) ? this.name : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
confirmAccountNumber: (this.confirmAccountNumber != null) ? this.confirmAccountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ? this.accountType : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined,
nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined
        };
    }
    



}



export class PaymentCalculator {

   
    public static fromJsonString(jsonString: string): PaymentCalculator {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentCalculator.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentCalculator {
        const json: any = o ;
        return new PaymentCalculator(
         (json.ppCode != null) ? json.ppCode : "PM",
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 45,
(json.firstDraftDate != null) ? json.firstDraftDate : '',
(json.feeCode != null) ? json.feeCode : "MSFFee",
(json.feeFirstDraftDate != null) ? json.feeFirstDraftDate : '',
(json.msfDraftDay != null) ? json.msfDraftDay : undefined,
(json.msfDraftAmount != null) ? json.msfDraftAmount : undefined,
(json.spaDraftDay != null) ? json.spaDraftDay : undefined,
(json.spaDraftAmount != null) ? json.spaDraftAmount : undefined,
(json.totalMonthlyObligation != null) ? json.totalMonthlyObligation : undefined,
(json.repaymentAmount != null) ? json.repaymentAmount : undefined,
(json.msfSlabAmount != null) ? json.msfSlabAmount : 0,
(json.msfDiscountAmount != null) ? json.msfDiscountAmount : undefined
        );
    }



  


   
    constructor(
        public ppCode: string = "PM",
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 45,
public firstDraftDate: string = '',
public feeCode: string = "MSFFee",
public feeFirstDraftDate: string = '',
public msfDraftDay: number|undefined = undefined,
public msfDraftAmount: number|undefined = undefined,
public spaDraftDay: number|undefined = undefined,
public spaDraftAmount: number|undefined = undefined,
public totalMonthlyObligation: number|undefined = undefined,
public repaymentAmount: number|undefined = undefined,
public msfSlabAmount: number = 0,
public msfDiscountAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          ppCode: (this.ppCode != null) ? this.ppCode : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
firstDraftDate: (this.firstDraftDate != null) ? this.firstDraftDate : undefined,
feeCode: (this.feeCode != null) ? this.feeCode : undefined,
feeFirstDraftDate: (this.feeFirstDraftDate != null) ? this.feeFirstDraftDate : undefined,
msfDraftDay: (this.msfDraftDay != null) ? this.msfDraftDay : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
spaDraftDay: (this.spaDraftDay != null) ? this.spaDraftDay : undefined,
spaDraftAmount: (this.spaDraftAmount != null) ? this.spaDraftAmount : undefined,
totalMonthlyObligation: (this.totalMonthlyObligation != null) ? this.totalMonthlyObligation : undefined,
repaymentAmount: (this.repaymentAmount != null) ? this.repaymentAmount : undefined,
msfSlabAmount: (this.msfSlabAmount != null) ? this.msfSlabAmount : undefined,
msfDiscountAmount: (this.msfDiscountAmount != null) ? this.msfDiscountAmount : undefined
        };
    }
    



}



export class PaymentPlan {

   
    public static fromJsonString(jsonString: string): PaymentPlan {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentPlan.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentPlan {
        const json: any = o ;
        return new PaymentPlan(
         (json.psPlanId != null) ? json.psPlanId : '',
(json.psPlanStatus != null) ? json.psPlanStatus : '',
(json.ppCalculator != null) ? PaymentCalculator.fromJson(json.ppCalculator) : new PaymentCalculator(),
(json.paymentScheduleList != null) ? json.paymentScheduleList : [],
(json.subscriptionFeeScheduleList != null) ? json.subscriptionFeeScheduleList : [],
(json.psEntryTotalAmount != null) ? json.psEntryTotalAmount : 0,
(json.psEntryTotalFeeAmount != null) ? json.psEntryTotalFeeAmount : 0,
(json.psEntryTotalSpaAmount != null) ? json.psEntryTotalSpaAmount : 0,
(json.psEntryTotalPresentedAmount != null) ? json.psEntryTotalPresentedAmount : 0,
(json.psEntryTotalPaidAmount != null) ? json.psEntryTotalPaidAmount : 0
        );
    }



  


   
    constructor(
        public psPlanId: string = '',
public psPlanStatus: string = '',
public ppCalculator: PaymentCalculator = new PaymentCalculator(),
public paymentScheduleList: object[] = [],
public subscriptionFeeScheduleList: object[] = [],
public psEntryTotalAmount: number = 0,
public psEntryTotalFeeAmount: number = 0,
public psEntryTotalSpaAmount: number = 0,
public psEntryTotalPresentedAmount: number = 0,
public psEntryTotalPaidAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
psPlanStatus: (this.psPlanStatus != null) ? this.psPlanStatus : undefined,
ppCalculator: (this.ppCalculator != null) ? this.ppCalculator.toJson() : undefined,
paymentScheduleList: (this.paymentScheduleList != null) ? this.paymentScheduleList : [],
subscriptionFeeScheduleList: (this.subscriptionFeeScheduleList != null) ? this.subscriptionFeeScheduleList : [],
psEntryTotalAmount: (this.psEntryTotalAmount != null) ? this.psEntryTotalAmount : undefined,
psEntryTotalFeeAmount: (this.psEntryTotalFeeAmount != null) ? this.psEntryTotalFeeAmount : undefined,
psEntryTotalSpaAmount: (this.psEntryTotalSpaAmount != null) ? this.psEntryTotalSpaAmount : undefined,
psEntryTotalPresentedAmount: (this.psEntryTotalPresentedAmount != null) ? this.psEntryTotalPresentedAmount : undefined,
psEntryTotalPaidAmount: (this.psEntryTotalPaidAmount != null) ? this.psEntryTotalPaidAmount : undefined
        };
    }
    



}



export class PersonalInfo {

   
    public static fromJsonString(jsonString: string): PersonalInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return PersonalInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PersonalInfo {
        const json: any = o ;
        return new PersonalInfo(
         (json.secondaryPhone != null) ? json.secondaryPhone : undefined,
(json.motherMaidenName != null) ? json.motherMaidenName : '',
(json.fatherName != null) ? json.fatherName : '',
(json.pan != null) ? json.pan : '',
(json.dob != null) ? json.dob : '',
(json.gender != null) ? json.gender : '',
(json.residentialAddress != null) ? Address.fromJson(json.residentialAddress) : new Address(),
(json.clientLanguage != null) ? json.clientLanguage : ''
        );
    }



  


   
    constructor(
        public secondaryPhone: string|undefined = undefined,
public motherMaidenName: string = '',
public fatherName: string = '',
public pan: string = '',
public dob: string = '',
public gender: string = '',
public residentialAddress: Address = new Address(),
public clientLanguage: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          secondaryPhone: (this.secondaryPhone != null) ? this.secondaryPhone : undefined,
motherMaidenName: (this.motherMaidenName != null) ? this.motherMaidenName : undefined,
fatherName: (this.fatherName != null) ? this.fatherName : undefined,
pan: (this.pan != null) ? this.pan : undefined,
dob: (this.dob != null) ? this.dob : undefined,
gender: (this.gender != null) ? this.gender : undefined,
residentialAddress: (this.residentialAddress != null) ? this.residentialAddress.toJson() : undefined,
clientLanguage: (this.clientLanguage != null) ? this.clientLanguage : undefined
        };
    }
    



}



export class CreditorInfo {

   
    public static fromJsonString(jsonString: string): CreditorInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return CreditorInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CreditorInfo {
        const json: any = o ;
        return new CreditorInfo(
         (json.creditorList != null) ? (json.creditorList as object[]).map((x) => Creditor.fromJson(x)) : [],
(json.totalDebt != null) ? json.totalDebt : 0
        );
    }



  


   
    constructor(
        public creditorList: Creditor[] = [],
public totalDebt: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          creditorList: (this.creditorList != null) ? this.creditorList.map((x) => x.toJson()) : [],
totalDebt: (this.totalDebt != null) ? this.totalDebt : undefined
        };
    }
    



}



export class Creditor {

   
    public static fromJsonString(jsonString: string): Creditor {
       const jsonObj: object = JSON.parse(jsonString);
       return Creditor.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Creditor {
        const json: any = o ;
        return new Creditor(
         (json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.daysDelinquentAsOnOnboarding != null) ? json.daysDelinquentAsOnOnboarding : 0,
(json.daysPastDue != null) ? json.daysPastDue : 0,
(json.details != null) ? json.details : undefined,
(json.settlementStatus != null) ? json.settlementStatus : undefined,
(json.ineligible != null) ? json.ineligible : undefined,
(json.exceptionTaken != null) ? json.exceptionTaken : undefined,
(json.emiAmount != null) ? json.emiAmount : 0,
(json.clCreditorId != null) ? json.clCreditorId : undefined
        );
    }



  


   
    constructor(
        public fiCreditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public daysDelinquentAsOnOnboarding: number = 0,
public daysPastDue: number = 0,
public details: string|undefined = undefined,
public settlementStatus: string|undefined = undefined,
public ineligible: boolean|undefined = undefined,
public exceptionTaken: boolean|undefined = undefined,
public emiAmount: number = 0,
public clCreditorId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
daysDelinquentAsOnOnboarding: (this.daysDelinquentAsOnOnboarding != null) ? this.daysDelinquentAsOnOnboarding : undefined,
daysPastDue: (this.daysPastDue != null) ? this.daysPastDue : undefined,
details: (this.details != null) ? this.details : undefined,
settlementStatus: (this.settlementStatus != null) ? this.settlementStatus : undefined,
ineligible: (this.ineligible != null) ? this.ineligible : undefined,
exceptionTaken: (this.exceptionTaken != null) ? this.exceptionTaken : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined
        };
    }
    



}



export class BudgetIncomeSources {

   
    public static fromJsonString(jsonString: string): BudgetIncomeSources {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetIncomeSources.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetIncomeSources {
        const json: any = o ;
        return new BudgetIncomeSources(
         (json.salary != null) ? json.salary : 0,
(json.businessIncome != null) ? json.businessIncome : 0,
(json.otherIncome != null) ? json.otherIncome : 0,
(json.familySupport != null) ? json.familySupport : 0
        );
    }



  


   
    constructor(
        public salary: number = 0,
public businessIncome: number = 0,
public otherIncome: number = 0,
public familySupport: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          salary: (this.salary != null) ? this.salary : undefined,
businessIncome: (this.businessIncome != null) ? this.businessIncome : undefined,
otherIncome: (this.otherIncome != null) ? this.otherIncome : undefined,
familySupport: (this.familySupport != null) ? this.familySupport : undefined
        };
    }
    



}



export class BudgetDebtRepayments {

   
    public static fromJsonString(jsonString: string): BudgetDebtRepayments {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetDebtRepayments.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetDebtRepayments {
        const json: any = o ;
        return new BudgetDebtRepayments(
         (json.autoLoan != null) ? json.autoLoan : 0,
(json.housingLoan != null) ? json.housingLoan : 0,
(json.loansAgainstSecurity != null) ? json.loansAgainstSecurity : 0,
(json.collateralizedBussinessLoan != null) ? json.collateralizedBussinessLoan : 0
        );
    }



  


   
    constructor(
        public autoLoan: number = 0,
public housingLoan: number = 0,
public loansAgainstSecurity: number = 0,
public collateralizedBussinessLoan: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          autoLoan: (this.autoLoan != null) ? this.autoLoan : undefined,
housingLoan: (this.housingLoan != null) ? this.housingLoan : undefined,
loansAgainstSecurity: (this.loansAgainstSecurity != null) ? this.loansAgainstSecurity : undefined,
collateralizedBussinessLoan: (this.collateralizedBussinessLoan != null) ? this.collateralizedBussinessLoan : undefined
        };
    }
    



}



export class BudgetLivingExpenses {

   
    public static fromJsonString(jsonString: string): BudgetLivingExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetLivingExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetLivingExpenses {
        const json: any = o ;
        return new BudgetLivingExpenses(
         (json.rentMaintenance != null) ? json.rentMaintenance : 0,
(json.groceryExpense != null) ? json.groceryExpense : 0,
(json.electricityBill != null) ? json.electricityBill : 0,
(json.gasBill != null) ? json.gasBill : 0,
(json.phoneBill != null) ? json.phoneBill : 0,
(json.otherUtilities != null) ? json.otherUtilities : 0
        );
    }



  


   
    constructor(
        public rentMaintenance: number = 0,
public groceryExpense: number = 0,
public electricityBill: number = 0,
public gasBill: number = 0,
public phoneBill: number = 0,
public otherUtilities: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          rentMaintenance: (this.rentMaintenance != null) ? this.rentMaintenance : undefined,
groceryExpense: (this.groceryExpense != null) ? this.groceryExpense : undefined,
electricityBill: (this.electricityBill != null) ? this.electricityBill : undefined,
gasBill: (this.gasBill != null) ? this.gasBill : undefined,
phoneBill: (this.phoneBill != null) ? this.phoneBill : undefined,
otherUtilities: (this.otherUtilities != null) ? this.otherUtilities : undefined
        };
    }
    



}



export class BudgetLifeStyleExpenses {

   
    public static fromJsonString(jsonString: string): BudgetLifeStyleExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetLifeStyleExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetLifeStyleExpenses {
        const json: any = o ;
        return new BudgetLifeStyleExpenses(
         (json.travelExpense != null) ? json.travelExpense : 0,
(json.digitalSubscriptionsExpense != null) ? json.digitalSubscriptionsExpense : 0,
(json.diningOutExpense != null) ? json.diningOutExpense : 0,
(json.houseHelpExpense != null) ? json.houseHelpExpense : 0,
(json.outingExpense != null) ? json.outingExpense : 0
        );
    }



  


   
    constructor(
        public travelExpense: number = 0,
public digitalSubscriptionsExpense: number = 0,
public diningOutExpense: number = 0,
public houseHelpExpense: number = 0,
public outingExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          travelExpense: (this.travelExpense != null) ? this.travelExpense : undefined,
digitalSubscriptionsExpense: (this.digitalSubscriptionsExpense != null) ? this.digitalSubscriptionsExpense : undefined,
diningOutExpense: (this.diningOutExpense != null) ? this.diningOutExpense : undefined,
houseHelpExpense: (this.houseHelpExpense != null) ? this.houseHelpExpense : undefined,
outingExpense: (this.outingExpense != null) ? this.outingExpense : undefined
        };
    }
    



}



export class BudgetDependentExpenses {

   
    public static fromJsonString(jsonString: string): BudgetDependentExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetDependentExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetDependentExpenses {
        const json: any = o ;
        return new BudgetDependentExpenses(
         (json.medicalExpense != null) ? json.medicalExpense : 0,
(json.essentialCareExpense != null) ? json.essentialCareExpense : 0,
(json.schoolFeeExpense != null) ? json.schoolFeeExpense : 0,
(json.schoolBusExpense != null) ? json.schoolBusExpense : 0,
(json.booksAndProjectsExpense != null) ? json.booksAndProjectsExpense : 0,
(json.nursingAttendExpense != null) ? json.nursingAttendExpense : 0,
(json.petCareExpense != null) ? json.petCareExpense : 0
        );
    }



  


   
    constructor(
        public medicalExpense: number = 0,
public essentialCareExpense: number = 0,
public schoolFeeExpense: number = 0,
public schoolBusExpense: number = 0,
public booksAndProjectsExpense: number = 0,
public nursingAttendExpense: number = 0,
public petCareExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          medicalExpense: (this.medicalExpense != null) ? this.medicalExpense : undefined,
essentialCareExpense: (this.essentialCareExpense != null) ? this.essentialCareExpense : undefined,
schoolFeeExpense: (this.schoolFeeExpense != null) ? this.schoolFeeExpense : undefined,
schoolBusExpense: (this.schoolBusExpense != null) ? this.schoolBusExpense : undefined,
booksAndProjectsExpense: (this.booksAndProjectsExpense != null) ? this.booksAndProjectsExpense : undefined,
nursingAttendExpense: (this.nursingAttendExpense != null) ? this.nursingAttendExpense : undefined,
petCareExpense: (this.petCareExpense != null) ? this.petCareExpense : undefined
        };
    }
    



}



export class BudgetIncidentalExpenses {

   
    public static fromJsonString(jsonString: string): BudgetIncidentalExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetIncidentalExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetIncidentalExpenses {
        const json: any = o ;
        return new BudgetIncidentalExpenses(
         (json.healthInsuranceExpense != null) ? json.healthInsuranceExpense : 0,
(json.vehicleInsuranceExpense != null) ? json.vehicleInsuranceExpense : 0,
(json.sipExpense != null) ? json.sipExpense : 0,
(json.stockTradesExpense != null) ? json.stockTradesExpense : 0,
(json.otherExpense != null) ? json.otherExpense : 0
        );
    }



  


   
    constructor(
        public healthInsuranceExpense: number = 0,
public vehicleInsuranceExpense: number = 0,
public sipExpense: number = 0,
public stockTradesExpense: number = 0,
public otherExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          healthInsuranceExpense: (this.healthInsuranceExpense != null) ? this.healthInsuranceExpense : undefined,
vehicleInsuranceExpense: (this.vehicleInsuranceExpense != null) ? this.vehicleInsuranceExpense : undefined,
sipExpense: (this.sipExpense != null) ? this.sipExpense : undefined,
stockTradesExpense: (this.stockTradesExpense != null) ? this.stockTradesExpense : undefined,
otherExpense: (this.otherExpense != null) ? this.otherExpense : undefined
        };
    }
    



}



export class BudgetMiscellaneousExpenses {

   
    public static fromJsonString(jsonString: string): BudgetMiscellaneousExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetMiscellaneousExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetMiscellaneousExpenses {
        const json: any = o ;
        return new BudgetMiscellaneousExpenses(
         (json.miscellaneousExpense != null) ? json.miscellaneousExpense : 0
        );
    }



  


   
    constructor(
        public miscellaneousExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          miscellaneousExpense: (this.miscellaneousExpense != null) ? this.miscellaneousExpense : undefined
        };
    }
    



}



export class BudgetInfo {

   
    public static fromJsonString(jsonString: string): BudgetInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetInfo {
        const json: any = o ;
        return new BudgetInfo(
         (json.hardshipReason != null) ? json.hardshipReason : '',
(json.totalIncome != null) ? json.totalIncome : 0,
(json.totalDebtRepayments != null) ? json.totalDebtRepayments : 0,
(json.totalLivingExpenses != null) ? json.totalLivingExpenses : 0,
(json.totalLifeStyleExpenses != null) ? json.totalLifeStyleExpenses : 0,
(json.totalDependentExpenses != null) ? json.totalDependentExpenses : 0,
(json.totalIncidentalExpenses != null) ? json.totalIncidentalExpenses : 0,
(json.totalMiscellaneousExpenses != null) ? json.totalMiscellaneousExpenses : 0,
(json.incomeSources != null) ? BudgetIncomeSources.fromJson(json.incomeSources) : new BudgetIncomeSources(),
(json.debtRepayments != null) ? BudgetDebtRepayments.fromJson(json.debtRepayments) : new BudgetDebtRepayments(),
(json.livingExpenses != null) ? BudgetLivingExpenses.fromJson(json.livingExpenses) : new BudgetLivingExpenses(),
(json.lifeStyleExpenses != null) ? BudgetLifeStyleExpenses.fromJson(json.lifeStyleExpenses) : new BudgetLifeStyleExpenses(),
(json.dependentExpenses != null) ? BudgetDependentExpenses.fromJson(json.dependentExpenses) : new BudgetDependentExpenses(),
(json.incidentalExpenses != null) ? BudgetIncidentalExpenses.fromJson(json.incidentalExpenses) : new BudgetIncidentalExpenses(),
(json.miscellaneousExpenses != null) ? BudgetMiscellaneousExpenses.fromJson(json.miscellaneousExpenses) : new BudgetMiscellaneousExpenses(),
(json.totalMonthlyExpense != null) ? json.totalMonthlyExpense : 0,
(json.availableIncome != null) ? json.availableIncome : 0,
(json.proposedDSPayment != null) ? json.proposedDSPayment : 0,
(json.stdiPercentage != null) ? json.stdiPercentage : 0,
(json.hasDependents != null) ? json.hasDependents : undefined,
(json.hasPets != null) ? json.hasPets : undefined,
(json.maritalStatus != null) ? json.maritalStatus : undefined,
(json.hasKids != null) ? json.hasKids : undefined,
(json.rentedHouse != null) ? json.rentedHouse : undefined,
(json.employmentStatus != null) ? json.employmentStatus : undefined,
(json.employeeCompanyName != null) ? json.employeeCompanyName : undefined,
(json.age != null) ? json.age : undefined,
(json.fullName != null) ? json.fullName : undefined,
(json.gender != null) ? json.gender : undefined
        );
    }



  


   
    constructor(
        public hardshipReason: string = '',
public totalIncome: number = 0,
public totalDebtRepayments: number = 0,
public totalLivingExpenses: number = 0,
public totalLifeStyleExpenses: number = 0,
public totalDependentExpenses: number = 0,
public totalIncidentalExpenses: number = 0,
public totalMiscellaneousExpenses: number = 0,
public incomeSources: BudgetIncomeSources = new BudgetIncomeSources(),
public debtRepayments: BudgetDebtRepayments = new BudgetDebtRepayments(),
public livingExpenses: BudgetLivingExpenses = new BudgetLivingExpenses(),
public lifeStyleExpenses: BudgetLifeStyleExpenses = new BudgetLifeStyleExpenses(),
public dependentExpenses: BudgetDependentExpenses = new BudgetDependentExpenses(),
public incidentalExpenses: BudgetIncidentalExpenses = new BudgetIncidentalExpenses(),
public miscellaneousExpenses: BudgetMiscellaneousExpenses = new BudgetMiscellaneousExpenses(),
public totalMonthlyExpense: number = 0,
public availableIncome: number = 0,
public proposedDSPayment: number = 0,
public stdiPercentage: number = 0,
public hasDependents: boolean|undefined = undefined,
public hasPets: boolean|undefined = undefined,
public maritalStatus: string|undefined = undefined,
public hasKids: boolean|undefined = undefined,
public rentedHouse: string|undefined = undefined,
public employmentStatus: string|undefined = undefined,
public employeeCompanyName: string|undefined = undefined,
public age: number|undefined = undefined,
public fullName: string|undefined = undefined,
public gender: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          hardshipReason: (this.hardshipReason != null) ? this.hardshipReason : undefined,
totalIncome: (this.totalIncome != null) ? this.totalIncome : undefined,
totalDebtRepayments: (this.totalDebtRepayments != null) ? this.totalDebtRepayments : undefined,
totalLivingExpenses: (this.totalLivingExpenses != null) ? this.totalLivingExpenses : undefined,
totalLifeStyleExpenses: (this.totalLifeStyleExpenses != null) ? this.totalLifeStyleExpenses : undefined,
totalDependentExpenses: (this.totalDependentExpenses != null) ? this.totalDependentExpenses : undefined,
totalIncidentalExpenses: (this.totalIncidentalExpenses != null) ? this.totalIncidentalExpenses : undefined,
totalMiscellaneousExpenses: (this.totalMiscellaneousExpenses != null) ? this.totalMiscellaneousExpenses : undefined,
incomeSources: (this.incomeSources != null) ? this.incomeSources.toJson() : undefined,
debtRepayments: (this.debtRepayments != null) ? this.debtRepayments.toJson() : undefined,
livingExpenses: (this.livingExpenses != null) ? this.livingExpenses.toJson() : undefined,
lifeStyleExpenses: (this.lifeStyleExpenses != null) ? this.lifeStyleExpenses.toJson() : undefined,
dependentExpenses: (this.dependentExpenses != null) ? this.dependentExpenses.toJson() : undefined,
incidentalExpenses: (this.incidentalExpenses != null) ? this.incidentalExpenses.toJson() : undefined,
miscellaneousExpenses: (this.miscellaneousExpenses != null) ? this.miscellaneousExpenses.toJson() : undefined,
totalMonthlyExpense: (this.totalMonthlyExpense != null) ? this.totalMonthlyExpense : undefined,
availableIncome: (this.availableIncome != null) ? this.availableIncome : undefined,
proposedDSPayment: (this.proposedDSPayment != null) ? this.proposedDSPayment : undefined,
stdiPercentage: (this.stdiPercentage != null) ? this.stdiPercentage : undefined,
hasDependents: (this.hasDependents != null) ? this.hasDependents : undefined,
hasPets: (this.hasPets != null) ? this.hasPets : undefined,
maritalStatus: (this.maritalStatus != null) ? this.maritalStatus : undefined,
hasKids: (this.hasKids != null) ? this.hasKids : undefined,
rentedHouse: (this.rentedHouse != null) ? this.rentedHouse : undefined,
employmentStatus: (this.employmentStatus != null) ? this.employmentStatus : undefined,
employeeCompanyName: (this.employeeCompanyName != null) ? this.employeeCompanyName : undefined,
age: (this.age != null) ? this.age : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
gender: (this.gender != null) ? this.gender : undefined
        };
    }
    



}



}



export namespace ClientFile {
  

export class SERVICE_STATUS {

     public static get NO_SERVICE() { return new SERVICE_STATUS('NO_SERVICE', 'No Service'); } 
public static get IN_SERVICE() { return new SERVICE_STATUS('IN_SERVICE', 'In Service'); } 

     public static get DEFAULT_VALUE() { return SERVICE_STATUS.NO_SERVICE; }

     public static withIdOption(id: string): SERVICE_STATUS| undefined  {
        try {
            return SERVICE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SERVICE_STATUS.withIdOption(id) !== undefined; }
    public static list(): SERVICE_STATUS[]  { return [SERVICE_STATUS.NO_SERVICE, SERVICE_STATUS.IN_SERVICE]; }
    public static idList(): string[]  { return ['NO_SERVICE', 'IN_SERVICE']; }
    public static nameList(): string[]  { return ['No Service', 'In Service']; }


    public static withId(id: string): SERVICE_STATUS  {
        switch (id) {
            case 'NO_SERVICE': return SERVICE_STATUS.NO_SERVICE; 
case 'IN_SERVICE': return SERVICE_STATUS.IN_SERVICE; 
            default: throw new Error('Invalid id for SERVICE_STATUS: ' + id);
        }
    }

    public static withName(name: string): SERVICE_STATUS  {
        switch (name) {
            case 'No Service': return SERVICE_STATUS.NO_SERVICE; 
case 'In Service': return SERVICE_STATUS.IN_SERVICE; 
            default: throw new Error('Invalid id for SERVICE_STATUS: ' + name);
        }
    }

    public static copyMe(given: SERVICE_STATUS): SERVICE_STATUS  {
            return SERVICE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SERVICE_STATUS { return SERVICE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SERVICE_STATUS.list().indexOf(this); }
}
 

export class EMANDATE_STATUS {

     public static get REJECTED() { return new EMANDATE_STATUS('REJECTED', 'REJECTED'); } 
public static get PENDING() { return new EMANDATE_STATUS('PENDING', 'PENDING'); } 
public static get CANCELLED() { return new EMANDATE_STATUS('CANCELLED', 'CANCELLED'); } 
public static get ACTIVE() { return new EMANDATE_STATUS('ACTIVE', 'ACTIVE'); } 
public static get DRAFTED() { return new EMANDATE_STATUS('DRAFTED', 'DRAFTED'); } 
public static get EXPIRED() { return new EMANDATE_STATUS('EXPIRED', 'EXPIRED'); } 

     public static get DEFAULT_VALUE() { return EMANDATE_STATUS.DRAFTED; }

     public static withIdOption(id: string): EMANDATE_STATUS| undefined  {
        try {
            return EMANDATE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return EMANDATE_STATUS.withIdOption(id) !== undefined; }
    public static list(): EMANDATE_STATUS[]  { return [EMANDATE_STATUS.REJECTED, EMANDATE_STATUS.DRAFTED, EMANDATE_STATUS.PENDING, EMANDATE_STATUS.EXPIRED, EMANDATE_STATUS.ACTIVE, EMANDATE_STATUS.CANCELLED]; }
    public static idList(): string[]  { return ['DRAFTED', 'REJECTED', 'CANCELLED', 'ACTIVE', 'PENDING', 'EXPIRED']; }
    public static nameList(): string[]  { return ['DRAFTED', 'REJECTED', 'CANCELLED', 'ACTIVE', 'PENDING', 'EXPIRED']; }


    public static withId(id: string): EMANDATE_STATUS  {
        switch (id) {
            case 'EXPIRED': return EMANDATE_STATUS.EXPIRED; 
case 'REJECTED': return EMANDATE_STATUS.REJECTED; 
case 'ACTIVE': return EMANDATE_STATUS.ACTIVE; 
case 'CANCELLED': return EMANDATE_STATUS.CANCELLED; 
case 'DRAFTED': return EMANDATE_STATUS.DRAFTED; 
case 'PENDING': return EMANDATE_STATUS.PENDING; 
            default: throw new Error('Invalid id for EMANDATE_STATUS: ' + id);
        }
    }

    public static withName(name: string): EMANDATE_STATUS  {
        switch (name) {
            case 'EXPIRED': return EMANDATE_STATUS.EXPIRED; 
case 'REJECTED': return EMANDATE_STATUS.REJECTED; 
case 'ACTIVE': return EMANDATE_STATUS.ACTIVE; 
case 'CANCELLED': return EMANDATE_STATUS.CANCELLED; 
case 'DRAFTED': return EMANDATE_STATUS.DRAFTED; 
case 'PENDING': return EMANDATE_STATUS.PENDING; 
            default: throw new Error('Invalid id for EMANDATE_STATUS: ' + name);
        }
    }

    public static copyMe(given: EMANDATE_STATUS): EMANDATE_STATUS  {
            return EMANDATE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): EMANDATE_STATUS { return EMANDATE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return EMANDATE_STATUS.list().indexOf(this); }
}
 

export class EMANDATE_PROVIDER {

     public static get NUPAY() { return new EMANDATE_PROVIDER('NUPAY', 'NUPAY'); } 
public static get ESCROWPAY() { return new EMANDATE_PROVIDER('ESCROWPAY', 'ESCROWPAY'); } 

     public static get DEFAULT_VALUE() { return EMANDATE_PROVIDER.NUPAY; }

     public static withIdOption(id: string): EMANDATE_PROVIDER| undefined  {
        try {
            return EMANDATE_PROVIDER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return EMANDATE_PROVIDER.withIdOption(id) !== undefined; }
    public static list(): EMANDATE_PROVIDER[]  { return [EMANDATE_PROVIDER.NUPAY, EMANDATE_PROVIDER.ESCROWPAY]; }
    public static idList(): string[]  { return ['NUPAY', 'ESCROWPAY']; }
    public static nameList(): string[]  { return ['NUPAY', 'ESCROWPAY']; }


    public static withId(id: string): EMANDATE_PROVIDER  {
        switch (id) {
            case 'NUPAY': return EMANDATE_PROVIDER.NUPAY; 
case 'ESCROWPAY': return EMANDATE_PROVIDER.ESCROWPAY; 
            default: throw new Error('Invalid id for EMANDATE_PROVIDER: ' + id);
        }
    }

    public static withName(name: string): EMANDATE_PROVIDER  {
        switch (name) {
            case 'NUPAY': return EMANDATE_PROVIDER.NUPAY; 
case 'ESCROWPAY': return EMANDATE_PROVIDER.ESCROWPAY; 
            default: throw new Error('Invalid id for EMANDATE_PROVIDER: ' + name);
        }
    }

    public static copyMe(given: EMANDATE_PROVIDER): EMANDATE_PROVIDER  {
            return EMANDATE_PROVIDER.withId(given.id)
    }

    public static fromJson(json: string): EMANDATE_PROVIDER { return EMANDATE_PROVIDER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return EMANDATE_PROVIDER.list().indexOf(this); }
}
 

export class PS_ENTRY_STATUS {

     public static get NSF() { return new PS_ENTRY_STATUS('NSF', 'NSF'); } 
public static get NSF_RESCHEDULED() { return new PS_ENTRY_STATUS('NSF_RESCHEDULED', 'NSF_RESCHEDULED'); } 
public static get SKIPPED() { return new PS_ENTRY_STATUS('SKIPPED', 'SKIPPED'); } 
public static get REJECTED() { return new PS_ENTRY_STATUS('REJECTED', 'REJECTED'); } 
public static get ARCHIVED() { return new PS_ENTRY_STATUS('ARCHIVED', 'ARCHIVED'); } 
public static get PRESENTED() { return new PS_ENTRY_STATUS('PRESENTED', 'PRESENTED'); } 
public static get SCHEDULED() { return new PS_ENTRY_STATUS('SCHEDULED', 'SCHEDULED'); } 
public static get PAID() { return new PS_ENTRY_STATUS('PAID', 'PAID'); } 

     public static get DEFAULT_VALUE() { return PS_ENTRY_STATUS.SCHEDULED; }

     public static withIdOption(id: string): PS_ENTRY_STATUS| undefined  {
        try {
            return PS_ENTRY_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PS_ENTRY_STATUS.withIdOption(id) !== undefined; }
    public static list(): PS_ENTRY_STATUS[]  { return [PS_ENTRY_STATUS.NSF_RESCHEDULED, PS_ENTRY_STATUS.REJECTED, PS_ENTRY_STATUS.SCHEDULED, PS_ENTRY_STATUS.SKIPPED, PS_ENTRY_STATUS.PRESENTED, PS_ENTRY_STATUS.NSF, PS_ENTRY_STATUS.PAID, PS_ENTRY_STATUS.ARCHIVED]; }
    public static idList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'REJECTED', 'SKIPPED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }
    public static nameList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'REJECTED', 'SKIPPED', 'ARCHIVED', 'SCHEDULED', 'PRESENTED', 'NSF']; }


    public static withId(id: string): PS_ENTRY_STATUS  {
        switch (id) {
            case 'ARCHIVED': return PS_ENTRY_STATUS.ARCHIVED; 
case 'PAID': return PS_ENTRY_STATUS.PAID; 
case 'REJECTED': return PS_ENTRY_STATUS.REJECTED; 
case 'SKIPPED': return PS_ENTRY_STATUS.SKIPPED; 
case 'SCHEDULED': return PS_ENTRY_STATUS.SCHEDULED; 
case 'PRESENTED': return PS_ENTRY_STATUS.PRESENTED; 
case 'NSF_RESCHEDULED': return PS_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'NSF': return PS_ENTRY_STATUS.NSF; 
            default: throw new Error('Invalid id for PS_ENTRY_STATUS: ' + id);
        }
    }

    public static withName(name: string): PS_ENTRY_STATUS  {
        switch (name) {
            case 'ARCHIVED': return PS_ENTRY_STATUS.ARCHIVED; 
case 'PAID': return PS_ENTRY_STATUS.PAID; 
case 'REJECTED': return PS_ENTRY_STATUS.REJECTED; 
case 'SKIPPED': return PS_ENTRY_STATUS.SKIPPED; 
case 'SCHEDULED': return PS_ENTRY_STATUS.SCHEDULED; 
case 'PRESENTED': return PS_ENTRY_STATUS.PRESENTED; 
case 'NSF_RESCHEDULED': return PS_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'NSF': return PS_ENTRY_STATUS.NSF; 
            default: throw new Error('Invalid id for PS_ENTRY_STATUS: ' + name);
        }
    }

    public static copyMe(given: PS_ENTRY_STATUS): PS_ENTRY_STATUS  {
            return PS_ENTRY_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PS_ENTRY_STATUS { return PS_ENTRY_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PS_ENTRY_STATUS.list().indexOf(this); }
}
 

export class ACCOUNT_TYPE {

     public static get SAVINGS() { return new ACCOUNT_TYPE('SAVINGS', 'Savings'); } 
public static get CURRENT() { return new ACCOUNT_TYPE('CURRENT', 'Current'); } 

     public static get DEFAULT_VALUE() { return ACCOUNT_TYPE.SAVINGS; }

     public static withIdOption(id: string): ACCOUNT_TYPE| undefined  {
        try {
            return ACCOUNT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return ACCOUNT_TYPE.withIdOption(id) !== undefined; }
    public static list(): ACCOUNT_TYPE[]  { return [ACCOUNT_TYPE.SAVINGS, ACCOUNT_TYPE.CURRENT]; }
    public static idList(): string[]  { return ['SAVINGS', 'CURRENT']; }
    public static nameList(): string[]  { return ['Savings', 'Current']; }


    public static withId(id: string): ACCOUNT_TYPE  {
        switch (id) {
            case 'SAVINGS': return ACCOUNT_TYPE.SAVINGS; 
case 'CURRENT': return ACCOUNT_TYPE.CURRENT; 
            default: throw new Error('Invalid id for ACCOUNT_TYPE: ' + id);
        }
    }

    public static withName(name: string): ACCOUNT_TYPE  {
        switch (name) {
            case 'Savings': return ACCOUNT_TYPE.SAVINGS; 
case 'Current': return ACCOUNT_TYPE.CURRENT; 
            default: throw new Error('Invalid id for ACCOUNT_TYPE: ' + name);
        }
    }

    public static copyMe(given: ACCOUNT_TYPE): ACCOUNT_TYPE  {
            return ACCOUNT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): ACCOUNT_TYPE { return ACCOUNT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return ACCOUNT_TYPE.list().indexOf(this); }
}
 

export class CLIENT_FILE_STATUS {

     public static get HOLD() { return new CLIENT_FILE_STATUS('HOLD', 'HOLD'); } 
public static get PENDING_CANCELLED() { return new CLIENT_FILE_STATUS('PENDING_CANCELLED', 'PENDING_CANCELLED'); } 
public static get AGREEMENT_SIGNED() { return new CLIENT_FILE_STATUS('AGREEMENT_SIGNED', 'AGREEMENT_SIGNED'); } 
public static get PARTIALLY_GRADUATED() { return new CLIENT_FILE_STATUS('PARTIALLY_GRADUATED', 'PARTIALLY_GRADUATED'); } 
public static get ENROLLED() { return new CLIENT_FILE_STATUS('ENROLLED', 'ENROLLED'); } 
public static get ACTIVE() { return new CLIENT_FILE_STATUS('ACTIVE', 'ACTIVE'); } 
public static get AGREEMENT_SIGNED_REVOKED() { return new CLIENT_FILE_STATUS('AGREEMENT_SIGNED_REVOKED', 'AGREEMENT_SIGNED_REVOKED'); } 
public static get CANCELLED() { return new CLIENT_FILE_STATUS('CANCELLED', 'CANCELLED'); } 
public static get REJECTED() { return new CLIENT_FILE_STATUS('REJECTED', 'REJECTED'); } 
public static get NO_SERVICE() { return new CLIENT_FILE_STATUS('NO_SERVICE', 'NO_SERVICE'); } 
public static get GRADUATED() { return new CLIENT_FILE_STATUS('GRADUATED', 'GRADUATED'); } 
public static get LEAD() { return new CLIENT_FILE_STATUS('LEAD', 'LEAD'); } 

     public static get DEFAULT_VALUE() { return CLIENT_FILE_STATUS.LEAD; }

     public static withIdOption(id: string): CLIENT_FILE_STATUS| undefined  {
        try {
            return CLIENT_FILE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CLIENT_FILE_STATUS.withIdOption(id) !== undefined; }
    public static list(): CLIENT_FILE_STATUS[]  { return [CLIENT_FILE_STATUS.PARTIALLY_GRADUATED, CLIENT_FILE_STATUS.CANCELLED, CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED, CLIENT_FILE_STATUS.ENROLLED, CLIENT_FILE_STATUS.ACTIVE, CLIENT_FILE_STATUS.REJECTED, CLIENT_FILE_STATUS.NO_SERVICE, CLIENT_FILE_STATUS.PENDING_CANCELLED, CLIENT_FILE_STATUS.GRADUATED, CLIENT_FILE_STATUS.AGREEMENT_SIGNED, CLIENT_FILE_STATUS.LEAD, CLIENT_FILE_STATUS.HOLD]; }
    public static idList(): string[]  { return ['LEAD', 'REJECTED', 'AGREEMENT_SIGNED_REVOKED', 'NO_SERVICE', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'PARTIALLY_GRADUATED', 'ENROLLED']; }
    public static nameList(): string[]  { return ['LEAD', 'REJECTED', 'AGREEMENT_SIGNED_REVOKED', 'NO_SERVICE', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'PARTIALLY_GRADUATED', 'ENROLLED']; }


    public static withId(id: string): CLIENT_FILE_STATUS  {
        switch (id) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return CLIENT_FILE_STATUS.REJECTED; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'ENROLLED': return CLIENT_FILE_STATUS.ENROLLED; 
case 'PARTIALLY_GRADUATED': return CLIENT_FILE_STATUS.PARTIALLY_GRADUATED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'AGREEMENT_SIGNED_REVOKED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'NO_SERVICE': return CLIENT_FILE_STATUS.NO_SERVICE; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + id);
        }
    }

    public static withName(name: string): CLIENT_FILE_STATUS  {
        switch (name) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return CLIENT_FILE_STATUS.REJECTED; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'ENROLLED': return CLIENT_FILE_STATUS.ENROLLED; 
case 'PARTIALLY_GRADUATED': return CLIENT_FILE_STATUS.PARTIALLY_GRADUATED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'AGREEMENT_SIGNED_REVOKED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED_REVOKED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'NO_SERVICE': return CLIENT_FILE_STATUS.NO_SERVICE; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + name);
        }
    }

    public static copyMe(given: CLIENT_FILE_STATUS): CLIENT_FILE_STATUS  {
            return CLIENT_FILE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): CLIENT_FILE_STATUS { return CLIENT_FILE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CLIENT_FILE_STATUS.list().indexOf(this); }
}
 

export class PAYMENT_MODE {

     public static get NET_BANKING() { return new PAYMENT_MODE('NET_BANKING', 'NET_BANKING'); } 
public static get ENACH() { return new PAYMENT_MODE('ENACH', 'ENACH'); } 
public static get TPA() { return new PAYMENT_MODE('TPA', 'TPA'); } 
public static get NEFT() { return new PAYMENT_MODE('NEFT', 'NEFT'); } 
public static get PG() { return new PAYMENT_MODE('PG', 'PG'); } 
public static get RTGS() { return new PAYMENT_MODE('RTGS', 'RTGS'); } 
public static get CREDIT_CARD() { return new PAYMENT_MODE('CREDIT_CARD', 'CREDIT_CARD'); } 
public static get IMPS() { return new PAYMENT_MODE('IMPS', 'IMPS'); } 
public static get UPI_AUTOPAY() { return new PAYMENT_MODE('UPI_AUTOPAY', 'UPI_AUTOPAY'); } 
public static get UPI() { return new PAYMENT_MODE('UPI', 'UPI'); } 
public static get DEBIT_CARD() { return new PAYMENT_MODE('DEBIT_CARD', 'DEBIT_CARD'); } 

     public static get DEFAULT_VALUE() { return PAYMENT_MODE.ENACH; }

     public static withIdOption(id: string): PAYMENT_MODE| undefined  {
        try {
            return PAYMENT_MODE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PAYMENT_MODE.withIdOption(id) !== undefined; }
    public static list(): PAYMENT_MODE[]  { return [PAYMENT_MODE.TPA, PAYMENT_MODE.RTGS, PAYMENT_MODE.CREDIT_CARD, PAYMENT_MODE.DEBIT_CARD, PAYMENT_MODE.UPI_AUTOPAY, PAYMENT_MODE.NET_BANKING, PAYMENT_MODE.ENACH, PAYMENT_MODE.UPI, PAYMENT_MODE.NEFT, PAYMENT_MODE.PG, PAYMENT_MODE.IMPS]; }
    public static idList(): string[]  { return ['RTGS', 'TPA', 'DEBIT_CARD', 'UPI', 'NEFT', 'UPI_AUTOPAY', 'PG', 'IMPS', 'ENACH', 'NET_BANKING', 'CREDIT_CARD']; }
    public static nameList(): string[]  { return ['RTGS', 'TPA', 'DEBIT_CARD', 'UPI', 'NEFT', 'UPI_AUTOPAY', 'PG', 'IMPS', 'ENACH', 'NET_BANKING', 'CREDIT_CARD']; }


    public static withId(id: string): PAYMENT_MODE  {
        switch (id) {
            case 'IMPS': return PAYMENT_MODE.IMPS; 
case 'NEFT': return PAYMENT_MODE.NEFT; 
case 'RTGS': return PAYMENT_MODE.RTGS; 
case 'UPI': return PAYMENT_MODE.UPI; 
case 'ENACH': return PAYMENT_MODE.ENACH; 
case 'NET_BANKING': return PAYMENT_MODE.NET_BANKING; 
case 'UPI_AUTOPAY': return PAYMENT_MODE.UPI_AUTOPAY; 
case 'CREDIT_CARD': return PAYMENT_MODE.CREDIT_CARD; 
case 'TPA': return PAYMENT_MODE.TPA; 
case 'DEBIT_CARD': return PAYMENT_MODE.DEBIT_CARD; 
case 'PG': return PAYMENT_MODE.PG; 
            default: throw new Error('Invalid id for PAYMENT_MODE: ' + id);
        }
    }

    public static withName(name: string): PAYMENT_MODE  {
        switch (name) {
            case 'IMPS': return PAYMENT_MODE.IMPS; 
case 'NEFT': return PAYMENT_MODE.NEFT; 
case 'RTGS': return PAYMENT_MODE.RTGS; 
case 'UPI': return PAYMENT_MODE.UPI; 
case 'ENACH': return PAYMENT_MODE.ENACH; 
case 'NET_BANKING': return PAYMENT_MODE.NET_BANKING; 
case 'UPI_AUTOPAY': return PAYMENT_MODE.UPI_AUTOPAY; 
case 'CREDIT_CARD': return PAYMENT_MODE.CREDIT_CARD; 
case 'TPA': return PAYMENT_MODE.TPA; 
case 'DEBIT_CARD': return PAYMENT_MODE.DEBIT_CARD; 
case 'PG': return PAYMENT_MODE.PG; 
            default: throw new Error('Invalid id for PAYMENT_MODE: ' + name);
        }
    }

    public static copyMe(given: PAYMENT_MODE): PAYMENT_MODE  {
            return PAYMENT_MODE.withId(given.id)
    }

    public static fromJson(json: string): PAYMENT_MODE { return PAYMENT_MODE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PAYMENT_MODE.list().indexOf(this); }
}
 

export class PAYMENT_STATUS {

     public static get FUND_SPLIT_REQUESTED() { return new PAYMENT_STATUS('FUND_SPLIT_REQUESTED', 'FUND_SPLIT_REQUESTED'); } 
public static get FUND_SPLIT_REJECTED() { return new PAYMENT_STATUS('FUND_SPLIT_REJECTED', 'FUND_SPLIT_REJECTED'); } 
public static get RECEIVED() { return new PAYMENT_STATUS('RECEIVED', 'RECEIVED'); } 
public static get SETTLED() { return new PAYMENT_STATUS('SETTLED', 'SETTLED'); } 
public static get REJECTED() { return new PAYMENT_STATUS('REJECTED', 'REJECTED'); } 
public static get DRAFT() { return new PAYMENT_STATUS('DRAFT', 'DRAFT'); } 
public static get NOT_REACHABLE() { return new PAYMENT_STATUS('NOT_REACHABLE', 'NOT_REACHABLE'); } 
public static get FAILED() { return new PAYMENT_STATUS('FAILED', 'FAILED'); } 
public static get CANCELLED() { return new PAYMENT_STATUS('CANCELLED', 'CANCELLED'); } 
public static get PRESENTED() { return new PAYMENT_STATUS('PRESENTED', 'PRESENTED'); } 

     public static get DEFAULT_VALUE() { return PAYMENT_STATUS.DRAFT; }

     public static withIdOption(id: string): PAYMENT_STATUS| undefined  {
        try {
            return PAYMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PAYMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): PAYMENT_STATUS[]  { return [PAYMENT_STATUS.FUND_SPLIT_REJECTED, PAYMENT_STATUS.NOT_REACHABLE, PAYMENT_STATUS.SETTLED, PAYMENT_STATUS.CANCELLED, PAYMENT_STATUS.REJECTED, PAYMENT_STATUS.RECEIVED, PAYMENT_STATUS.FUND_SPLIT_REQUESTED, PAYMENT_STATUS.FAILED, PAYMENT_STATUS.PRESENTED, PAYMENT_STATUS.DRAFT]; }
    public static idList(): string[]  { return ['REJECTED', 'FUND_SPLIT_REQUESTED', 'CANCELLED', 'FAILED', 'FUND_SPLIT_REJECTED', 'SETTLED', 'DRAFT', 'PRESENTED', 'RECEIVED', 'NOT_REACHABLE']; }
    public static nameList(): string[]  { return ['REJECTED', 'FUND_SPLIT_REQUESTED', 'CANCELLED', 'FAILED', 'FUND_SPLIT_REJECTED', 'SETTLED', 'DRAFT', 'PRESENTED', 'RECEIVED', 'NOT_REACHABLE']; }


    public static withId(id: string): PAYMENT_STATUS  {
        switch (id) {
            case 'RECEIVED': return PAYMENT_STATUS.RECEIVED; 
case 'NOT_REACHABLE': return PAYMENT_STATUS.NOT_REACHABLE; 
case 'FAILED': return PAYMENT_STATUS.FAILED; 
case 'REJECTED': return PAYMENT_STATUS.REJECTED; 
case 'CANCELLED': return PAYMENT_STATUS.CANCELLED; 
case 'FUND_SPLIT_REJECTED': return PAYMENT_STATUS.FUND_SPLIT_REJECTED; 
case 'SETTLED': return PAYMENT_STATUS.SETTLED; 
case 'DRAFT': return PAYMENT_STATUS.DRAFT; 
case 'PRESENTED': return PAYMENT_STATUS.PRESENTED; 
case 'FUND_SPLIT_REQUESTED': return PAYMENT_STATUS.FUND_SPLIT_REQUESTED; 
            default: throw new Error('Invalid id for PAYMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): PAYMENT_STATUS  {
        switch (name) {
            case 'RECEIVED': return PAYMENT_STATUS.RECEIVED; 
case 'NOT_REACHABLE': return PAYMENT_STATUS.NOT_REACHABLE; 
case 'FAILED': return PAYMENT_STATUS.FAILED; 
case 'REJECTED': return PAYMENT_STATUS.REJECTED; 
case 'CANCELLED': return PAYMENT_STATUS.CANCELLED; 
case 'FUND_SPLIT_REJECTED': return PAYMENT_STATUS.FUND_SPLIT_REJECTED; 
case 'SETTLED': return PAYMENT_STATUS.SETTLED; 
case 'DRAFT': return PAYMENT_STATUS.DRAFT; 
case 'PRESENTED': return PAYMENT_STATUS.PRESENTED; 
case 'FUND_SPLIT_REQUESTED': return PAYMENT_STATUS.FUND_SPLIT_REQUESTED; 
            default: throw new Error('Invalid id for PAYMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: PAYMENT_STATUS): PAYMENT_STATUS  {
            return PAYMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PAYMENT_STATUS { return PAYMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PAYMENT_STATUS.list().indexOf(this); }
}
 

export class PS_PLAN_STATUS {

     public static get DRAFT() { return new PS_PLAN_STATUS('DRAFT', 'DRAFT'); } 
public static get ACTIVE() { return new PS_PLAN_STATUS('ACTIVE', 'ACTIVE'); } 
public static get HOLD() { return new PS_PLAN_STATUS('HOLD', 'HOLD'); } 
public static get ARCHIVED() { return new PS_PLAN_STATUS('ARCHIVED', 'ARCHIVED'); } 

     public static get DEFAULT_VALUE() { return PS_PLAN_STATUS.DRAFT; }

     public static withIdOption(id: string): PS_PLAN_STATUS| undefined  {
        try {
            return PS_PLAN_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PS_PLAN_STATUS.withIdOption(id) !== undefined; }
    public static list(): PS_PLAN_STATUS[]  { return [PS_PLAN_STATUS.DRAFT, PS_PLAN_STATUS.ACTIVE, PS_PLAN_STATUS.HOLD, PS_PLAN_STATUS.ARCHIVED]; }
    public static idList(): string[]  { return ['DRAFT', 'ACTIVE', 'HOLD', 'ARCHIVED']; }
    public static nameList(): string[]  { return ['DRAFT', 'ACTIVE', 'HOLD', 'ARCHIVED']; }


    public static withId(id: string): PS_PLAN_STATUS  {
        switch (id) {
            case 'DRAFT': return PS_PLAN_STATUS.DRAFT; 
case 'ACTIVE': return PS_PLAN_STATUS.ACTIVE; 
case 'HOLD': return PS_PLAN_STATUS.HOLD; 
case 'ARCHIVED': return PS_PLAN_STATUS.ARCHIVED; 
            default: throw new Error('Invalid id for PS_PLAN_STATUS: ' + id);
        }
    }

    public static withName(name: string): PS_PLAN_STATUS  {
        switch (name) {
            case 'DRAFT': return PS_PLAN_STATUS.DRAFT; 
case 'ACTIVE': return PS_PLAN_STATUS.ACTIVE; 
case 'HOLD': return PS_PLAN_STATUS.HOLD; 
case 'ARCHIVED': return PS_PLAN_STATUS.ARCHIVED; 
            default: throw new Error('Invalid id for PS_PLAN_STATUS: ' + name);
        }
    }

    public static copyMe(given: PS_PLAN_STATUS): PS_PLAN_STATUS  {
            return PS_PLAN_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PS_PLAN_STATUS { return PS_PLAN_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PS_PLAN_STATUS.list().indexOf(this); }
}
 

export class PAYMENT_PROVIDER {

     public static get NUPAY() { return new PAYMENT_PROVIDER('NUPAY', 'NUPAY'); } 
public static get ESCROWPAY() { return new PAYMENT_PROVIDER('ESCROWPAY', 'ESCROWPAY'); } 
public static get CASHFREE() { return new PAYMENT_PROVIDER('CASHFREE', 'CASHFREE'); } 

     public static get DEFAULT_VALUE() { return PAYMENT_PROVIDER.NUPAY; }

     public static withIdOption(id: string): PAYMENT_PROVIDER| undefined  {
        try {
            return PAYMENT_PROVIDER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PAYMENT_PROVIDER.withIdOption(id) !== undefined; }
    public static list(): PAYMENT_PROVIDER[]  { return [PAYMENT_PROVIDER.NUPAY, PAYMENT_PROVIDER.ESCROWPAY, PAYMENT_PROVIDER.CASHFREE]; }
    public static idList(): string[]  { return ['NUPAY', 'ESCROWPAY', 'CASHFREE']; }
    public static nameList(): string[]  { return ['NUPAY', 'ESCROWPAY', 'CASHFREE']; }


    public static withId(id: string): PAYMENT_PROVIDER  {
        switch (id) {
            case 'NUPAY': return PAYMENT_PROVIDER.NUPAY; 
case 'ESCROWPAY': return PAYMENT_PROVIDER.ESCROWPAY; 
case 'CASHFREE': return PAYMENT_PROVIDER.CASHFREE; 
            default: throw new Error('Invalid id for PAYMENT_PROVIDER: ' + id);
        }
    }

    public static withName(name: string): PAYMENT_PROVIDER  {
        switch (name) {
            case 'NUPAY': return PAYMENT_PROVIDER.NUPAY; 
case 'ESCROWPAY': return PAYMENT_PROVIDER.ESCROWPAY; 
case 'CASHFREE': return PAYMENT_PROVIDER.CASHFREE; 
            default: throw new Error('Invalid id for PAYMENT_PROVIDER: ' + name);
        }
    }

    public static copyMe(given: PAYMENT_PROVIDER): PAYMENT_PROVIDER  {
            return PAYMENT_PROVIDER.withId(given.id)
    }

    public static fromJson(json: string): PAYMENT_PROVIDER { return PAYMENT_PROVIDER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PAYMENT_PROVIDER.list().indexOf(this); }
}
 

export class GENDER {

     public static get MALE() { return new GENDER('MALE', 'MALE'); } 
public static get FEMALE() { return new GENDER('FEMALE', 'FEMALE'); } 
public static get OTHER() { return new GENDER('OTHER', 'OTHER'); } 

     public static get DEFAULT_VALUE() { return GENDER.MALE; }

     public static withIdOption(id: string): GENDER| undefined  {
        try {
            return GENDER.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return GENDER.withIdOption(id) !== undefined; }
    public static list(): GENDER[]  { return [GENDER.MALE, GENDER.FEMALE, GENDER.OTHER]; }
    public static idList(): string[]  { return ['MALE', 'FEMALE', 'OTHER']; }
    public static nameList(): string[]  { return ['MALE', 'FEMALE', 'OTHER']; }


    public static withId(id: string): GENDER  {
        switch (id) {
            case 'MALE': return GENDER.MALE; 
case 'FEMALE': return GENDER.FEMALE; 
case 'OTHER': return GENDER.OTHER; 
            default: throw new Error('Invalid id for GENDER: ' + id);
        }
    }

    public static withName(name: string): GENDER  {
        switch (name) {
            case 'MALE': return GENDER.MALE; 
case 'FEMALE': return GENDER.FEMALE; 
case 'OTHER': return GENDER.OTHER; 
            default: throw new Error('Invalid id for GENDER: ' + name);
        }
    }

    public static copyMe(given: GENDER): GENDER  {
            return GENDER.withId(given.id)
    }

    public static fromJson(json: string): GENDER { return GENDER.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return GENDER.list().indexOf(this); }
}
 

export class PAYMENT_TYPE {

     public static get COLLECTION() { return new PAYMENT_TYPE('COLLECTION', 'COLLECTION'); } 
public static get SETTLEMENT() { return new PAYMENT_TYPE('SETTLEMENT', 'SETTLEMENT'); } 
public static get REFUND() { return new PAYMENT_TYPE('REFUND', 'REFUND'); } 

     public static get DEFAULT_VALUE() { return PAYMENT_TYPE.COLLECTION; }

     public static withIdOption(id: string): PAYMENT_TYPE| undefined  {
        try {
            return PAYMENT_TYPE.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PAYMENT_TYPE.withIdOption(id) !== undefined; }
    public static list(): PAYMENT_TYPE[]  { return [PAYMENT_TYPE.COLLECTION, PAYMENT_TYPE.SETTLEMENT, PAYMENT_TYPE.REFUND]; }
    public static idList(): string[]  { return ['COLLECTION', 'SETTLEMENT', 'REFUND']; }
    public static nameList(): string[]  { return ['COLLECTION', 'SETTLEMENT', 'REFUND']; }


    public static withId(id: string): PAYMENT_TYPE  {
        switch (id) {
            case 'COLLECTION': return PAYMENT_TYPE.COLLECTION; 
case 'SETTLEMENT': return PAYMENT_TYPE.SETTLEMENT; 
case 'REFUND': return PAYMENT_TYPE.REFUND; 
            default: throw new Error('Invalid id for PAYMENT_TYPE: ' + id);
        }
    }

    public static withName(name: string): PAYMENT_TYPE  {
        switch (name) {
            case 'COLLECTION': return PAYMENT_TYPE.COLLECTION; 
case 'SETTLEMENT': return PAYMENT_TYPE.SETTLEMENT; 
case 'REFUND': return PAYMENT_TYPE.REFUND; 
            default: throw new Error('Invalid id for PAYMENT_TYPE: ' + name);
        }
    }

    public static copyMe(given: PAYMENT_TYPE): PAYMENT_TYPE  {
            return PAYMENT_TYPE.withId(given.id)
    }

    public static fromJson(json: string): PAYMENT_TYPE { return PAYMENT_TYPE.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PAYMENT_TYPE.list().indexOf(this); }
}
 

export class FICREDITOR_STATUS {

     public static get ARCHIVED() { return new FICREDITOR_STATUS('ARCHIVED', 'ARCHIVED'); } 
public static get ACTIVE() { return new FICREDITOR_STATUS('ACTIVE', 'ACTIVE'); } 
public static get SETTLED() { return new FICREDITOR_STATUS('SETTLED', 'SETTLED'); } 
public static get DRAFT() { return new FICREDITOR_STATUS('DRAFT', 'DRAFT'); } 
public static get PARTIALLY_SETTLED() { return new FICREDITOR_STATUS('PARTIALLY_SETTLED', 'PARTIALLY_SETTLED'); } 
public static get SETTLEMENT_PLANNED() { return new FICREDITOR_STATUS('SETTLEMENT_PLANNED', 'SETTLEMENT_PLANNED'); } 

     public static get DEFAULT_VALUE() { return FICREDITOR_STATUS.DRAFT; }

     public static withIdOption(id: string): FICREDITOR_STATUS| undefined  {
        try {
            return FICREDITOR_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return FICREDITOR_STATUS.withIdOption(id) !== undefined; }
    public static list(): FICREDITOR_STATUS[]  { return [FICREDITOR_STATUS.ACTIVE, FICREDITOR_STATUS.SETTLEMENT_PLANNED, FICREDITOR_STATUS.SETTLED, FICREDITOR_STATUS.ARCHIVED, FICREDITOR_STATUS.DRAFT, FICREDITOR_STATUS.PARTIALLY_SETTLED]; }
    public static idList(): string[]  { return ['SETTLEMENT_PLANNED', 'PARTIALLY_SETTLED', 'ARCHIVED', 'ACTIVE', 'SETTLED', 'DRAFT']; }
    public static nameList(): string[]  { return ['SETTLEMENT_PLANNED', 'PARTIALLY_SETTLED', 'ARCHIVED', 'ACTIVE', 'SETTLED', 'DRAFT']; }


    public static withId(id: string): FICREDITOR_STATUS  {
        switch (id) {
            case 'SETTLEMENT_PLANNED': return FICREDITOR_STATUS.SETTLEMENT_PLANNED; 
case 'SETTLED': return FICREDITOR_STATUS.SETTLED; 
case 'ACTIVE': return FICREDITOR_STATUS.ACTIVE; 
case 'PARTIALLY_SETTLED': return FICREDITOR_STATUS.PARTIALLY_SETTLED; 
case 'ARCHIVED': return FICREDITOR_STATUS.ARCHIVED; 
case 'DRAFT': return FICREDITOR_STATUS.DRAFT; 
            default: throw new Error('Invalid id for FICREDITOR_STATUS: ' + id);
        }
    }

    public static withName(name: string): FICREDITOR_STATUS  {
        switch (name) {
            case 'SETTLEMENT_PLANNED': return FICREDITOR_STATUS.SETTLEMENT_PLANNED; 
case 'SETTLED': return FICREDITOR_STATUS.SETTLED; 
case 'ACTIVE': return FICREDITOR_STATUS.ACTIVE; 
case 'PARTIALLY_SETTLED': return FICREDITOR_STATUS.PARTIALLY_SETTLED; 
case 'ARCHIVED': return FICREDITOR_STATUS.ARCHIVED; 
case 'DRAFT': return FICREDITOR_STATUS.DRAFT; 
            default: throw new Error('Invalid id for FICREDITOR_STATUS: ' + name);
        }
    }

    public static copyMe(given: FICREDITOR_STATUS): FICREDITOR_STATUS  {
            return FICREDITOR_STATUS.withId(given.id)
    }

    public static fromJson(json: string): FICREDITOR_STATUS { return FICREDITOR_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return FICREDITOR_STATUS.list().indexOf(this); }
}
 

export class CreditBureau {

     public static get EXPERIAN() { return new CreditBureau('EXPERIAN', 'EXPERIAN'); } 
public static get CIBIL() { return new CreditBureau('CIBIL', 'CIBIL'); } 
public static get EQUIFAX() { return new CreditBureau('EQUIFAX', 'EQUIFAX'); } 
public static get CRIF() { return new CreditBureau('CRIF', 'CRIF'); } 

     public static get DEFAULT_VALUE() { return CreditBureau.EXPERIAN; }

     public static withIdOption(id: string): CreditBureau| undefined  {
        try {
            return CreditBureau.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CreditBureau.withIdOption(id) !== undefined; }
    public static list(): CreditBureau[]  { return [CreditBureau.EXPERIAN, CreditBureau.CIBIL, CreditBureau.EQUIFAX, CreditBureau.CRIF]; }
    public static idList(): string[]  { return ['EXPERIAN', 'CIBIL', 'EQUIFAX', 'CRIF']; }
    public static nameList(): string[]  { return ['EXPERIAN', 'CIBIL', 'EQUIFAX', 'CRIF']; }


    public static withId(id: string): CreditBureau  {
        switch (id) {
            case 'EXPERIAN': return CreditBureau.EXPERIAN; 
case 'CIBIL': return CreditBureau.CIBIL; 
case 'EQUIFAX': return CreditBureau.EQUIFAX; 
case 'CRIF': return CreditBureau.CRIF; 
            default: throw new Error('Invalid id for CreditBureau: ' + id);
        }
    }

    public static withName(name: string): CreditBureau  {
        switch (name) {
            case 'EXPERIAN': return CreditBureau.EXPERIAN; 
case 'CIBIL': return CreditBureau.CIBIL; 
case 'EQUIFAX': return CreditBureau.EQUIFAX; 
case 'CRIF': return CreditBureau.CRIF; 
            default: throw new Error('Invalid id for CreditBureau: ' + name);
        }
    }

    public static copyMe(given: CreditBureau): CreditBureau  {
            return CreditBureau.withId(given.id)
    }

    public static fromJson(json: string): CreditBureau { return CreditBureau.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CreditBureau.list().indexOf(this); }
}
 

export class PAYMENT_TXN_ACCT {

     public static get CL_TRUST() { return new PAYMENT_TXN_ACCT('CL_TRUST', 'CL_TRUST'); } 
public static get KFS() { return new PAYMENT_TXN_ACCT('KFS', 'KFS'); } 
public static get CREDITOR() { return new PAYMENT_TXN_ACCT('CREDITOR', 'CREDITOR'); } 
public static get CL_PERSONAL() { return new PAYMENT_TXN_ACCT('CL_PERSONAL', 'CL_PERSONAL'); } 
public static get AFFILIATE() { return new PAYMENT_TXN_ACCT('AFFILIATE', 'AFFILIATE'); } 
public static get NUPAY() { return new PAYMENT_TXN_ACCT('NUPAY', 'NUPAY'); } 

     public static get DEFAULT_VALUE() { return PAYMENT_TXN_ACCT.KFS; }

     public static withIdOption(id: string): PAYMENT_TXN_ACCT| undefined  {
        try {
            return PAYMENT_TXN_ACCT.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PAYMENT_TXN_ACCT.withIdOption(id) !== undefined; }
    public static list(): PAYMENT_TXN_ACCT[]  { return [PAYMENT_TXN_ACCT.KFS, PAYMENT_TXN_ACCT.CREDITOR, PAYMENT_TXN_ACCT.AFFILIATE, PAYMENT_TXN_ACCT.NUPAY, PAYMENT_TXN_ACCT.CL_PERSONAL, PAYMENT_TXN_ACCT.CL_TRUST]; }
    public static idList(): string[]  { return ['AFFILIATE', 'NUPAY', 'CREDITOR', 'KFS', 'CL_TRUST', 'CL_PERSONAL']; }
    public static nameList(): string[]  { return ['AFFILIATE', 'NUPAY', 'CREDITOR', 'KFS', 'CL_TRUST', 'CL_PERSONAL']; }


    public static withId(id: string): PAYMENT_TXN_ACCT  {
        switch (id) {
            case 'KFS': return PAYMENT_TXN_ACCT.KFS; 
case 'CL_TRUST': return PAYMENT_TXN_ACCT.CL_TRUST; 
case 'NUPAY': return PAYMENT_TXN_ACCT.NUPAY; 
case 'AFFILIATE': return PAYMENT_TXN_ACCT.AFFILIATE; 
case 'CREDITOR': return PAYMENT_TXN_ACCT.CREDITOR; 
case 'CL_PERSONAL': return PAYMENT_TXN_ACCT.CL_PERSONAL; 
            default: throw new Error('Invalid id for PAYMENT_TXN_ACCT: ' + id);
        }
    }

    public static withName(name: string): PAYMENT_TXN_ACCT  {
        switch (name) {
            case 'KFS': return PAYMENT_TXN_ACCT.KFS; 
case 'CL_TRUST': return PAYMENT_TXN_ACCT.CL_TRUST; 
case 'NUPAY': return PAYMENT_TXN_ACCT.NUPAY; 
case 'AFFILIATE': return PAYMENT_TXN_ACCT.AFFILIATE; 
case 'CREDITOR': return PAYMENT_TXN_ACCT.CREDITOR; 
case 'CL_PERSONAL': return PAYMENT_TXN_ACCT.CL_PERSONAL; 
            default: throw new Error('Invalid id for PAYMENT_TXN_ACCT: ' + name);
        }
    }

    public static copyMe(given: PAYMENT_TXN_ACCT): PAYMENT_TXN_ACCT  {
            return PAYMENT_TXN_ACCT.withId(given.id)
    }

    public static fromJson(json: string): PAYMENT_TXN_ACCT { return PAYMENT_TXN_ACCT.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PAYMENT_TXN_ACCT.list().indexOf(this); }
}
 

export class INTENT {

     public static get SPA() { return new INTENT('SPA', 'SPA'); } 
public static get MSF() { return new INTENT('MSF', 'MSF'); } 
public static get FEE() { return new INTENT('FEE', 'FEE'); } 
public static get MIXED() { return new INTENT('MIXED', 'MIXED'); } 

     public static get DEFAULT_VALUE() { return INTENT.SPA; }

     public static withIdOption(id: string): INTENT| undefined  {
        try {
            return INTENT.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return INTENT.withIdOption(id) !== undefined; }
    public static list(): INTENT[]  { return [INTENT.SPA, INTENT.MSF, INTENT.FEE, INTENT.MIXED]; }
    public static idList(): string[]  { return ['SPA', 'MSF', 'FEE', 'MIXED']; }
    public static nameList(): string[]  { return ['SPA', 'MSF', 'FEE', 'MIXED']; }


    public static withId(id: string): INTENT  {
        switch (id) {
            case 'SPA': return INTENT.SPA; 
case 'MSF': return INTENT.MSF; 
case 'FEE': return INTENT.FEE; 
case 'MIXED': return INTENT.MIXED; 
            default: throw new Error('Invalid id for INTENT: ' + id);
        }
    }

    public static withName(name: string): INTENT  {
        switch (name) {
            case 'SPA': return INTENT.SPA; 
case 'MSF': return INTENT.MSF; 
case 'FEE': return INTENT.FEE; 
case 'MIXED': return INTENT.MIXED; 
            default: throw new Error('Invalid id for INTENT: ' + name);
        }
    }

    public static copyMe(given: INTENT): INTENT  {
            return INTENT.withId(given.id)
    }

    public static fromJson(json: string): INTENT { return INTENT.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return INTENT.list().indexOf(this); }
}
 

export class QRCODE_STATUS {

     public static get CREATED() { return new QRCODE_STATUS('CREATED', 'Created'); } 
public static get EXPIRED() { return new QRCODE_STATUS('EXPIRED', 'Expired'); } 
public static get PAID() { return new QRCODE_STATUS('PAID', 'Paid'); } 
public static get FAILED() { return new QRCODE_STATUS('FAILED', 'Failed'); } 

     public static get DEFAULT_VALUE() { return QRCODE_STATUS.CREATED; }

     public static withIdOption(id: string): QRCODE_STATUS| undefined  {
        try {
            return QRCODE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return QRCODE_STATUS.withIdOption(id) !== undefined; }
    public static list(): QRCODE_STATUS[]  { return [QRCODE_STATUS.CREATED, QRCODE_STATUS.EXPIRED, QRCODE_STATUS.PAID, QRCODE_STATUS.FAILED]; }
    public static idList(): string[]  { return ['CREATED', 'EXPIRED', 'PAID', 'FAILED']; }
    public static nameList(): string[]  { return ['Created', 'Expired', 'Paid', 'Failed']; }


    public static withId(id: string): QRCODE_STATUS  {
        switch (id) {
            case 'CREATED': return QRCODE_STATUS.CREATED; 
case 'EXPIRED': return QRCODE_STATUS.EXPIRED; 
case 'PAID': return QRCODE_STATUS.PAID; 
case 'FAILED': return QRCODE_STATUS.FAILED; 
            default: throw new Error('Invalid id for QRCODE_STATUS: ' + id);
        }
    }

    public static withName(name: string): QRCODE_STATUS  {
        switch (name) {
            case 'Created': return QRCODE_STATUS.CREATED; 
case 'Expired': return QRCODE_STATUS.EXPIRED; 
case 'Paid': return QRCODE_STATUS.PAID; 
case 'Failed': return QRCODE_STATUS.FAILED; 
            default: throw new Error('Invalid id for QRCODE_STATUS: ' + name);
        }
    }

    public static copyMe(given: QRCODE_STATUS): QRCODE_STATUS  {
            return QRCODE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): QRCODE_STATUS { return QRCODE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return QRCODE_STATUS.list().indexOf(this); }
}
 

export class AGREEMENT_STATUS {

     public static get ACITVE() { return new AGREEMENT_STATUS('ACITVE', 'ACITVE'); } 
public static get SIGNED() { return new AGREEMENT_STATUS('SIGNED', 'SIGNED'); } 
public static get CANCELLED() { return new AGREEMENT_STATUS('CANCELLED', 'CANCELLED'); } 

     public static get DEFAULT_VALUE() { return AGREEMENT_STATUS.ACITVE; }

     public static withIdOption(id: string): AGREEMENT_STATUS| undefined  {
        try {
            return AGREEMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return AGREEMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): AGREEMENT_STATUS[]  { return [AGREEMENT_STATUS.ACITVE, AGREEMENT_STATUS.SIGNED, AGREEMENT_STATUS.CANCELLED]; }
    public static idList(): string[]  { return ['ACITVE', 'SIGNED', 'CANCELLED']; }
    public static nameList(): string[]  { return ['ACITVE', 'SIGNED', 'CANCELLED']; }


    public static withId(id: string): AGREEMENT_STATUS  {
        switch (id) {
            case 'ACITVE': return AGREEMENT_STATUS.ACITVE; 
case 'SIGNED': return AGREEMENT_STATUS.SIGNED; 
case 'CANCELLED': return AGREEMENT_STATUS.CANCELLED; 
            default: throw new Error('Invalid id for AGREEMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): AGREEMENT_STATUS  {
        switch (name) {
            case 'ACITVE': return AGREEMENT_STATUS.ACITVE; 
case 'SIGNED': return AGREEMENT_STATUS.SIGNED; 
case 'CANCELLED': return AGREEMENT_STATUS.CANCELLED; 
            default: throw new Error('Invalid id for AGREEMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: AGREEMENT_STATUS): AGREEMENT_STATUS  {
            return AGREEMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): AGREEMENT_STATUS { return AGREEMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return AGREEMENT_STATUS.list().indexOf(this); }
}
 

export class EXCEPTION_TAKEN {

     public static get DELINQUENCY() { return new EXCEPTION_TAKEN('DELINQUENCY', 'DELINQUENCY'); } 
public static get NO() { return new EXCEPTION_TAKEN('NO', 'NO'); } 
public static get HARDSHIP() { return new EXCEPTION_TAKEN('HARDSHIP', 'HARDSHIP'); } 
public static get MSF() { return new EXCEPTION_TAKEN('MSF', 'MSF'); } 
public static get BALLOON_PAYMENT() { return new EXCEPTION_TAKEN('BALLOON_PAYMENT', 'BALLOON_PAYMENT'); } 
public static get DEBT_LOAD() { return new EXCEPTION_TAKEN('DEBT_LOAD', 'DEBT_LOAD'); } 
public static get DRAFT() { return new EXCEPTION_TAKEN('DRAFT', 'DRAFT'); } 
public static get TENURE() { return new EXCEPTION_TAKEN('TENURE', 'TENURE'); } 

     public static get DEFAULT_VALUE() { return EXCEPTION_TAKEN.NO; }

     public static withIdOption(id: string): EXCEPTION_TAKEN| undefined  {
        try {
            return EXCEPTION_TAKEN.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return EXCEPTION_TAKEN.withIdOption(id) !== undefined; }
    public static list(): EXCEPTION_TAKEN[]  { return [EXCEPTION_TAKEN.HARDSHIP, EXCEPTION_TAKEN.NO, EXCEPTION_TAKEN.TENURE, EXCEPTION_TAKEN.DEBT_LOAD, EXCEPTION_TAKEN.MSF, EXCEPTION_TAKEN.DELINQUENCY, EXCEPTION_TAKEN.DRAFT, EXCEPTION_TAKEN.BALLOON_PAYMENT]; }
    public static idList(): string[]  { return ['DEBT_LOAD', 'DELINQUENCY', 'MSF', 'TENURE', 'BALLOON_PAYMENT', 'DRAFT', 'NO', 'HARDSHIP']; }
    public static nameList(): string[]  { return ['DEBT_LOAD', 'DELINQUENCY', 'MSF', 'TENURE', 'BALLOON_PAYMENT', 'DRAFT', 'NO', 'HARDSHIP']; }


    public static withId(id: string): EXCEPTION_TAKEN  {
        switch (id) {
            case 'DRAFT': return EXCEPTION_TAKEN.DRAFT; 
case 'TENURE': return EXCEPTION_TAKEN.TENURE; 
case 'DELINQUENCY': return EXCEPTION_TAKEN.DELINQUENCY; 
case 'HARDSHIP': return EXCEPTION_TAKEN.HARDSHIP; 
case 'MSF': return EXCEPTION_TAKEN.MSF; 
case 'NO': return EXCEPTION_TAKEN.NO; 
case 'BALLOON_PAYMENT': return EXCEPTION_TAKEN.BALLOON_PAYMENT; 
case 'DEBT_LOAD': return EXCEPTION_TAKEN.DEBT_LOAD; 
            default: throw new Error('Invalid id for EXCEPTION_TAKEN: ' + id);
        }
    }

    public static withName(name: string): EXCEPTION_TAKEN  {
        switch (name) {
            case 'DRAFT': return EXCEPTION_TAKEN.DRAFT; 
case 'TENURE': return EXCEPTION_TAKEN.TENURE; 
case 'DELINQUENCY': return EXCEPTION_TAKEN.DELINQUENCY; 
case 'HARDSHIP': return EXCEPTION_TAKEN.HARDSHIP; 
case 'MSF': return EXCEPTION_TAKEN.MSF; 
case 'NO': return EXCEPTION_TAKEN.NO; 
case 'BALLOON_PAYMENT': return EXCEPTION_TAKEN.BALLOON_PAYMENT; 
case 'DEBT_LOAD': return EXCEPTION_TAKEN.DEBT_LOAD; 
            default: throw new Error('Invalid id for EXCEPTION_TAKEN: ' + name);
        }
    }

    public static copyMe(given: EXCEPTION_TAKEN): EXCEPTION_TAKEN  {
            return EXCEPTION_TAKEN.withId(given.id)
    }

    public static fromJson(json: string): EXCEPTION_TAKEN { return EXCEPTION_TAKEN.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return EXCEPTION_TAKEN.list().indexOf(this); }
}
 


  


export class EnrollmentSummary {

   
    public static fromJsonString(jsonString: string): EnrollmentSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return EnrollmentSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): EnrollmentSummary {
        const json: any = o ;
        return new EnrollmentSummary(
         (json.isCreditorDone != null) ? json.isCreditorDone : false,
(json.isBudgetDone != null) ? json.isBudgetDone : false,
(json.isPaymentPlanCreated != null) ? json.isPaymentPlanCreated : false,
(json.isAggrementSigned != null) ? json.isAggrementSigned : false,
(json.initiateNewEMandate != null) ? json.initiateNewEMandate : false,
(json.isEmandateInitiated != null) ? json.isEmandateInitiated : false,
(json.isEmandateDone != null) ? json.isEmandateDone : false,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.exceptionTakenOnHardship != null) ? json.exceptionTakenOnHardship : false
        );
    }



  


   
    constructor(
        public isCreditorDone: boolean = false,
public isBudgetDone: boolean = false,
public isPaymentPlanCreated: boolean = false,
public isAggrementSigned: boolean = false,
public initiateNewEMandate: boolean = false,
public isEmandateInitiated: boolean = false,
public isEmandateDone: boolean = false,
public isFirstMSFPaid: boolean = false,
public exceptionTakenOnHardship: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          isCreditorDone: (this.isCreditorDone != null) ? this.isCreditorDone : undefined,
isBudgetDone: (this.isBudgetDone != null) ? this.isBudgetDone : undefined,
isPaymentPlanCreated: (this.isPaymentPlanCreated != null) ? this.isPaymentPlanCreated : undefined,
isAggrementSigned: (this.isAggrementSigned != null) ? this.isAggrementSigned : undefined,
initiateNewEMandate: (this.initiateNewEMandate != null) ? this.initiateNewEMandate : undefined,
isEmandateInitiated: (this.isEmandateInitiated != null) ? this.isEmandateInitiated : undefined,
isEmandateDone: (this.isEmandateDone != null) ? this.isEmandateDone : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
exceptionTakenOnHardship: (this.exceptionTakenOnHardship != null) ? this.exceptionTakenOnHardship : undefined
        };
    }
    



}



export class ClientFileEnrollmentSummary {

   
    public static fromJsonString(jsonString: string): ClientFileEnrollmentSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileEnrollmentSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileEnrollmentSummary {
        const json: any = o ;
        return new ClientFileEnrollmentSummary(
         (json.clientId != null) ? json.clientId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.enrollmentSummary != null) ? EnrollmentSummary.fromJson(json.enrollmentSummary) : new EnrollmentSummary(),
(json.fiCreditorInfo != null) ? FiCreditorInfo.fromJson(json.fiCreditorInfo) : undefined,
(json.fiBudgetInfo != null) ? BudgetInfo.fromJson(json.fiBudgetInfo) : undefined,
(json.fiPaymentPlan != null) ? FiPaymentPlanInfo.fromJson(json.fiPaymentPlan) : undefined,
(json.ssa != null) ? FiSSASummary.fromJson(json.ssa) : undefined,
(json.eMandate != null) ? FiEMandateSummary.fromJson(json.eMandate) : undefined,
(json.firstMSFCfPayment != null) ? FiPayment.fromJson(json.firstMSFCfPayment) : undefined,
(json.clientBasicInfo != null) ? ClientBasicInfo.fromJson(json.clientBasicInfo) : new ClientBasicInfo(),
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.creditScore != null) ? json.creditScore : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: CLIENT_FILE_STATUS = CLIENT_FILE_STATUS.DEFAULT_VALUE,
public enrollmentSummary: EnrollmentSummary = new EnrollmentSummary(),
public fiCreditorInfo: FiCreditorInfo|undefined = undefined,
public fiBudgetInfo: BudgetInfo|undefined = undefined,
public fiPaymentPlan: FiPaymentPlanInfo|undefined = undefined,
public ssa: FiSSASummary|undefined = undefined,
public eMandate: FiEMandateSummary|undefined = undefined,
public firstMSFCfPayment: FiPayment|undefined = undefined,
public clientBasicInfo: ClientBasicInfo = new ClientBasicInfo(),
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public creditScore: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
enrollmentSummary: (this.enrollmentSummary != null) ? this.enrollmentSummary.toJson() : undefined,
fiCreditorInfo: (this.fiCreditorInfo != null) ? this.fiCreditorInfo.toJson() : undefined,
fiBudgetInfo: (this.fiBudgetInfo != null) ? this.fiBudgetInfo.toJson() : undefined,
fiPaymentPlan: (this.fiPaymentPlan != null) ? this.fiPaymentPlan.toJson() : undefined,
ssa: (this.ssa != null) ? this.ssa.toJson() : undefined,
eMandate: (this.eMandate != null) ? this.eMandate.toJson() : undefined,
firstMSFCfPayment: (this.firstMSFCfPayment != null) ? this.firstMSFCfPayment.toJson() : undefined,
clientBasicInfo: (this.clientBasicInfo != null) ? this.clientBasicInfo.toJson() : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined
        };
    }
    



}



export class ClientFileSummary {

   
    public static fromJsonString(jsonString: string): ClientFileSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileSummary {
        const json: any = o ;
        return new ClientFileSummary(
         (json.clientFileBasicInfo != null) ? ClientFileBasicInfo.fromJson(json.clientFileBasicInfo) : new ClientFileBasicInfo(),
(json.personalInfo != null) ? ClPersonalInfo.fromJson(json.personalInfo) : undefined,
(json.fiBankInfo != null) ? FiBankInfo.fromJson(json.fiBankInfo) : undefined,
(json.fiCreditorInfo != null) ? FiCreditorInfo.fromJson(json.fiCreditorInfo) : undefined,
(json.fiDocumentList != null) ? (json.fiDocumentList as object[]).map((x) => FiDocument.fromJson(x)) : [],
(json.fiERPDocumentList != null) ? (json.fiERPDocumentList as object[]).map((x) => FiDocument.fromJson(x)) : [],
(json.fiPaymentPlanInfo != null) ? FiPaymentPlanInfo.fromJson(json.fiPaymentPlanInfo) : undefined,
(json.budgetInfo != null) ? BudgetInfo.fromJson(json.budgetInfo) : undefined,
(json.fiPaymentList != null) ? (json.fiPaymentList as object[]).map((x) => FiPayment.fromJson(x)) : [],
(json.fiEMandateList != null) ? (json.fiEMandateList as object[]).map((x) => FiEMandateList.fromJson(x)) : [],
(json.fiEMandateSummaryList != null) ? (json.fiEMandateSummaryList as object[]).map((x) => FiEMandateSummary.fromJson(x)) : [],
(json.clCreditorList != null) ? (json.clCreditorList as object[]).map((x) => ClCreditor.fromJson(x)) : [],
(json.fiPaymentTransactionList != null) ? (json.fiPaymentTransactionList as object[]).map((x) => FiPaymentTransaction.fromJson(x)) : [],
(json.fiSignAgreementList != null) ? (json.fiSignAgreementList as object[]).map((x) => FiSSASummary.fromJson(x)) : [],
(json.fileSummary != null) ? FileSummary.fromJson(json.fileSummary) : new FileSummary(),
(json.fiClientFileEnrollmentSummary != null) ? ClientFileEnrollmentSummary.fromJson(json.fiClientFileEnrollmentSummary) : new ClientFileEnrollmentSummary(),
(json.msfCashfreePaymentList != null) ? (json.msfCashfreePaymentList as object[]).map((x) => FiPayment.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientFileBasicInfo: ClientFileBasicInfo = new ClientFileBasicInfo(),
public personalInfo: ClPersonalInfo|undefined = undefined,
public fiBankInfo: FiBankInfo|undefined = undefined,
public fiCreditorInfo: FiCreditorInfo|undefined = undefined,
public fiDocumentList: FiDocument[] = [],
public fiERPDocumentList: FiDocument[] = [],
public fiPaymentPlanInfo: FiPaymentPlanInfo|undefined = undefined,
public budgetInfo: BudgetInfo|undefined = undefined,
public fiPaymentList: FiPayment[] = [],
public fiEMandateList: FiEMandateList[] = [],
public fiEMandateSummaryList: FiEMandateSummary[] = [],
public clCreditorList: ClCreditor[] = [],
public fiPaymentTransactionList: FiPaymentTransaction[] = [],
public fiSignAgreementList: FiSSASummary[] = [],
public fileSummary: FileSummary = new FileSummary(),
public fiClientFileEnrollmentSummary: ClientFileEnrollmentSummary = new ClientFileEnrollmentSummary(),
public msfCashfreePaymentList: FiPayment[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileBasicInfo: (this.clientFileBasicInfo != null) ? this.clientFileBasicInfo.toJson() : undefined,
personalInfo: (this.personalInfo != null) ? this.personalInfo.toJson() : undefined,
fiBankInfo: (this.fiBankInfo != null) ? this.fiBankInfo.toJson() : undefined,
fiCreditorInfo: (this.fiCreditorInfo != null) ? this.fiCreditorInfo.toJson() : undefined,
fiDocumentList: (this.fiDocumentList != null) ? this.fiDocumentList.map((x) => x.toJson()) : [],
fiERPDocumentList: (this.fiERPDocumentList != null) ? this.fiERPDocumentList.map((x) => x.toJson()) : [],
fiPaymentPlanInfo: (this.fiPaymentPlanInfo != null) ? this.fiPaymentPlanInfo.toJson() : undefined,
budgetInfo: (this.budgetInfo != null) ? this.budgetInfo.toJson() : undefined,
fiPaymentList: (this.fiPaymentList != null) ? this.fiPaymentList.map((x) => x.toJson()) : [],
fiEMandateList: (this.fiEMandateList != null) ? this.fiEMandateList.map((x) => x.toJson()) : [],
fiEMandateSummaryList: (this.fiEMandateSummaryList != null) ? this.fiEMandateSummaryList.map((x) => x.toJson()) : [],
clCreditorList: (this.clCreditorList != null) ? this.clCreditorList.map((x) => x.toJson()) : [],
fiPaymentTransactionList: (this.fiPaymentTransactionList != null) ? this.fiPaymentTransactionList.map((x) => x.toJson()) : [],
fiSignAgreementList: (this.fiSignAgreementList != null) ? this.fiSignAgreementList.map((x) => x.toJson()) : [],
fileSummary: (this.fileSummary != null) ? this.fileSummary.toJson() : undefined,
fiClientFileEnrollmentSummary: (this.fiClientFileEnrollmentSummary != null) ? this.fiClientFileEnrollmentSummary.toJson() : undefined,
msfCashfreePaymentList: (this.msfCashfreePaymentList != null) ? this.msfCashfreePaymentList.map((x) => x.toJson()) : []
        };
    }
    



}



export class ClientFileDashboardSummary {

   
    public static fromJsonString(jsonString: string): ClientFileDashboardSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileDashboardSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileDashboardSummary {
        const json: any = o ;
        return new ClientFileDashboardSummary(
         (json.totalCount != null) ? json.totalCount : 0,
(json.eMandateActiveCount != null) ? json.eMandateActiveCount : 0,
(json.eMandatePendingCount != null) ? json.eMandatePendingCount : 0,
(json.msfPaidCount != null) ? json.msfPaidCount : 0,
(json.msfPendingCount != null) ? json.msfPendingCount : 0,
(json.activeCFCount != null) ? json.activeCFCount : 0,
(json.agreementCFCount != null) ? json.agreementCFCount : 0,
(json.leadCFCount != null) ? json.leadCFCount : 0,
(json.holdCFCount != null) ? json.holdCFCount : 0,
(json.msfEMandateEligibleCFCount != null) ? json.msfEMandateEligibleCFCount : 0
        );
    }



  


   
    constructor(
        public totalCount: number = 0,
public eMandateActiveCount: number = 0,
public eMandatePendingCount: number = 0,
public msfPaidCount: number = 0,
public msfPendingCount: number = 0,
public activeCFCount: number = 0,
public agreementCFCount: number = 0,
public leadCFCount: number = 0,
public holdCFCount: number = 0,
public msfEMandateEligibleCFCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalCount: (this.totalCount != null) ? this.totalCount : undefined,
eMandateActiveCount: (this.eMandateActiveCount != null) ? this.eMandateActiveCount : undefined,
eMandatePendingCount: (this.eMandatePendingCount != null) ? this.eMandatePendingCount : undefined,
msfPaidCount: (this.msfPaidCount != null) ? this.msfPaidCount : undefined,
msfPendingCount: (this.msfPendingCount != null) ? this.msfPendingCount : undefined,
activeCFCount: (this.activeCFCount != null) ? this.activeCFCount : undefined,
agreementCFCount: (this.agreementCFCount != null) ? this.agreementCFCount : undefined,
leadCFCount: (this.leadCFCount != null) ? this.leadCFCount : undefined,
holdCFCount: (this.holdCFCount != null) ? this.holdCFCount : undefined,
msfEMandateEligibleCFCount: (this.msfEMandateEligibleCFCount != null) ? this.msfEMandateEligibleCFCount : undefined
        };
    }
    



}



export class MyClientFile {

   
    public static fromJsonString(jsonString: string): MyClientFile {
       const jsonObj: object = JSON.parse(jsonString);
       return MyClientFile.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyClientFile {
        const json: any = o ;
        return new MyClientFile(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.emailId != null) ? json.emailId : '',
(json.dateCreated != null) ? json.dateCreated : '',
(json.mobile != null) ? json.mobile : '',
(json.city != null) ? json.city : undefined,
(json.state != null) ? json.state : undefined,
(json.clientFileStatus != null) ? CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.eMandateActive != null) ? json.eMandateActive : false,
(json.firstMSFPaid != null) ? json.firstMSFPaid : false
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientId: string = '',
public fullName: string = '',
public emailId: string = '',
public dateCreated: string = '',
public mobile: string = '',
public city: string|undefined = undefined,
public state: string|undefined = undefined,
public clientFileStatus: CLIENT_FILE_STATUS = CLIENT_FILE_STATUS.DEFAULT_VALUE,
public eMandateActive: boolean = false,
public firstMSFPaid: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
dateCreated: (this.dateCreated != null) ? this.dateCreated : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
eMandateActive: (this.eMandateActive != null) ? this.eMandateActive : undefined,
firstMSFPaid: (this.firstMSFPaid != null) ? this.firstMSFPaid : undefined
        };
    }
    



}



export class FileSummary {

   
    public static fromJsonString(jsonString: string): FileSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return FileSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FileSummary {
        const json: any = o ;
        return new FileSummary(
         (json.msfAmount != null) ? json.msfAmount : 0,
(json.msfDueDate != null) ? json.msfDueDate : undefined,
(json.totalMonthlyObligation != null) ? json.totalMonthlyObligation : 0,
(json.spaDueDate != null) ? json.spaDueDate : undefined,
(json.totalSaving != null) ? json.totalSaving : 0,
(json.lastPaidDate != null) ? new Date(json.lastPaidDate) : undefined,
(json.totalOutstanding != null) ? json.totalOutstanding : 0,
(json.creditorsCount != null) ? json.creditorsCount : 0,
(json.settledCreditorsCount != null) ? json.settledCreditorsCount : 0,
(json.wad != null) ? json.wad : 0,
(json.creditScore != null) ? json.creditScore : 0,
(json.creditBureau != null) ? json.creditBureau : '',
(json.clientFileStatus != null) ? CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.eMandateActive != null) ? json.eMandateActive : false,
(json.ppCode != null) ? json.ppCode : '',
(json.msfSlabAmount != null) ? json.msfSlabAmount : 0,
(json.msfDiscountAmount != null) ? json.msfDiscountAmount : undefined,
(json.msfDraftAmount != null) ? json.msfDraftAmount : 0,
(json.addedBy != null) ? json.addedBy : '',
(json.dateCreated != null) ? new Date(json.dateCreated) : new Date(),
(json.serviceStatus != null) ? SERVICE_STATUS.fromJson(json.serviceStatus) : SERVICE_STATUS.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public msfAmount: number = 0,
public msfDueDate: number|undefined = undefined,
public totalMonthlyObligation: number = 0,
public spaDueDate: number|undefined = undefined,
public totalSaving: number = 0,
public lastPaidDate: Date|undefined = undefined,
public totalOutstanding: number = 0,
public creditorsCount: number = 0,
public settledCreditorsCount: number = 0,
public wad: number = 0,
public creditScore: number = 0,
public creditBureau: string = '',
public clientFileStatus: CLIENT_FILE_STATUS = CLIENT_FILE_STATUS.DEFAULT_VALUE,
public isFirstMSFPaid: boolean = false,
public eMandateActive: boolean = false,
public ppCode: string = '',
public msfSlabAmount: number = 0,
public msfDiscountAmount: number|undefined = undefined,
public msfDraftAmount: number = 0,
public addedBy: string = '',
public dateCreated: Date = new Date(),
public serviceStatus: SERVICE_STATUS = SERVICE_STATUS.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined,
msfDueDate: (this.msfDueDate != null) ? this.msfDueDate : undefined,
totalMonthlyObligation: (this.totalMonthlyObligation != null) ? this.totalMonthlyObligation : undefined,
spaDueDate: (this.spaDueDate != null) ? this.spaDueDate : undefined,
totalSaving: (this.totalSaving != null) ? this.totalSaving : undefined,
lastPaidDate: (this.lastPaidDate != null) ? this.lastPaidDate.toISOString() : undefined,
totalOutstanding: (this.totalOutstanding != null) ? this.totalOutstanding : undefined,
creditorsCount: (this.creditorsCount != null) ? this.creditorsCount : undefined,
settledCreditorsCount: (this.settledCreditorsCount != null) ? this.settledCreditorsCount : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
eMandateActive: (this.eMandateActive != null) ? this.eMandateActive : undefined,
ppCode: (this.ppCode != null) ? this.ppCode : undefined,
msfSlabAmount: (this.msfSlabAmount != null) ? this.msfSlabAmount : undefined,
msfDiscountAmount: (this.msfDiscountAmount != null) ? this.msfDiscountAmount : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
addedBy: (this.addedBy != null) ? this.addedBy : undefined,
dateCreated: (this.dateCreated != null) ? this.dateCreated.toISOString() : undefined,
serviceStatus: (this.serviceStatus != null) ?  SERVICE_STATUS.copyMe(this.serviceStatus).toJson() : undefined
        };
    }
    



}



export class MyClientFileStore {

   
    public static fromJsonString(jsonString: string): MyClientFileStore {
       const jsonObj: object = JSON.parse(jsonString);
       return MyClientFileStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyClientFileStore {
        const json: any = o ;
        return new MyClientFileStore(
         (json.clientFileDashboardSummary != null) ? ClientFileDashboardSummary.fromJson(json.clientFileDashboardSummary) : new ClientFileDashboardSummary(),
(json.myClientFileList != null) ? (json.myClientFileList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myEMandatePendingCFList != null) ? (json.myEMandatePendingCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myEMandateActiveCFList != null) ? (json.myEMandateActiveCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myMSFPendingCFList != null) ? (json.myMSFPendingCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myMSFPaidCFList != null) ? (json.myMSFPaidCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myActiveCFList != null) ? (json.myActiveCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myAgreementCFList != null) ? (json.myAgreementCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myLeadCFList != null) ? (json.myLeadCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.myHoldCFList != null) ? (json.myHoldCFList as object[]).map((x) => MyClientFile.fromJson(x)) : [],
(json.msfEMandateEligibleCFList != null) ? (json.msfEMandateEligibleCFList as object[]).map((x) => MyClientFile.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientFileDashboardSummary: ClientFileDashboardSummary = new ClientFileDashboardSummary(),
public myClientFileList: MyClientFile[] = [],
public myEMandatePendingCFList: MyClientFile[] = [],
public myEMandateActiveCFList: MyClientFile[] = [],
public myMSFPendingCFList: MyClientFile[] = [],
public myMSFPaidCFList: MyClientFile[] = [],
public myActiveCFList: MyClientFile[] = [],
public myAgreementCFList: MyClientFile[] = [],
public myLeadCFList: MyClientFile[] = [],
public myHoldCFList: MyClientFile[] = [],
public msfEMandateEligibleCFList: MyClientFile[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileDashboardSummary: (this.clientFileDashboardSummary != null) ? this.clientFileDashboardSummary.toJson() : undefined,
myClientFileList: (this.myClientFileList != null) ? this.myClientFileList.map((x) => x.toJson()) : [],
myEMandatePendingCFList: (this.myEMandatePendingCFList != null) ? this.myEMandatePendingCFList.map((x) => x.toJson()) : [],
myEMandateActiveCFList: (this.myEMandateActiveCFList != null) ? this.myEMandateActiveCFList.map((x) => x.toJson()) : [],
myMSFPendingCFList: (this.myMSFPendingCFList != null) ? this.myMSFPendingCFList.map((x) => x.toJson()) : [],
myMSFPaidCFList: (this.myMSFPaidCFList != null) ? this.myMSFPaidCFList.map((x) => x.toJson()) : [],
myActiveCFList: (this.myActiveCFList != null) ? this.myActiveCFList.map((x) => x.toJson()) : [],
myAgreementCFList: (this.myAgreementCFList != null) ? this.myAgreementCFList.map((x) => x.toJson()) : [],
myLeadCFList: (this.myLeadCFList != null) ? this.myLeadCFList.map((x) => x.toJson()) : [],
myHoldCFList: (this.myHoldCFList != null) ? this.myHoldCFList.map((x) => x.toJson()) : [],
msfEMandateEligibleCFList: (this.msfEMandateEligibleCFList != null) ? this.msfEMandateEligibleCFList.map((x) => x.toJson()) : []
        };
    }
    



}



export class NupayBankMaster {

   
    public static fromJsonString(jsonString: string): NupayBankMaster {
       const jsonObj: object = JSON.parse(jsonString);
       return NupayBankMaster.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): NupayBankMaster {
        const json: any = o ;
        return new NupayBankMaster(
         (json.nupayBankMasterId != null) ? json.nupayBankMasterId : '',
(json.nupayBankId != null) ? json.nupayBankId : '',
(json.nupayBnkName != null) ? json.nupayBnkName : ''
        );
    }



  


   
    constructor(
        public nupayBankMasterId: string = '',
public nupayBankId: string = '',
public nupayBnkName: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined,
nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
nupayBnkName: (this.nupayBnkName != null) ? this.nupayBnkName : undefined
        };
    }
    



}



export class NupayBankMasterListOutput {

   
    public static fromJsonString(jsonString: string): NupayBankMasterListOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return NupayBankMasterListOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): NupayBankMasterListOutput {
        const json: any = o ;
        return new NupayBankMasterListOutput(
         (json.nupayBankMasterList != null) ? (json.nupayBankMasterList as object[]).map((x) => NupayBankMaster.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public nupayBankMasterList: NupayBankMaster[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankMasterList: (this.nupayBankMasterList != null) ? this.nupayBankMasterList.map((x) => x.toJson()) : []
        };
    }
    



}



export class FiBankAddress {

   
    public static fromJsonString(jsonString: string): FiBankAddress {
       const jsonObj: object = JSON.parse(jsonString);
       return FiBankAddress.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiBankAddress {
        const json: any = o ;
        return new FiBankAddress(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : '',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = '',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class FiEMandateList {

   
    public static fromJsonString(jsonString: string): FiEMandateList {
       const jsonObj: object = JSON.parse(jsonString);
       return FiEMandateList.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiEMandateList {
        const json: any = o ;
        return new FiEMandateList(
         (json.eMandateId != null) ? json.eMandateId : '',
(json.umrn != null) ? json.umrn : undefined,
(json.remoteEMandateId != null) ? json.remoteEMandateId : undefined,
(json.eMandateStatus != null) ? EMANDATE_STATUS.fromJson(json.eMandateStatus) : EMANDATE_STATUS.DEFAULT_VALUE,
(json.provider != null) ? EMANDATE_PROVIDER.fromJson(json.provider) : EMANDATE_PROVIDER.DEFAULT_VALUE,
(json.nupayBankMasterId != null) ? json.nupayBankMasterId : '',
(json.nupayBankId != null) ? json.nupayBankId : 0,
(json.nupayBankName != null) ? json.nupayBankName : '',
(json.nupayBankCode != null) ? json.nupayBankCode : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? ACCOUNT_TYPE.fromJson(json.accountType) : ACCOUNT_TYPE.DEFAULT_VALUE,
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.bankAddress != null) ? FiBankAddress.fromJson(json.bankAddress) : undefined,
(json.amount != null) ? json.amount : 0,
(json.isDefault != null) ? json.isDefault : false,
(json.createdOn != null) ? json.createdOn : '',
(json.activatedOn != null) ? json.activatedOn : undefined
        );
    }



  


   
    constructor(
        public eMandateId: string = '',
public umrn: string|undefined = undefined,
public remoteEMandateId: string|undefined = undefined,
public eMandateStatus: EMANDATE_STATUS = EMANDATE_STATUS.DEFAULT_VALUE,
public provider: EMANDATE_PROVIDER = EMANDATE_PROVIDER.DEFAULT_VALUE,
public nupayBankMasterId: string = '',
public nupayBankId: number = 0,
public nupayBankName: string = '',
public nupayBankCode: string = '',
public accountNumber: string = '',
public ifscCode: string = '',
public accountType: ACCOUNT_TYPE = ACCOUNT_TYPE.DEFAULT_VALUE,
public accountHolderName: string = '',
public bankAddress: FiBankAddress|undefined = undefined,
public amount: number = 0,
public isDefault: boolean = false,
public createdOn: string = '',
public activatedOn: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
umrn: (this.umrn != null) ? this.umrn : undefined,
remoteEMandateId: (this.remoteEMandateId != null) ? this.remoteEMandateId : undefined,
eMandateStatus: (this.eMandateStatus != null) ?  EMANDATE_STATUS.copyMe(this.eMandateStatus).toJson() : undefined,
provider: (this.provider != null) ?  EMANDATE_PROVIDER.copyMe(this.provider).toJson() : undefined,
nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined,
nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
nupayBankName: (this.nupayBankName != null) ? this.nupayBankName : undefined,
nupayBankCode: (this.nupayBankCode != null) ? this.nupayBankCode : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ?  ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined,
amount: (this.amount != null) ? this.amount : undefined,
isDefault: (this.isDefault != null) ? this.isDefault : undefined,
createdOn: (this.createdOn != null) ? this.createdOn : undefined,
activatedOn: (this.activatedOn != null) ? this.activatedOn : undefined
        };
    }
    



}



export class ClientBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientBasicInfo {
        const json: any = o ;
        return new ClientBasicInfo(
         (json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.pan != null) ? json.pan : undefined,
(json.city != null) ? json.city : undefined,
(json.state != null) ? json.state : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public fullName: string = '',
public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public pan: string|undefined = undefined,
public city: string|undefined = undefined,
public state: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
pan: (this.pan != null) ? this.pan : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined
        };
    }
    



}



export class ClientFileBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientFileBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileBasicInfo {
        const json: any = o ;
        return new ClientFileBasicInfo(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.clientBasicInfo != null) ? ClientBasicInfo.fromJson(json.clientBasicInfo) : new ClientBasicInfo(),
(json.assignedRM != null) ? json.assignedRM : undefined,
(json.assignedSalesRep != null) ? json.assignedSalesRep : undefined,
(json.wad != null) ? json.wad : undefined,
(json.creditScore != null) ? json.creditScore : undefined,
(json.creditBureau != null) ? json.creditBureau : undefined,
(json.isFirstMSFPaid != null) ? json.isFirstMSFPaid : false,
(json.programCode != null) ? json.programCode : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: CLIENT_FILE_STATUS = CLIENT_FILE_STATUS.DEFAULT_VALUE,
public clientBasicInfo: ClientBasicInfo = new ClientBasicInfo(),
public assignedRM: string|undefined = undefined,
public assignedSalesRep: string|undefined = undefined,
public wad: number|undefined = undefined,
public creditScore: number|undefined = undefined,
public creditBureau: string|undefined = undefined,
public isFirstMSFPaid: boolean = false,
public programCode: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
clientBasicInfo: (this.clientBasicInfo != null) ? this.clientBasicInfo.toJson() : undefined,
assignedRM: (this.assignedRM != null) ? this.assignedRM : undefined,
assignedSalesRep: (this.assignedSalesRep != null) ? this.assignedSalesRep : undefined,
wad: (this.wad != null) ? this.wad : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined,
isFirstMSFPaid: (this.isFirstMSFPaid != null) ? this.isFirstMSFPaid : undefined,
programCode: (this.programCode != null) ? this.programCode : undefined
        };
    }
    



}



export class AttachDocumentInput {

   
    public static fromJsonString(jsonString: string): AttachDocumentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AttachDocumentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AttachDocumentInput {
        const json: any = o ;
        return new AttachDocumentInput(
         (json.clientFileId != null) ? json.clientFileId : undefined,
(json.documentType != null) ? json.documentType : '',
(json.documentDetails != null) ? json.documentDetails : undefined,
(json.documentPath != null) ? json.documentPath : '',
(json.signedOn != null) ? json.signedOn : undefined,
(json.ipAddr != null) ? json.ipAddr : undefined,
(json.fiCreditorId != null) ? json.fiCreditorId : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string|undefined = undefined,
public documentType: string = '',
public documentDetails: string|undefined = undefined,
public documentPath: string = '',
public signedOn: string|undefined = undefined,
public ipAddr: string|undefined = undefined,
public fiCreditorId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
signedOn: (this.signedOn != null) ? this.signedOn : undefined,
ipAddr: (this.ipAddr != null) ? this.ipAddr : undefined,
fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined
        };
    }
    



}



export class FiDocument {

   
    public static fromJsonString(jsonString: string): FiDocument {
       const jsonObj: object = JSON.parse(jsonString);
       return FiDocument.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiDocument {
        const json: any = o ;
        return new FiDocument(
         (json.fiDocumentId != null) ? json.fiDocumentId : '',
(json.documentType != null) ? json.documentType : '',
(json.documentPath != null) ? json.documentPath : '',
(json.documentDetails != null) ? json.documentDetails : '',
(json.uploadedOn != null) ? json.uploadedOn : '',
(json.archived != null) ? json.archived : false
        );
    }



  


   
    constructor(
        public fiDocumentId: string = '',
public documentType: string = '',
public documentPath: string = '',
public documentDetails: string = '',
public uploadedOn: string = '',
public archived: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiDocumentId: (this.fiDocumentId != null) ? this.fiDocumentId : undefined,
documentType: (this.documentType != null) ? this.documentType : undefined,
documentPath: (this.documentPath != null) ? this.documentPath : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined,
uploadedOn: (this.uploadedOn != null) ? this.uploadedOn : undefined,
archived: (this.archived != null) ? this.archived : undefined
        };
    }
    



}



export class UploadDocumentForm {

   
    public static fromJsonString(jsonString: string): UploadDocumentForm {
       const jsonObj: object = JSON.parse(jsonString);
       return UploadDocumentForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UploadDocumentForm {
        const json: any = o ;
        return new UploadDocumentForm(
         (json.fileId != null) ? json.fileId : '',
(json.docType != null) ? json.docType : '',
(json.fileDoc != null) ? json.fileDoc : null,
(json.documentDetails != null) ? json.documentDetails : ''
        );
    }



  


   
    constructor(
        public fileId: string = '',
public docType: string = '',
public fileDoc: File|null = null,
public documentDetails: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fileId: (this.fileId != null) ? this.fileId : undefined,
docType: (this.docType != null) ? this.docType : undefined,
fileDoc: (this.fileDoc != null) ? this.fileDoc : undefined,
documentDetails: (this.documentDetails != null) ? this.documentDetails : undefined
        };
    }
    
    public toFormData(): FormData {
      const fd = new FormData();
      if (this.fileId != null) {fd.append('fileId', this.fileId.toString()); }
if (this.docType != null) {fd.append('docType', this.docType.toString()); }
if (this.fileDoc != null) {fd.append('fileDoc', this.fileDoc, this.fileDoc.name); }
if (this.documentDetails != null) {fd.append('documentDetails', this.documentDetails.toString()); }
      return fd;
    }
    



}



export class ClientAddress {

   
    public static fromJsonString(jsonString: string): ClientAddress {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientAddress.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientAddress {
        const json: any = o ;
        return new ClientAddress(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : '',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = '',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class ClPersonalInfo {

   
    public static fromJsonString(jsonString: string): ClPersonalInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClPersonalInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClPersonalInfo {
        const json: any = o ;
        return new ClPersonalInfo(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.email != null) ? json.email : '',
(json.mobile != null) ? json.mobile : '',
(json.secondaryPhone != null) ? json.secondaryPhone : undefined,
(json.motherMaidenName != null) ? json.motherMaidenName : undefined,
(json.fatherName != null) ? json.fatherName : undefined,
(json.pan != null) ? json.pan : undefined,
(json.dob != null) ? json.dob : undefined,
(json.gender != null) ? json.gender : undefined,
(json.residentialAddress != null) ? ClientAddress.fromJson(json.residentialAddress) : undefined,
(json.clientLanguage != null) ? json.clientLanguage : ''
        );
    }



  


   
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public email: string = '',
public mobile: string = '',
public secondaryPhone: string|undefined = undefined,
public motherMaidenName: string|undefined = undefined,
public fatherName: string|undefined = undefined,
public pan: string|undefined = undefined,
public dob: string|undefined = undefined,
public gender: string|undefined = undefined,
public residentialAddress: ClientAddress|undefined = undefined,
public clientLanguage: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
email: (this.email != null) ? this.email : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
secondaryPhone: (this.secondaryPhone != null) ? this.secondaryPhone : undefined,
motherMaidenName: (this.motherMaidenName != null) ? this.motherMaidenName : undefined,
fatherName: (this.fatherName != null) ? this.fatherName : undefined,
pan: (this.pan != null) ? this.pan : undefined,
dob: (this.dob != null) ? this.dob : undefined,
gender: (this.gender != null) ? this.gender : undefined,
residentialAddress: (this.residentialAddress != null) ? this.residentialAddress.toJson() : undefined,
clientLanguage: (this.clientLanguage != null) ? this.clientLanguage : undefined
        };
    }
    



}



export class FiBankInfo {

   
    public static fromJsonString(jsonString: string): FiBankInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return FiBankInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiBankInfo {
        const json: any = o ;
        return new FiBankInfo(
         (json.name != null) ? json.name : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? json.accountType : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.nupayBankMasterId != null) ? json.nupayBankMasterId : '',
(json.bankAddress != null) ? FiBankAddress.fromJson(json.bankAddress) : new FiBankAddress()
        );
    }



  


   
    constructor(
        public name: string = '',
public accountNumber: string = '',
public ifscCode: string = '',
public accountType: string = '',
public accountHolderName: string = '',
public nupayBankMasterId: string = '',
public bankAddress: FiBankAddress = new FiBankAddress()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          name: (this.name != null) ? this.name : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ? this.accountType : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined
        };
    }
    



}



export class FiEMandateSummary {

   
    public static fromJsonString(jsonString: string): FiEMandateSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return FiEMandateSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiEMandateSummary {
        const json: any = o ;
        return new FiEMandateSummary(
         (json.eMandateId != null) ? json.eMandateId : '',
(json.status != null) ? EMANDATE_STATUS.fromJson(json.status) : EMANDATE_STATUS.DEFAULT_VALUE,
(json.bankName != null) ? json.bankName : '',
(json.nupayBankId != null) ? json.nupayBankId : 0,
(json.nupayMandateRegistrationId != null) ? json.nupayMandateRegistrationId : '',
(json.psPlanId != null) ? json.psPlanId : '',
(json.reasonDesc != null) ? json.reasonDesc : undefined,
(json.reasonCode != null) ? json.reasonCode : undefined,
(json.rejectBy != null) ? json.rejectBy : undefined,
(json.npciRefNumber != null) ? json.npciRefNumber : undefined,
(json.eMandateLink != null) ? json.eMandateLink : undefined,
(json.redirectUrl != null) ? json.redirectUrl : undefined,
(json.umrn != null) ? json.umrn : undefined,
(json.remoteEMandateId != null) ? json.remoteEMandateId : undefined,
(json.provider != null) ? EMANDATE_PROVIDER.fromJson(json.provider) : EMANDATE_PROVIDER.DEFAULT_VALUE,
(json.accountNumber != null) ? json.accountNumber : '',
(json.amount != null) ? json.amount : 0,
(json.label != null) ? json.label : '',
(json.isDefault != null) ? json.isDefault : false,
(json.createdOn != null) ? json.createdOn : '',
(json.activatedOn != null) ? json.activatedOn : undefined,
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.selfEnrolEMandateLink != null) ? json.selfEnrolEMandateLink : undefined
        );
    }



  


   
    constructor(
        public eMandateId: string = '',
public status: EMANDATE_STATUS = EMANDATE_STATUS.DEFAULT_VALUE,
public bankName: string = '',
public nupayBankId: number = 0,
public nupayMandateRegistrationId: string = '',
public psPlanId: string = '',
public reasonDesc: string|undefined = undefined,
public reasonCode: string|undefined = undefined,
public rejectBy: string|undefined = undefined,
public npciRefNumber: string|undefined = undefined,
public eMandateLink: string|undefined = undefined,
public redirectUrl: string|undefined = undefined,
public umrn: string|undefined = undefined,
public remoteEMandateId: string|undefined = undefined,
public provider: EMANDATE_PROVIDER = EMANDATE_PROVIDER.DEFAULT_VALUE,
public accountNumber: string = '',
public amount: number = 0,
public label: string = '',
public isDefault: boolean = false,
public createdOn: string = '',
public activatedOn: string|undefined = undefined,
public ifscCode: string = '',
public accountHolderName: string = '',
public selfEnrolEMandateLink: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
status: (this.status != null) ?  EMANDATE_STATUS.copyMe(this.status).toJson() : undefined,
bankName: (this.bankName != null) ? this.bankName : undefined,
nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
nupayMandateRegistrationId: (this.nupayMandateRegistrationId != null) ? this.nupayMandateRegistrationId : undefined,
psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
reasonDesc: (this.reasonDesc != null) ? this.reasonDesc : undefined,
reasonCode: (this.reasonCode != null) ? this.reasonCode : undefined,
rejectBy: (this.rejectBy != null) ? this.rejectBy : undefined,
npciRefNumber: (this.npciRefNumber != null) ? this.npciRefNumber : undefined,
eMandateLink: (this.eMandateLink != null) ? this.eMandateLink : undefined,
redirectUrl: (this.redirectUrl != null) ? this.redirectUrl : undefined,
umrn: (this.umrn != null) ? this.umrn : undefined,
remoteEMandateId: (this.remoteEMandateId != null) ? this.remoteEMandateId : undefined,
provider: (this.provider != null) ?  EMANDATE_PROVIDER.copyMe(this.provider).toJson() : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
amount: (this.amount != null) ? this.amount : undefined,
label: (this.label != null) ? this.label : undefined,
isDefault: (this.isDefault != null) ? this.isDefault : undefined,
createdOn: (this.createdOn != null) ? this.createdOn : undefined,
activatedOn: (this.activatedOn != null) ? this.activatedOn : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
selfEnrolEMandateLink: (this.selfEnrolEMandateLink != null) ? this.selfEnrolEMandateLink : undefined
        };
    }
    



}



export class FiPaymentCalculator {

   
    public static fromJsonString(jsonString: string): FiPaymentCalculator {
       const jsonObj: object = JSON.parse(jsonString);
       return FiPaymentCalculator.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiPaymentCalculator {
        const json: any = o ;
        return new FiPaymentCalculator(
         (json.ppCode != null) ? json.ppCode : '',
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 0,
(json.firstDraftDate != null) ? json.firstDraftDate : '',
(json.feeCode != null) ? json.feeCode : '',
(json.feeFirstDraftDate != null) ? json.feeFirstDraftDate : '',
(json.msfDraftDay != null) ? json.msfDraftDay : undefined,
(json.msfDraftAmount != null) ? json.msfDraftAmount : undefined,
(json.spaDraftDay != null) ? json.spaDraftDay : undefined,
(json.spaDraftAmount != null) ? json.spaDraftAmount : undefined,
(json.totalMonthlyObligation != null) ? json.totalMonthlyObligation : undefined,
(json.repaymentAmount != null) ? json.repaymentAmount : undefined,
(json.msfSlabAmount != null) ? json.msfSlabAmount : 0,
(json.msfDiscountAmount != null) ? json.msfDiscountAmount : undefined
        );
    }



  


   
    constructor(
        public ppCode: string = '',
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 0,
public firstDraftDate: string = '',
public feeCode: string = '',
public feeFirstDraftDate: string = '',
public msfDraftDay: number|undefined = undefined,
public msfDraftAmount: number|undefined = undefined,
public spaDraftDay: number|undefined = undefined,
public spaDraftAmount: number|undefined = undefined,
public totalMonthlyObligation: number|undefined = undefined,
public repaymentAmount: number|undefined = undefined,
public msfSlabAmount: number = 0,
public msfDiscountAmount: number|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          ppCode: (this.ppCode != null) ? this.ppCode : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
firstDraftDate: (this.firstDraftDate != null) ? this.firstDraftDate : undefined,
feeCode: (this.feeCode != null) ? this.feeCode : undefined,
feeFirstDraftDate: (this.feeFirstDraftDate != null) ? this.feeFirstDraftDate : undefined,
msfDraftDay: (this.msfDraftDay != null) ? this.msfDraftDay : undefined,
msfDraftAmount: (this.msfDraftAmount != null) ? this.msfDraftAmount : undefined,
spaDraftDay: (this.spaDraftDay != null) ? this.spaDraftDay : undefined,
spaDraftAmount: (this.spaDraftAmount != null) ? this.spaDraftAmount : undefined,
totalMonthlyObligation: (this.totalMonthlyObligation != null) ? this.totalMonthlyObligation : undefined,
repaymentAmount: (this.repaymentAmount != null) ? this.repaymentAmount : undefined,
msfSlabAmount: (this.msfSlabAmount != null) ? this.msfSlabAmount : undefined,
msfDiscountAmount: (this.msfDiscountAmount != null) ? this.msfDiscountAmount : undefined
        };
    }
    



}



export class FiFeeEntry {

   
    public static fromJsonString(jsonString: string): FiFeeEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return FiFeeEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiFeeEntry {
        const json: any = o ;
        return new FiFeeEntry(
         (json.draftDate != null) ? new Date(json.draftDate) : undefined,
(json.feeCode != null) ? json.feeCode : undefined,
(json.collectionOrder != null) ? json.collectionOrder : undefined,
(json.amount != null) ? json.amount : 0,
(json.taxAmount != null) ? json.taxAmount : 0,
(json.totalAmount != null) ? json.totalAmount : 0,
(json.status != null) ? json.status : '',
(json.msfEntryId != null) ? json.msfEntryId : undefined,
(json.paymentId != null) ? json.paymentId : undefined,
(json.paymentStatus != null) ? PAYMENT_STATUS.fromJson(json.paymentStatus) : undefined,
(json.statusUpdatedOn != null) ? json.statusUpdatedOn : '',
(json.linkedEMandate != null) ? FiEMandateSummary.fromJson(json.linkedEMandate) : undefined
        );
    }



  


   
    constructor(
        public draftDate: Date|undefined = undefined,
public feeCode: string|undefined = undefined,
public collectionOrder: number|undefined = undefined,
public amount: number = 0,
public taxAmount: number = 0,
public totalAmount: number = 0,
public status: string = '',
public msfEntryId: string|undefined = undefined,
public paymentId: string|undefined = undefined,
public paymentStatus: PAYMENT_STATUS|undefined = undefined,
public statusUpdatedOn: string = '',
public linkedEMandate: FiEMandateSummary|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          draftDate: (this.draftDate != null) ? this.draftDate.toISOString() : undefined,
feeCode: (this.feeCode != null) ? this.feeCode : undefined,
collectionOrder: (this.collectionOrder != null) ? this.collectionOrder : undefined,
amount: (this.amount != null) ? this.amount : undefined,
taxAmount: (this.taxAmount != null) ? this.taxAmount : undefined,
totalAmount: (this.totalAmount != null) ? this.totalAmount : undefined,
status: (this.status != null) ? this.status : undefined,
msfEntryId: (this.msfEntryId != null) ? this.msfEntryId : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined,
paymentStatus: (this.paymentStatus != null) ? PAYMENT_STATUS.copyMe(this.paymentStatus).toJson() : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn : undefined,
linkedEMandate: (this.linkedEMandate != null) ? this.linkedEMandate.toJson() : undefined
        };
    }
    



}



export class FiPSEntry {

   
    public static fromJsonString(jsonString: string): FiPSEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return FiPSEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiPSEntry {
        const json: any = o ;
        return new FiPSEntry(
         (json.draftDate != null) ? new Date(json.draftDate) : new Date(),
(json.totalAmount != null) ? json.totalAmount : 0,
(json.settlementReserve != null) ? json.settlementReserve : 0,
(json.feeList != null) ? (json.feeList as object[]).map((x) => FiFeeEntry.fromJson(x)) : [],
(json.psEntryId != null) ? json.psEntryId : '',
(json.status != null) ? json.status : '',
(json.feeAmount != null) ? json.feeAmount : '',
(json.spaAmount != null) ? json.spaAmount : '',
(json.paymentStatus != null) ? json.paymentStatus : '',
(json.paymentId != null) ? json.paymentId : '',
(json.statusUpdatedOn != null) ? new Date(json.statusUpdatedOn) : new Date(),
(json.linkedEMandate != null) ? FiEMandateSummary.fromJson(json.linkedEMandate) : undefined
        );
    }



  


   
    constructor(
        public draftDate: Date = new Date(),
public totalAmount: number = 0,
public settlementReserve: number = 0,
public feeList: FiFeeEntry[] = [],
public psEntryId: string = '',
public status: string = '',
public feeAmount: string = '',
public spaAmount: string = '',
public paymentStatus: string = '',
public paymentId: string = '',
public statusUpdatedOn: Date = new Date(),
public linkedEMandate: FiEMandateSummary|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          draftDate: (this.draftDate != null) ? this.draftDate.toISOString() : undefined,
totalAmount: (this.totalAmount != null) ? this.totalAmount : undefined,
settlementReserve: (this.settlementReserve != null) ? this.settlementReserve : undefined,
feeList: (this.feeList != null) ? this.feeList.map((x) => x.toJson()) : [],
psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined,
status: (this.status != null) ? this.status : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
paymentStatus: (this.paymentStatus != null) ? this.paymentStatus : undefined,
paymentId: (this.paymentId != null) ? this.paymentId : undefined,
statusUpdatedOn: (this.statusUpdatedOn != null) ? this.statusUpdatedOn.toISOString() : undefined,
linkedEMandate: (this.linkedEMandate != null) ? this.linkedEMandate.toJson() : undefined
        };
    }
    



}



export class FiPaymentPlanInfo {

   
    public static fromJsonString(jsonString: string): FiPaymentPlanInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return FiPaymentPlanInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiPaymentPlanInfo {
        const json: any = o ;
        return new FiPaymentPlanInfo(
         (json.psPlanId != null) ? json.psPlanId : '',
(json.psPlanStatus != null) ? json.psPlanStatus : '',
(json.ppCalculator != null) ? FiPaymentCalculator.fromJson(json.ppCalculator) : new FiPaymentCalculator(),
(json.paymentScheduleList != null) ? (json.paymentScheduleList as object[]).map((x) => FiPSEntry.fromJson(x)) : [],
(json.subscriptionFeeScheduleList != null) ? (json.subscriptionFeeScheduleList as object[]).map((x) => FiFeeEntry.fromJson(x)) : [],
(json.psEntryTotalAmount != null) ? json.psEntryTotalAmount : 0,
(json.psEntryTotalFeeAmount != null) ? json.psEntryTotalFeeAmount : 0,
(json.psEntryTotalSpaAmount != null) ? json.psEntryTotalSpaAmount : 0,
(json.psEntryTotalPresentedAmount != null) ? json.psEntryTotalPresentedAmount : 0,
(json.psEntryTotalPaidAmount != null) ? json.psEntryTotalPaidAmount : 0
        );
    }



  


   
    constructor(
        public psPlanId: string = '',
public psPlanStatus: string = '',
public ppCalculator: FiPaymentCalculator = new FiPaymentCalculator(),
public paymentScheduleList: FiPSEntry[] = [],
public subscriptionFeeScheduleList: FiFeeEntry[] = [],
public psEntryTotalAmount: number = 0,
public psEntryTotalFeeAmount: number = 0,
public psEntryTotalSpaAmount: number = 0,
public psEntryTotalPresentedAmount: number = 0,
public psEntryTotalPaidAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
psPlanStatus: (this.psPlanStatus != null) ? this.psPlanStatus : undefined,
ppCalculator: (this.ppCalculator != null) ? this.ppCalculator.toJson() : undefined,
paymentScheduleList: (this.paymentScheduleList != null) ? this.paymentScheduleList.map((x) => x.toJson()) : [],
subscriptionFeeScheduleList: (this.subscriptionFeeScheduleList != null) ? this.subscriptionFeeScheduleList.map((x) => x.toJson()) : [],
psEntryTotalAmount: (this.psEntryTotalAmount != null) ? this.psEntryTotalAmount : undefined,
psEntryTotalFeeAmount: (this.psEntryTotalFeeAmount != null) ? this.psEntryTotalFeeAmount : undefined,
psEntryTotalSpaAmount: (this.psEntryTotalSpaAmount != null) ? this.psEntryTotalSpaAmount : undefined,
psEntryTotalPresentedAmount: (this.psEntryTotalPresentedAmount != null) ? this.psEntryTotalPresentedAmount : undefined,
psEntryTotalPaidAmount: (this.psEntryTotalPaidAmount != null) ? this.psEntryTotalPaidAmount : undefined
        };
    }
    



}



export class FiPayment {

   
    public static fromJsonString(jsonString: string): FiPayment {
       const jsonObj: object = JSON.parse(jsonString);
       return FiPayment.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiPayment {
        const json: any = o ;
        return new FiPayment(
         (json.paymentId != null) ? json.paymentId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.paymentType != null) ? PAYMENT_TYPE.fromJson(json.paymentType) : PAYMENT_TYPE.DEFAULT_VALUE,
(json.paymentProvider != null) ? PAYMENT_PROVIDER.fromJson(json.paymentProvider) : PAYMENT_PROVIDER.DEFAULT_VALUE,
(json.paymentMode != null) ? PAYMENT_MODE.fromJson(json.paymentMode) : undefined,
(json.accountNumber != null) ? json.accountNumber : undefined,
(json.accountHolderName != null) ? json.accountHolderName : undefined,
(json.accountType != null) ? ACCOUNT_TYPE.fromJson(json.accountType) : undefined,
(json.ifscCode != null) ? json.ifscCode : undefined,
(json.umrn != null) ? json.umrn : undefined,
(json.eMandateId != null) ? json.eMandateId : undefined,
(json.presentedDate != null) ? json.presentedDate : undefined,
(json.totalAmount != null) ? json.totalAmount : 0,
(json.spaAmount != null) ? json.spaAmount : undefined,
(json.feeAmount != null) ? json.feeAmount : undefined,
(json.msfAmount != null) ? json.msfAmount : undefined,
(json.paymentRefNumber != null) ? json.paymentRefNumber : '',
(json.remoteTxnRefNumber != null) ? json.remoteTxnRefNumber : undefined,
(json.remoteTxnRefDetails != null) ? json.remoteTxnRefDetails : undefined,
(json.achSeqNumber != null) ? json.achSeqNumber : undefined,
(json.status != null) ? PAYMENT_STATUS.fromJson(json.status) : PAYMENT_STATUS.DEFAULT_VALUE,
(json.source != null) ? json.source : undefined,
(json.details != null) ? json.details : undefined,
(json.receivedBy != null) ? json.receivedBy : undefined,
(json.refId != null) ? json.refId : undefined,
(json.lastUpdatedTime != null) ? json.lastUpdatedTime : undefined,
(json.selfEnrolPaymentLink != null) ? json.selfEnrolPaymentLink : undefined
        );
    }



  


   
    constructor(
        public paymentId: string = '',
public clientFileNumber: string = '',
public paymentType: PAYMENT_TYPE = PAYMENT_TYPE.DEFAULT_VALUE,
public paymentProvider: PAYMENT_PROVIDER = PAYMENT_PROVIDER.DEFAULT_VALUE,
public paymentMode: PAYMENT_MODE|undefined = undefined,
public accountNumber: string|undefined = undefined,
public accountHolderName: string|undefined = undefined,
public accountType: ACCOUNT_TYPE|undefined = undefined,
public ifscCode: string|undefined = undefined,
public umrn: string|undefined = undefined,
public eMandateId: string|undefined = undefined,
public presentedDate: string|undefined = undefined,
public totalAmount: number = 0,
public spaAmount: number|undefined = undefined,
public feeAmount: number|undefined = undefined,
public msfAmount: number|undefined = undefined,
public paymentRefNumber: string = '',
public remoteTxnRefNumber: string|undefined = undefined,
public remoteTxnRefDetails: object|undefined = undefined,
public achSeqNumber: string|undefined = undefined,
public status: PAYMENT_STATUS = PAYMENT_STATUS.DEFAULT_VALUE,
public source: string|undefined = undefined,
public details: string|undefined = undefined,
public receivedBy: string|undefined = undefined,
public refId: string|undefined = undefined,
public lastUpdatedTime: string|undefined = undefined,
public selfEnrolPaymentLink: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
paymentType: (this.paymentType != null) ?  PAYMENT_TYPE.copyMe(this.paymentType).toJson() : undefined,
paymentProvider: (this.paymentProvider != null) ?  PAYMENT_PROVIDER.copyMe(this.paymentProvider).toJson() : undefined,
paymentMode: (this.paymentMode != null) ? PAYMENT_MODE.copyMe(this.paymentMode).toJson() : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
accountType: (this.accountType != null) ? ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
umrn: (this.umrn != null) ? this.umrn : undefined,
eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
presentedDate: (this.presentedDate != null) ? this.presentedDate : undefined,
totalAmount: (this.totalAmount != null) ? this.totalAmount : undefined,
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined,
paymentRefNumber: (this.paymentRefNumber != null) ? this.paymentRefNumber : undefined,
remoteTxnRefNumber: (this.remoteTxnRefNumber != null) ? this.remoteTxnRefNumber : undefined,
remoteTxnRefDetails: (this.remoteTxnRefDetails != null) ? this.remoteTxnRefDetails : undefined,
achSeqNumber: (this.achSeqNumber != null) ? this.achSeqNumber : undefined,
status: (this.status != null) ?  PAYMENT_STATUS.copyMe(this.status).toJson() : undefined,
source: (this.source != null) ? this.source : undefined,
details: (this.details != null) ? this.details : undefined,
receivedBy: (this.receivedBy != null) ? this.receivedBy : undefined,
refId: (this.refId != null) ? this.refId : undefined,
lastUpdatedTime: (this.lastUpdatedTime != null) ? this.lastUpdatedTime : undefined,
selfEnrolPaymentLink: (this.selfEnrolPaymentLink != null) ? this.selfEnrolPaymentLink : undefined
        };
    }
    



}



export class BudgetIncomeSources {

   
    public static fromJsonString(jsonString: string): BudgetIncomeSources {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetIncomeSources.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetIncomeSources {
        const json: any = o ;
        return new BudgetIncomeSources(
         (json.salary != null) ? json.salary : 0,
(json.businessIncome != null) ? json.businessIncome : 0,
(json.otherIncome != null) ? json.otherIncome : 0,
(json.familySupport != null) ? json.familySupport : 0
        );
    }



  


   
    constructor(
        public salary: number = 0,
public businessIncome: number = 0,
public otherIncome: number = 0,
public familySupport: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          salary: (this.salary != null) ? this.salary : undefined,
businessIncome: (this.businessIncome != null) ? this.businessIncome : undefined,
otherIncome: (this.otherIncome != null) ? this.otherIncome : undefined,
familySupport: (this.familySupport != null) ? this.familySupport : undefined
        };
    }
    



}



export class BudgetDebtRepayments {

   
    public static fromJsonString(jsonString: string): BudgetDebtRepayments {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetDebtRepayments.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetDebtRepayments {
        const json: any = o ;
        return new BudgetDebtRepayments(
         (json.autoLoan != null) ? json.autoLoan : 0,
(json.housingLoan != null) ? json.housingLoan : 0,
(json.loansAgainstSecurity != null) ? json.loansAgainstSecurity : 0,
(json.collateralizedBussinessLoan != null) ? json.collateralizedBussinessLoan : 0
        );
    }



  


   
    constructor(
        public autoLoan: number = 0,
public housingLoan: number = 0,
public loansAgainstSecurity: number = 0,
public collateralizedBussinessLoan: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          autoLoan: (this.autoLoan != null) ? this.autoLoan : undefined,
housingLoan: (this.housingLoan != null) ? this.housingLoan : undefined,
loansAgainstSecurity: (this.loansAgainstSecurity != null) ? this.loansAgainstSecurity : undefined,
collateralizedBussinessLoan: (this.collateralizedBussinessLoan != null) ? this.collateralizedBussinessLoan : undefined
        };
    }
    



}



export class BudgetLivingExpenses {

   
    public static fromJsonString(jsonString: string): BudgetLivingExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetLivingExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetLivingExpenses {
        const json: any = o ;
        return new BudgetLivingExpenses(
         (json.rentMaintenance != null) ? json.rentMaintenance : 0,
(json.groceryExpense != null) ? json.groceryExpense : 0,
(json.electricityBill != null) ? json.electricityBill : 0,
(json.gasBill != null) ? json.gasBill : 0,
(json.phoneBill != null) ? json.phoneBill : 0,
(json.otherUtilities != null) ? json.otherUtilities : 0
        );
    }



  


   
    constructor(
        public rentMaintenance: number = 0,
public groceryExpense: number = 0,
public electricityBill: number = 0,
public gasBill: number = 0,
public phoneBill: number = 0,
public otherUtilities: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          rentMaintenance: (this.rentMaintenance != null) ? this.rentMaintenance : undefined,
groceryExpense: (this.groceryExpense != null) ? this.groceryExpense : undefined,
electricityBill: (this.electricityBill != null) ? this.electricityBill : undefined,
gasBill: (this.gasBill != null) ? this.gasBill : undefined,
phoneBill: (this.phoneBill != null) ? this.phoneBill : undefined,
otherUtilities: (this.otherUtilities != null) ? this.otherUtilities : undefined
        };
    }
    



}



export class BudgetLifeStyleExpenses {

   
    public static fromJsonString(jsonString: string): BudgetLifeStyleExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetLifeStyleExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetLifeStyleExpenses {
        const json: any = o ;
        return new BudgetLifeStyleExpenses(
         (json.travelExpense != null) ? json.travelExpense : 0,
(json.digitalSubscriptionsExpense != null) ? json.digitalSubscriptionsExpense : 0,
(json.diningOutExpense != null) ? json.diningOutExpense : 0,
(json.houseHelpExpense != null) ? json.houseHelpExpense : 0,
(json.outingExpense != null) ? json.outingExpense : 0
        );
    }



  


   
    constructor(
        public travelExpense: number = 0,
public digitalSubscriptionsExpense: number = 0,
public diningOutExpense: number = 0,
public houseHelpExpense: number = 0,
public outingExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          travelExpense: (this.travelExpense != null) ? this.travelExpense : undefined,
digitalSubscriptionsExpense: (this.digitalSubscriptionsExpense != null) ? this.digitalSubscriptionsExpense : undefined,
diningOutExpense: (this.diningOutExpense != null) ? this.diningOutExpense : undefined,
houseHelpExpense: (this.houseHelpExpense != null) ? this.houseHelpExpense : undefined,
outingExpense: (this.outingExpense != null) ? this.outingExpense : undefined
        };
    }
    



}



export class BudgetDependentExpenses {

   
    public static fromJsonString(jsonString: string): BudgetDependentExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetDependentExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetDependentExpenses {
        const json: any = o ;
        return new BudgetDependentExpenses(
         (json.medicalExpense != null) ? json.medicalExpense : 0,
(json.essentialCareExpense != null) ? json.essentialCareExpense : 0,
(json.schoolFeeExpense != null) ? json.schoolFeeExpense : 0,
(json.schoolBusExpense != null) ? json.schoolBusExpense : 0,
(json.booksAndProjectsExpense != null) ? json.booksAndProjectsExpense : 0,
(json.nursingAttendExpense != null) ? json.nursingAttendExpense : 0,
(json.petCareExpense != null) ? json.petCareExpense : 0
        );
    }



  


   
    constructor(
        public medicalExpense: number = 0,
public essentialCareExpense: number = 0,
public schoolFeeExpense: number = 0,
public schoolBusExpense: number = 0,
public booksAndProjectsExpense: number = 0,
public nursingAttendExpense: number = 0,
public petCareExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          medicalExpense: (this.medicalExpense != null) ? this.medicalExpense : undefined,
essentialCareExpense: (this.essentialCareExpense != null) ? this.essentialCareExpense : undefined,
schoolFeeExpense: (this.schoolFeeExpense != null) ? this.schoolFeeExpense : undefined,
schoolBusExpense: (this.schoolBusExpense != null) ? this.schoolBusExpense : undefined,
booksAndProjectsExpense: (this.booksAndProjectsExpense != null) ? this.booksAndProjectsExpense : undefined,
nursingAttendExpense: (this.nursingAttendExpense != null) ? this.nursingAttendExpense : undefined,
petCareExpense: (this.petCareExpense != null) ? this.petCareExpense : undefined
        };
    }
    



}



export class BudgetIncidentalExpenses {

   
    public static fromJsonString(jsonString: string): BudgetIncidentalExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetIncidentalExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetIncidentalExpenses {
        const json: any = o ;
        return new BudgetIncidentalExpenses(
         (json.healthInsuranceExpense != null) ? json.healthInsuranceExpense : 0,
(json.vehicleInsuranceExpense != null) ? json.vehicleInsuranceExpense : 0,
(json.sipExpense != null) ? json.sipExpense : 0,
(json.stockTradesExpense != null) ? json.stockTradesExpense : 0,
(json.otherExpense != null) ? json.otherExpense : 0
        );
    }



  


   
    constructor(
        public healthInsuranceExpense: number = 0,
public vehicleInsuranceExpense: number = 0,
public sipExpense: number = 0,
public stockTradesExpense: number = 0,
public otherExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          healthInsuranceExpense: (this.healthInsuranceExpense != null) ? this.healthInsuranceExpense : undefined,
vehicleInsuranceExpense: (this.vehicleInsuranceExpense != null) ? this.vehicleInsuranceExpense : undefined,
sipExpense: (this.sipExpense != null) ? this.sipExpense : undefined,
stockTradesExpense: (this.stockTradesExpense != null) ? this.stockTradesExpense : undefined,
otherExpense: (this.otherExpense != null) ? this.otherExpense : undefined
        };
    }
    



}



export class BudgetMiscellaneousExpenses {

   
    public static fromJsonString(jsonString: string): BudgetMiscellaneousExpenses {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetMiscellaneousExpenses.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetMiscellaneousExpenses {
        const json: any = o ;
        return new BudgetMiscellaneousExpenses(
         (json.miscellaneousExpense != null) ? json.miscellaneousExpense : 0
        );
    }



  


   
    constructor(
        public miscellaneousExpense: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          miscellaneousExpense: (this.miscellaneousExpense != null) ? this.miscellaneousExpense : undefined
        };
    }
    



}



export class BudgetIneligibleUnsecuredDebts {

   
    public static fromJsonString(jsonString: string): BudgetIneligibleUnsecuredDebts {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetIneligibleUnsecuredDebts.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetIneligibleUnsecuredDebts {
        const json: any = o ;
        return new BudgetIneligibleUnsecuredDebts(
         (json.personalLoanDebt != null) ? json.personalLoanDebt : 0,
(json.creditCardDebt != null) ? json.creditCardDebt : 0
        );
    }



  


   
    constructor(
        public personalLoanDebt: number = 0,
public creditCardDebt: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          personalLoanDebt: (this.personalLoanDebt != null) ? this.personalLoanDebt : undefined,
creditCardDebt: (this.creditCardDebt != null) ? this.creditCardDebt : undefined
        };
    }
    



}



export class BudgetInfo {

   
    public static fromJsonString(jsonString: string): BudgetInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return BudgetInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BudgetInfo {
        const json: any = o ;
        return new BudgetInfo(
         (json.hardshipReason != null) ? json.hardshipReason : '',
(json.hardshipReasonCodeList != null) ? json.hardshipReasonCodeList : [],
(json.totalIncome != null) ? json.totalIncome : 0,
(json.proposedDSPayment != null) ? json.proposedDSPayment : 0,
(json.totalDebtRepayments != null) ? json.totalDebtRepayments : 0,
(json.totalLivingExpenses != null) ? json.totalLivingExpenses : 0,
(json.totalLifeStyleExpenses != null) ? json.totalLifeStyleExpenses : 0,
(json.totalDependentExpenses != null) ? json.totalDependentExpenses : 0,
(json.totalIncidentalExpenses != null) ? json.totalIncidentalExpenses : 0,
(json.totalMiscellaneousExpenses != null) ? json.totalMiscellaneousExpenses : 0,
(json.incomeSources != null) ? BudgetIncomeSources.fromJson(json.incomeSources) : new BudgetIncomeSources(),
(json.debtRepayments != null) ? BudgetDebtRepayments.fromJson(json.debtRepayments) : new BudgetDebtRepayments(),
(json.livingExpenses != null) ? BudgetLivingExpenses.fromJson(json.livingExpenses) : new BudgetLivingExpenses(),
(json.lifeStyleExpenses != null) ? BudgetLifeStyleExpenses.fromJson(json.lifeStyleExpenses) : new BudgetLifeStyleExpenses(),
(json.dependentExpenses != null) ? BudgetDependentExpenses.fromJson(json.dependentExpenses) : new BudgetDependentExpenses(),
(json.incidentalExpenses != null) ? BudgetIncidentalExpenses.fromJson(json.incidentalExpenses) : new BudgetIncidentalExpenses(),
(json.miscellaneousExpenses != null) ? BudgetMiscellaneousExpenses.fromJson(json.miscellaneousExpenses) : new BudgetMiscellaneousExpenses(),
(json.totalMonthlyExpense != null) ? json.totalMonthlyExpense : 0,
(json.hasDependents != null) ? json.hasDependents : undefined,
(json.hasPets != null) ? json.hasPets : undefined,
(json.maritalStatus != null) ? json.maritalStatus : undefined,
(json.hasKids != null) ? json.hasKids : undefined,
(json.rentedHouse != null) ? json.rentedHouse : undefined,
(json.employmentStatus != null) ? json.employmentStatus : undefined,
(json.employeeCompanyName != null) ? json.employeeCompanyName : undefined,
(json.age != null) ? json.age : undefined,
(json.fullName != null) ? json.fullName : undefined,
(json.gender != null) ? json.gender : undefined,
(json.ineligibleUnsecuredDebt != null) ? BudgetIneligibleUnsecuredDebts.fromJson(json.ineligibleUnsecuredDebt) : new BudgetIneligibleUnsecuredDebts()
        );
    }



  


   
    constructor(
        public hardshipReason: string = '',
public hardshipReasonCodeList: string[] = [],
public totalIncome: number = 0,
public proposedDSPayment: number = 0,
public totalDebtRepayments: number = 0,
public totalLivingExpenses: number = 0,
public totalLifeStyleExpenses: number = 0,
public totalDependentExpenses: number = 0,
public totalIncidentalExpenses: number = 0,
public totalMiscellaneousExpenses: number = 0,
public incomeSources: BudgetIncomeSources = new BudgetIncomeSources(),
public debtRepayments: BudgetDebtRepayments = new BudgetDebtRepayments(),
public livingExpenses: BudgetLivingExpenses = new BudgetLivingExpenses(),
public lifeStyleExpenses: BudgetLifeStyleExpenses = new BudgetLifeStyleExpenses(),
public dependentExpenses: BudgetDependentExpenses = new BudgetDependentExpenses(),
public incidentalExpenses: BudgetIncidentalExpenses = new BudgetIncidentalExpenses(),
public miscellaneousExpenses: BudgetMiscellaneousExpenses = new BudgetMiscellaneousExpenses(),
public totalMonthlyExpense: number = 0,
public hasDependents: boolean|undefined = undefined,
public hasPets: boolean|undefined = undefined,
public maritalStatus: string|undefined = undefined,
public hasKids: boolean|undefined = undefined,
public rentedHouse: string|undefined = undefined,
public employmentStatus: string|undefined = undefined,
public employeeCompanyName: string|undefined = undefined,
public age: number|undefined = undefined,
public fullName: string|undefined = undefined,
public gender: string|undefined = undefined,
public ineligibleUnsecuredDebt: BudgetIneligibleUnsecuredDebts = new BudgetIneligibleUnsecuredDebts()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          hardshipReason: (this.hardshipReason != null) ? this.hardshipReason : undefined,
hardshipReasonCodeList: (this.hardshipReasonCodeList != null) ? this.hardshipReasonCodeList : [],
totalIncome: (this.totalIncome != null) ? this.totalIncome : undefined,
proposedDSPayment: (this.proposedDSPayment != null) ? this.proposedDSPayment : undefined,
totalDebtRepayments: (this.totalDebtRepayments != null) ? this.totalDebtRepayments : undefined,
totalLivingExpenses: (this.totalLivingExpenses != null) ? this.totalLivingExpenses : undefined,
totalLifeStyleExpenses: (this.totalLifeStyleExpenses != null) ? this.totalLifeStyleExpenses : undefined,
totalDependentExpenses: (this.totalDependentExpenses != null) ? this.totalDependentExpenses : undefined,
totalIncidentalExpenses: (this.totalIncidentalExpenses != null) ? this.totalIncidentalExpenses : undefined,
totalMiscellaneousExpenses: (this.totalMiscellaneousExpenses != null) ? this.totalMiscellaneousExpenses : undefined,
incomeSources: (this.incomeSources != null) ? this.incomeSources.toJson() : undefined,
debtRepayments: (this.debtRepayments != null) ? this.debtRepayments.toJson() : undefined,
livingExpenses: (this.livingExpenses != null) ? this.livingExpenses.toJson() : undefined,
lifeStyleExpenses: (this.lifeStyleExpenses != null) ? this.lifeStyleExpenses.toJson() : undefined,
dependentExpenses: (this.dependentExpenses != null) ? this.dependentExpenses.toJson() : undefined,
incidentalExpenses: (this.incidentalExpenses != null) ? this.incidentalExpenses.toJson() : undefined,
miscellaneousExpenses: (this.miscellaneousExpenses != null) ? this.miscellaneousExpenses.toJson() : undefined,
totalMonthlyExpense: (this.totalMonthlyExpense != null) ? this.totalMonthlyExpense : undefined,
hasDependents: (this.hasDependents != null) ? this.hasDependents : undefined,
hasPets: (this.hasPets != null) ? this.hasPets : undefined,
maritalStatus: (this.maritalStatus != null) ? this.maritalStatus : undefined,
hasKids: (this.hasKids != null) ? this.hasKids : undefined,
rentedHouse: (this.rentedHouse != null) ? this.rentedHouse : undefined,
employmentStatus: (this.employmentStatus != null) ? this.employmentStatus : undefined,
employeeCompanyName: (this.employeeCompanyName != null) ? this.employeeCompanyName : undefined,
age: (this.age != null) ? this.age : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
gender: (this.gender != null) ? this.gender : undefined,
ineligibleUnsecuredDebt: (this.ineligibleUnsecuredDebt != null) ? this.ineligibleUnsecuredDebt.toJson() : undefined
        };
    }
    



}



export class AddFiCreditorInput {

   
    public static fromJsonString(jsonString: string): AddFiCreditorInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AddFiCreditorInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddFiCreditorInput {
        const json: any = o ;
        return new AddFiCreditorInput(
         (json.clientId != null) ? json.clientId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : '',
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string = '',
public skipUnderwrittingRule: boolean|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    



}



export class FiCreditor {

   
    public static fromJsonString(jsonString: string): FiCreditor {
       const jsonObj: object = JSON.parse(jsonString);
       return FiCreditor.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiCreditor {
        const json: any = o ;
        return new FiCreditor(
         (json.stEntryId != null) ? json.stEntryId : '',
(json.settlementStatus != null) ? json.settlementStatus : '',
(json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.daysDelinquentAsOnOnboarding != null) ? json.daysDelinquentAsOnOnboarding : 0,
(json.daysPastDue != null) ? json.daysPastDue : 0,
(json.details != null) ? json.details : undefined,
(json.ineligible != null) ? json.ineligible : undefined,
(json.exceptionTaken != null) ? json.exceptionTaken : undefined,
(json.emiAmount != null) ? json.emiAmount : 0,
(json.isSettled != null) ? json.isSettled : false,
(json.clCreditorId != null) ? json.clCreditorId : undefined,
(json.creditorMasterInfo != null) ? CreditorMasterInfo.fromJson(json.creditorMasterInfo) : undefined
        );
    }



  


   
    constructor(
        public stEntryId: string = '',
public settlementStatus: string = '',
public fiCreditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public daysDelinquentAsOnOnboarding: number = 0,
public daysPastDue: number = 0,
public details: string|undefined = undefined,
public ineligible: boolean|undefined = undefined,
public exceptionTaken: boolean|undefined = undefined,
public emiAmount: number = 0,
public isSettled: boolean = false,
public clCreditorId: string|undefined = undefined,
public creditorMasterInfo: CreditorMasterInfo|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          stEntryId: (this.stEntryId != null) ? this.stEntryId : undefined,
settlementStatus: (this.settlementStatus != null) ? this.settlementStatus : undefined,
fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
daysDelinquentAsOnOnboarding: (this.daysDelinquentAsOnOnboarding != null) ? this.daysDelinquentAsOnOnboarding : undefined,
daysPastDue: (this.daysPastDue != null) ? this.daysPastDue : undefined,
details: (this.details != null) ? this.details : undefined,
ineligible: (this.ineligible != null) ? this.ineligible : undefined,
exceptionTaken: (this.exceptionTaken != null) ? this.exceptionTaken : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
isSettled: (this.isSettled != null) ? this.isSettled : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
creditorMasterInfo: (this.creditorMasterInfo != null) ? this.creditorMasterInfo.toJson() : undefined
        };
    }
    



}



export class CreditorMasterInfo {

   
    public static fromJsonString(jsonString: string): CreditorMasterInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return CreditorMasterInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CreditorMasterInfo {
        const json: any = o ;
        return new CreditorMasterInfo(
         (json.creditorId != null) ? json.creditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.isServicedByFreed != null) ? json.isServicedByFreed : false
        );
    }



  


   
    constructor(
        public creditorId: string = '',
public creditorName: string = '',
public isServicedByFreed: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          creditorId: (this.creditorId != null) ? this.creditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
isServicedByFreed: (this.isServicedByFreed != null) ? this.isServicedByFreed : undefined
        };
    }
    



}



export class ClCreditor {

   
    public static fromJsonString(jsonString: string): ClCreditor {
       const jsonObj: object = JSON.parse(jsonString);
       return ClCreditor.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClCreditor {
        const json: any = o ;
        return new ClCreditor(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.creditorSearchStr != null) ? json.creditorSearchStr : ''
        );
    }



  


   
    constructor(
        public clCreditorId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public creditorSearchStr: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
creditorSearchStr: (this.creditorSearchStr != null) ? this.creditorSearchStr : undefined
        };
    }
    



}



export class IneligibleUnsecuredDebt {

   
    public static fromJsonString(jsonString: string): IneligibleUnsecuredDebt {
       const jsonObj: object = JSON.parse(jsonString);
       return IneligibleUnsecuredDebt.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): IneligibleUnsecuredDebt {
        const json: any = o ;
        return new IneligibleUnsecuredDebt(
         (json.personalLoanDebt != null) ? json.personalLoanDebt : 0,
(json.creditCardDebt != null) ? json.creditCardDebt : 0,
(json.total != null) ? json.total : 0
        );
    }



  


   
    constructor(
        public personalLoanDebt: number = 0,
public creditCardDebt: number = 0,
public total: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          personalLoanDebt: (this.personalLoanDebt != null) ? this.personalLoanDebt : undefined,
creditCardDebt: (this.creditCardDebt != null) ? this.creditCardDebt : undefined,
total: (this.total != null) ? this.total : undefined
        };
    }
    



}



export class FiCreditorInfo {

   
    public static fromJsonString(jsonString: string): FiCreditorInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return FiCreditorInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiCreditorInfo {
        const json: any = o ;
        return new FiCreditorInfo(
         (json.totalDebt != null) ? json.totalDebt : 0,
(json.fiCreditorList != null) ? (json.fiCreditorList as object[]).map((x) => FiCreditor.fromJson(x)) : [],
(json.totalUnsecuredDebt != null) ? json.totalUnsecuredDebt : 0,
(json.ineligibleUnsecuredDebt != null) ? IneligibleUnsecuredDebt.fromJson(json.ineligibleUnsecuredDebt) : new IneligibleUnsecuredDebt()
        );
    }



  


   
    constructor(
        public totalDebt: number = 0,
public fiCreditorList: FiCreditor[] = [],
public totalUnsecuredDebt: number = 0,
public ineligibleUnsecuredDebt: IneligibleUnsecuredDebt = new IneligibleUnsecuredDebt()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          totalDebt: (this.totalDebt != null) ? this.totalDebt : undefined,
fiCreditorList: (this.fiCreditorList != null) ? this.fiCreditorList.map((x) => x.toJson()) : [],
totalUnsecuredDebt: (this.totalUnsecuredDebt != null) ? this.totalUnsecuredDebt : undefined,
ineligibleUnsecuredDebt: (this.ineligibleUnsecuredDebt != null) ? this.ineligibleUnsecuredDebt.toJson() : undefined
        };
    }
    



}



export class AddIncludeFiCreditorInput {

   
    public static fromJsonString(jsonString: string): AddIncludeFiCreditorInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AddIncludeFiCreditorInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddIncludeFiCreditorInput {
        const json: any = o ;
        return new AddIncludeFiCreditorInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.clCreditorId != null) ? json.clCreditorId : undefined,
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : true
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public creditorName: string = '',
public clCreditorId: string|undefined = undefined,
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = true
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined
        };
    }
    



}



export class UpdateIncludeClCreditorInput {

   
    public static fromJsonString(jsonString: string): UpdateIncludeClCreditorInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateIncludeClCreditorInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateIncludeClCreditorInput {
        const json: any = o ;
        return new UpdateIncludeClCreditorInput(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : true,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }



  


   
    constructor(
        public clCreditorId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = true,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    



}



export class UpdateFiCreditorInput {

   
    public static fromJsonString(jsonString: string): UpdateFiCreditorInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateFiCreditorInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateFiCreditorInput {
        const json: any = o ;
        return new UpdateFiCreditorInput(
         (json.fiCreditorId != null) ? json.fiCreditorId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : undefined
        );
    }



  


   
    constructor(
        public fiCreditorId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiCreditorId: (this.fiCreditorId != null) ? this.fiCreditorId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined
        };
    }
    



}



export class RemoveClCreditorInput {

   
    public static fromJsonString(jsonString: string): RemoveClCreditorInput {
       const jsonObj: object = JSON.parse(jsonString);
       return RemoveClCreditorInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): RemoveClCreditorInput {
        const json: any = o ;
        return new RemoveClCreditorInput(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.reason != null) ? json.reason : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }



  


   
    constructor(
        public clCreditorId: string = '',
public reason: string|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
reason: (this.reason != null) ? this.reason : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    



}



export class UpdateClCreditorInput {

   
    public static fromJsonString(jsonString: string): UpdateClCreditorInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateClCreditorInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateClCreditorInput {
        const json: any = o ;
        return new UpdateClCreditorInput(
         (json.clCreditorId != null) ? json.clCreditorId : '',
(json.clientId != null) ? json.clientId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined
        );
    }



  


   
    constructor(
        public clCreditorId: string = '',
public clientId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: number|undefined = undefined,
public clCreditorStatus: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
clientId: (this.clientId != null) ? this.clientId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined
        };
    }
    



}



export class UpdateIncludeClCreditorForAmendmentForm {

   
    public static fromJsonString(jsonString: string): UpdateIncludeClCreditorForAmendmentForm {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateIncludeClCreditorForAmendmentForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateIncludeClCreditorForAmendmentForm {
        const json: any = o ;
        return new UpdateIncludeClCreditorForAmendmentForm(
         (json.amendmentToken != null) ? json.amendmentToken : '',
(json.clCreditorId != null) ? json.clCreditorId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.creditorName != null) ? json.creditorName : '',
(json.creditorBalance != null) ? json.creditorBalance : 0,
(json.lastDateOfPayment != null) ? json.lastDateOfPayment : '',
(json.debtType != null) ? json.debtType : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.details != null) ? json.details : undefined,
(json.emiAmount != null) ? json.emiAmount : undefined,
(json.clCreditorStatus != null) ? json.clCreditorStatus : undefined,
(json.skipUnderwrittingRule != null) ? json.skipUnderwrittingRule : true,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }



  


   
    constructor(
        public amendmentToken: string = '',
public clCreditorId: string = '',
public clientFileId: string = '',
public creditorName: string = '',
public creditorBalance: number = 0,
public lastDateOfPayment: string = '',
public debtType: string = '',
public accountNumber: string = '',
public details: string|undefined = undefined,
public emiAmount: string|undefined = undefined,
public clCreditorStatus: string|undefined = undefined,
public skipUnderwrittingRule: boolean|undefined = true,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          amendmentToken: (this.amendmentToken != null) ? this.amendmentToken : undefined,
clCreditorId: (this.clCreditorId != null) ? this.clCreditorId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditorName: (this.creditorName != null) ? this.creditorName : undefined,
creditorBalance: (this.creditorBalance != null) ? this.creditorBalance : undefined,
lastDateOfPayment: (this.lastDateOfPayment != null) ? this.lastDateOfPayment : undefined,
debtType: (this.debtType != null) ? this.debtType : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
details: (this.details != null) ? this.details : undefined,
emiAmount: (this.emiAmount != null) ? this.emiAmount : undefined,
clCreditorStatus: (this.clCreditorStatus != null) ? this.clCreditorStatus : undefined,
skipUnderwrittingRule: (this.skipUnderwrittingRule != null) ? this.skipUnderwrittingRule : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    



}



export class UpdateCreditInfoInput {

   
    public static fromJsonString(jsonString: string): UpdateCreditInfoInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateCreditInfoInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateCreditInfoInput {
        const json: any = o ;
        return new UpdateCreditInfoInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.creditScore != null) ? json.creditScore : 0,
(json.creditBureau != null) ? json.creditBureau : ''
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public creditScore: number = 0,
public creditBureau: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
creditScore: (this.creditScore != null) ? this.creditScore : undefined,
creditBureau: (this.creditBureau != null) ? this.creditBureau : undefined
        };
    }
    



}



export class UpdateBudgetInfoInput {

   
    public static fromJsonString(jsonString: string): UpdateBudgetInfoInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateBudgetInfoInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateBudgetInfoInput {
        const json: any = o ;
        return new UpdateBudgetInfoInput(
         (json.taskId != null) ? json.taskId : '',
(json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.hardshipReason != null) ? json.hardshipReason : '',
(json.hardshipReasonCodeList != null) ? json.hardshipReasonCodeList : [],
(json.incomeSources != null) ? BudgetIncomeSources.fromJson(json.incomeSources) : new BudgetIncomeSources(),
(json.debtRepayments != null) ? BudgetDebtRepayments.fromJson(json.debtRepayments) : new BudgetDebtRepayments(),
(json.livingExpenses != null) ? BudgetLivingExpenses.fromJson(json.livingExpenses) : new BudgetLivingExpenses(),
(json.lifeStyleExpenses != null) ? BudgetLifeStyleExpenses.fromJson(json.lifeStyleExpenses) : new BudgetLifeStyleExpenses(),
(json.dependentExpenses != null) ? BudgetDependentExpenses.fromJson(json.dependentExpenses) : new BudgetDependentExpenses(),
(json.incidentalExpenses != null) ? BudgetIncidentalExpenses.fromJson(json.incidentalExpenses) : new BudgetIncidentalExpenses(),
(json.miscellaneousExpenses != null) ? BudgetMiscellaneousExpenses.fromJson(json.miscellaneousExpenses) : new BudgetMiscellaneousExpenses(),
(json.totalMonthlyExpense != null) ? json.totalMonthlyExpense : 0,
(json.availableIncome != null) ? json.availableIncome : 0,
(json.proposedDSPayment != null) ? json.proposedDSPayment : 0,
(json.stdiPercentage != null) ? json.stdiPercentage : 0,
(json.totalIncome != null) ? json.totalIncome : 0,
(json.totalDebtRepayments != null) ? json.totalDebtRepayments : 0,
(json.hasDependents != null) ? json.hasDependents : undefined,
(json.hasPets != null) ? json.hasPets : undefined,
(json.maritalStatus != null) ? json.maritalStatus : undefined,
(json.hasKids != null) ? json.hasKids : undefined,
(json.rentedHouse != null) ? json.rentedHouse : undefined,
(json.employmentStatus != null) ? json.employmentStatus : undefined,
(json.employeeCompanyName != null) ? json.employeeCompanyName : undefined,
(json.age != null) ? json.age : undefined,
(json.fullName != null) ? json.fullName : undefined,
(json.gender != null) ? json.gender : undefined
        );
    }



  


   
    constructor(
        public taskId: string = '',
public clientFileId: string = '',
public clientFileNumber: string = '',
public hardshipReason: string = '',
public hardshipReasonCodeList: string[] = [],
public incomeSources: BudgetIncomeSources = new BudgetIncomeSources(),
public debtRepayments: BudgetDebtRepayments = new BudgetDebtRepayments(),
public livingExpenses: BudgetLivingExpenses = new BudgetLivingExpenses(),
public lifeStyleExpenses: BudgetLifeStyleExpenses = new BudgetLifeStyleExpenses(),
public dependentExpenses: BudgetDependentExpenses = new BudgetDependentExpenses(),
public incidentalExpenses: BudgetIncidentalExpenses = new BudgetIncidentalExpenses(),
public miscellaneousExpenses: BudgetMiscellaneousExpenses = new BudgetMiscellaneousExpenses(),
public totalMonthlyExpense: number = 0,
public availableIncome: number = 0,
public proposedDSPayment: number = 0,
public stdiPercentage: number = 0,
public totalIncome: number = 0,
public totalDebtRepayments: number = 0,
public hasDependents: boolean|undefined = undefined,
public hasPets: boolean|undefined = undefined,
public maritalStatus: string|undefined = undefined,
public hasKids: boolean|undefined = undefined,
public rentedHouse: string|undefined = undefined,
public employmentStatus: string|undefined = undefined,
public employeeCompanyName: string|undefined = undefined,
public age: number|undefined = undefined,
public fullName: string|undefined = undefined,
public gender: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
hardshipReason: (this.hardshipReason != null) ? this.hardshipReason : undefined,
hardshipReasonCodeList: (this.hardshipReasonCodeList != null) ? this.hardshipReasonCodeList : [],
incomeSources: (this.incomeSources != null) ? this.incomeSources.toJson() : undefined,
debtRepayments: (this.debtRepayments != null) ? this.debtRepayments.toJson() : undefined,
livingExpenses: (this.livingExpenses != null) ? this.livingExpenses.toJson() : undefined,
lifeStyleExpenses: (this.lifeStyleExpenses != null) ? this.lifeStyleExpenses.toJson() : undefined,
dependentExpenses: (this.dependentExpenses != null) ? this.dependentExpenses.toJson() : undefined,
incidentalExpenses: (this.incidentalExpenses != null) ? this.incidentalExpenses.toJson() : undefined,
miscellaneousExpenses: (this.miscellaneousExpenses != null) ? this.miscellaneousExpenses.toJson() : undefined,
totalMonthlyExpense: (this.totalMonthlyExpense != null) ? this.totalMonthlyExpense : undefined,
availableIncome: (this.availableIncome != null) ? this.availableIncome : undefined,
proposedDSPayment: (this.proposedDSPayment != null) ? this.proposedDSPayment : undefined,
stdiPercentage: (this.stdiPercentage != null) ? this.stdiPercentage : undefined,
totalIncome: (this.totalIncome != null) ? this.totalIncome : undefined,
totalDebtRepayments: (this.totalDebtRepayments != null) ? this.totalDebtRepayments : undefined,
hasDependents: (this.hasDependents != null) ? this.hasDependents : undefined,
hasPets: (this.hasPets != null) ? this.hasPets : undefined,
maritalStatus: (this.maritalStatus != null) ? this.maritalStatus : undefined,
hasKids: (this.hasKids != null) ? this.hasKids : undefined,
rentedHouse: (this.rentedHouse != null) ? this.rentedHouse : undefined,
employmentStatus: (this.employmentStatus != null) ? this.employmentStatus : undefined,
employeeCompanyName: (this.employeeCompanyName != null) ? this.employeeCompanyName : undefined,
age: (this.age != null) ? this.age : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
gender: (this.gender != null) ? this.gender : undefined
        };
    }
    



}



export class DraftPSPlanForPMInput {

   
    public static fromJsonString(jsonString: string): DraftPSPlanForPMInput {
       const jsonObj: object = JSON.parse(jsonString);
       return DraftPSPlanForPMInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DraftPSPlanForPMInput {
        const json: any = o ;
        return new DraftPSPlanForPMInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.ppCode != null) ? json.ppCode : 'PM',
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 45,
(json.settlementFee != null) ? json.settlementFee : undefined,
(json.spaFirstDraftDate != null) ? json.spaFirstDraftDate : '',
(json.msfFirstDraftDate != null) ? json.msfFirstDraftDate : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public ppCode: string = 'PM',
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 45,
public settlementFee: number|undefined = undefined,
public spaFirstDraftDate: string = '',
public msfFirstDraftDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
ppCode: (this.ppCode != null) ? this.ppCode : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
settlementFee: (this.settlementFee != null) ? this.settlementFee : undefined,
spaFirstDraftDate: (this.spaFirstDraftDate != null) ? this.spaFirstDraftDate : undefined,
msfFirstDraftDate: (this.msfFirstDraftDate != null) ? this.msfFirstDraftDate : undefined
        };
    }
    



}



export class AddPSEntryInput {

   
    public static fromJsonString(jsonString: string): AddPSEntryInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AddPSEntryInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddPSEntryInput {
        const json: any = o ;
        return new AddPSEntryInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.paymentProvider != null) ? json.paymentProvider : '',
(json.paymentMode != null) ? json.paymentMode : '',
(json.eMandateId != null) ? json.eMandateId : undefined,
(json.draftDate != null) ? json.draftDate : '',
(json.spaAmount != null) ? json.spaAmount : 0,
(json.feeAmount != null) ? json.feeAmount : 0,
(json.psPlanId != null) ? json.psPlanId : ''
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public paymentProvider: string = '',
public paymentMode: string = '',
public eMandateId: string|undefined = undefined,
public draftDate: string = '',
public spaAmount: number = 0,
public feeAmount: number = 0,
public psPlanId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
paymentProvider: (this.paymentProvider != null) ? this.paymentProvider : undefined,
paymentMode: (this.paymentMode != null) ? this.paymentMode : undefined,
eMandateId: (this.eMandateId != null) ? this.eMandateId : undefined,
draftDate: (this.draftDate != null) ? this.draftDate : undefined,
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined,
psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined
        };
    }
    



}



export class RecalculatePSPlanForPMInput {

   
    public static fromJsonString(jsonString: string): RecalculatePSPlanForPMInput {
       const jsonObj: object = JSON.parse(jsonString);
       return RecalculatePSPlanForPMInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): RecalculatePSPlanForPMInput {
        const json: any = o ;
        return new RecalculatePSPlanForPMInput(
         (json.psPlanId != null) ? json.psPlanId : '',
(json.outstanding != null) ? json.outstanding : 0,
(json.tenor != null) ? json.tenor : 0,
(json.settlementPercentage != null) ? json.settlementPercentage : 0,
(json.settlementFee != null) ? json.settlementFee : undefined,
(json.spaFirstDraftDate != null) ? json.spaFirstDraftDate : '',
(json.msfFirstDraftDate != null) ? json.msfFirstDraftDate : undefined
        );
    }



  


   
    constructor(
        public psPlanId: string = '',
public outstanding: number = 0,
public tenor: number = 0,
public settlementPercentage: number = 0,
public settlementFee: number|undefined = undefined,
public spaFirstDraftDate: string = '',
public msfFirstDraftDate: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psPlanId: (this.psPlanId != null) ? this.psPlanId : undefined,
outstanding: (this.outstanding != null) ? this.outstanding : undefined,
tenor: (this.tenor != null) ? this.tenor : undefined,
settlementPercentage: (this.settlementPercentage != null) ? this.settlementPercentage : undefined,
settlementFee: (this.settlementFee != null) ? this.settlementFee : undefined,
spaFirstDraftDate: (this.spaFirstDraftDate != null) ? this.spaFirstDraftDate : undefined,
msfFirstDraftDate: (this.msfFirstDraftDate != null) ? this.msfFirstDraftDate : undefined
        };
    }
    



}



export class CancelPaymentInput {

   
    public static fromJsonString(jsonString: string): CancelPaymentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return CancelPaymentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CancelPaymentInput {
        const json: any = o ;
        return new CancelPaymentInput(
         (json.paymentId != null) ? json.paymentId : '',
(json.reason != null) ? json.reason : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }



  


   
    constructor(
        public paymentId: string = '',
public reason: string|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          paymentId: (this.paymentId != null) ? this.paymentId : undefined,
reason: (this.reason != null) ? this.reason : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    



}



export class PresentPSEntryInput {

   
    public static fromJsonString(jsonString: string): PresentPSEntryInput {
       const jsonObj: object = JSON.parse(jsonString);
       return PresentPSEntryInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PresentPSEntryInput {
        const json: any = o ;
        return new PresentPSEntryInput(
         (json.psEntryId != null) ? json.psEntryId : ''
        );
    }



  


   
    constructor(
        public psEntryId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined
        };
    }
    



}



export class ModifyAmountWithFixedTenureInput {

   
    public static fromJsonString(jsonString: string): ModifyAmountWithFixedTenureInput {
       const jsonObj: object = JSON.parse(jsonString);
       return ModifyAmountWithFixedTenureInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ModifyAmountWithFixedTenureInput {
        const json: any = o ;
        return new ModifyAmountWithFixedTenureInput(
         (json.psEntryIdList != null) ? json.psEntryIdList : [],
(json.spaAmount != null) ? json.spaAmount : 0,
(json.feeAmount != null) ? json.feeAmount : 0
        );
    }



  


   
    constructor(
        public psEntryIdList: string[] = [],
public spaAmount: number = 0,
public feeAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryIdList: (this.psEntryIdList != null) ? this.psEntryIdList : [],
spaAmount: (this.spaAmount != null) ? this.spaAmount : undefined,
feeAmount: (this.feeAmount != null) ? this.feeAmount : undefined
        };
    }
    



}



export class FiPaymentTransaction {

   
    public static fromJsonString(jsonString: string): FiPaymentTransaction {
       const jsonObj: object = JSON.parse(jsonString);
       return FiPaymentTransaction.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiPaymentTransaction {
        const json: any = o ;
        return new FiPaymentTransaction(
         (json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.accountIdentifier != null) ? json.accountIdentifier : '',
(json.debit != null) ? json.debit : false,
(json.amount != null) ? json.amount : 0,
(json.paymentRefNumber != null) ? json.paymentRefNumber : undefined,
(json.remoteTxnRefNumber != null) ? json.remoteTxnRefNumber : undefined,
(json.txnDate != null) ? json.txnDate : '',
(json.intent != null) ? json.intent : '',
(json.description != null) ? json.description : undefined
        );
    }



  


   
    constructor(
        public clientFileNumber: string = '',
public accountIdentifier: string = '',
public debit: boolean = false,
public amount: number = 0,
public paymentRefNumber: string|undefined = undefined,
public remoteTxnRefNumber: string|undefined = undefined,
public txnDate: string = '',
public intent: string = '',
public description: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
accountIdentifier: (this.accountIdentifier != null) ? this.accountIdentifier : undefined,
debit: (this.debit != null) ? this.debit : undefined,
amount: (this.amount != null) ? this.amount : undefined,
paymentRefNumber: (this.paymentRefNumber != null) ? this.paymentRefNumber : undefined,
remoteTxnRefNumber: (this.remoteTxnRefNumber != null) ? this.remoteTxnRefNumber : undefined,
txnDate: (this.txnDate != null) ? this.txnDate : undefined,
intent: (this.intent != null) ? this.intent : undefined,
description: (this.description != null) ? this.description : undefined
        };
    }
    



}



export class QrCodeStatus {

   
    public static fromJsonString(jsonString: string): QrCodeStatus {
       const jsonObj: object = JSON.parse(jsonString);
       return QrCodeStatus.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): QrCodeStatus {
        const json: any = o ;
        return new QrCodeStatus(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.amount != null) ? json.amount : 0,
(json.s3Url != null) ? json.s3Url : '',
(json.createdDate != null) ? json.createdDate : '',
(json.status != null) ? QRCODE_STATUS.fromJson(json.status) : QRCODE_STATUS.DEFAULT_VALUE
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public amount: number = 0,
public s3Url: string = '',
public createdDate: string = '',
public status: QRCODE_STATUS = QRCODE_STATUS.DEFAULT_VALUE
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
amount: (this.amount != null) ? this.amount : undefined,
s3Url: (this.s3Url != null) ? this.s3Url : undefined,
createdDate: (this.createdDate != null) ? this.createdDate : undefined,
status: (this.status != null) ?  QRCODE_STATUS.copyMe(this.status).toJson() : undefined
        };
    }
    



}



export class GetQrCodeStatusListForClientOutput {

   
    public static fromJsonString(jsonString: string): GetQrCodeStatusListForClientOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetQrCodeStatusListForClientOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetQrCodeStatusListForClientOutput {
        const json: any = o ;
        return new GetQrCodeStatusListForClientOutput(
         (json.qrCodeStatusList != null) ? (json.qrCodeStatusList as object[]).map((x) => QrCodeStatus.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public qrCodeStatusList: QrCodeStatus[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          qrCodeStatusList: (this.qrCodeStatusList != null) ? this.qrCodeStatusList.map((x) => x.toJson()) : []
        };
    }
    



}



export class GenerateQrCodeForSpaPaymentInput {

   
    public static fromJsonString(jsonString: string): GenerateQrCodeForSpaPaymentInput {
       const jsonObj: object = JSON.parse(jsonString);
       return GenerateQrCodeForSpaPaymentInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GenerateQrCodeForSpaPaymentInput {
        const json: any = o ;
        return new GenerateQrCodeForSpaPaymentInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.amount != null) ? json.amount : 0
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public amount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
amount: (this.amount != null) ? this.amount : undefined
        };
    }
    



}



export class PaymentTransaction {

   
    public static fromJsonString(jsonString: string): PaymentTransaction {
       const jsonObj: object = JSON.parse(jsonString);
       return PaymentTransaction.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): PaymentTransaction {
        const json: any = o ;
        return new PaymentTransaction(
         (json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.accountIdentifier != null) ? json.accountIdentifier : '',
(json.debit != null) ? json.debit : false,
(json.amount != null) ? json.amount : 0,
(json.paymentRefNumber != null) ? json.paymentRefNumber : undefined,
(json.remoteTxnRefNumber != null) ? json.remoteTxnRefNumber : undefined,
(json.txnDate != null) ? json.txnDate : '',
(json.intent != null) ? json.intent : '',
(json.description != null) ? json.description : undefined
        );
    }



  


   
    constructor(
        public clientFileNumber: string = '',
public accountIdentifier: string = '',
public debit: boolean = false,
public amount: number = 0,
public paymentRefNumber: string|undefined = undefined,
public remoteTxnRefNumber: string|undefined = undefined,
public txnDate: string = '',
public intent: string = '',
public description: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
accountIdentifier: (this.accountIdentifier != null) ? this.accountIdentifier : undefined,
debit: (this.debit != null) ? this.debit : undefined,
amount: (this.amount != null) ? this.amount : undefined,
paymentRefNumber: (this.paymentRefNumber != null) ? this.paymentRefNumber : undefined,
remoteTxnRefNumber: (this.remoteTxnRefNumber != null) ? this.remoteTxnRefNumber : undefined,
txnDate: (this.txnDate != null) ? this.txnDate : undefined,
intent: (this.intent != null) ? this.intent : undefined,
description: (this.description != null) ? this.description : undefined
        };
    }
    



}



export class Address {

   
    public static fromJsonString(jsonString: string): Address {
       const jsonObj: object = JSON.parse(jsonString);
       return Address.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Address {
        const json: any = o ;
        return new Address(
         (json.addressLine1 != null) ? json.addressLine1 : '',
(json.city != null) ? json.city : '',
(json.state != null) ? json.state : '',
(json.country != null) ? json.country : 'India',
(json.pinCode != null) ? json.pinCode : ''
        );
    }



  


   
    constructor(
        public addressLine1: string = '',
public city: string = '',
public state: string = '',
public country: string = 'India',
public pinCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          addressLine1: (this.addressLine1 != null) ? this.addressLine1 : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined,
country: (this.country != null) ? this.country : undefined,
pinCode: (this.pinCode != null) ? this.pinCode : undefined
        };
    }
    



}



export class UpdateBankInfoInput {

   
    public static fromJsonString(jsonString: string): UpdateBankInfoInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateBankInfoInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateBankInfoInput {
        const json: any = o ;
        return new UpdateBankInfoInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.accountNumber != null) ? json.accountNumber : '',
(json.ifscCode != null) ? json.ifscCode : '',
(json.accountType != null) ? json.accountType : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.bankAddress != null) ? Address.fromJson(json.bankAddress) : new Address(),
(json.nupayBankMasterId != null) ? json.nupayBankMasterId : ''
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public accountNumber: string = '',
public ifscCode: string = '',
public accountType: string = '',
public accountHolderName: string = '',
public bankAddress: Address = new Address(),
public nupayBankMasterId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined,
accountType: (this.accountType != null) ? this.accountType : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
bankAddress: (this.bankAddress != null) ? this.bankAddress.toJson() : undefined,
nupayBankMasterId: (this.nupayBankMasterId != null) ? this.nupayBankMasterId : undefined
        };
    }
    



}



export class SkipInput {

   
    public static fromJsonString(jsonString: string): SkipInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SkipInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SkipInput {
        const json: any = o ;
        return new SkipInput(
         (json.psEntryId != null) ? json.psEntryId : ''
        );
    }



  


   
    constructor(
        public psEntryId: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          psEntryId: (this.psEntryId != null) ? this.psEntryId : undefined
        };
    }
    



}



export class DraftFirstMSFThroughCashfreeInput {

   
    public static fromJsonString(jsonString: string): DraftFirstMSFThroughCashfreeInput {
       const jsonObj: object = JSON.parse(jsonString);
       return DraftFirstMSFThroughCashfreeInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): DraftFirstMSFThroughCashfreeInput {
        const json: any = o ;
        return new DraftFirstMSFThroughCashfreeInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.msfAmount != null) ? json.msfAmount : undefined,
(json.whoDidIt != null) ? json.whoDidIt : undefined
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public msfAmount: number|undefined = undefined,
public whoDidIt: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
msfAmount: (this.msfAmount != null) ? this.msfAmount : undefined,
whoDidIt: (this.whoDidIt != null) ? this.whoDidIt : undefined
        };
    }
    



}



export class FiSSASummary {

   
    public static fromJsonString(jsonString: string): FiSSASummary {
       const jsonObj: object = JSON.parse(jsonString);
       return FiSSASummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiSSASummary {
        const json: any = o ;
        return new FiSSASummary(
         (json.clientFile != null) ? ClientFileBasicInfo.fromJson(json.clientFile) : new ClientFileBasicInfo(),
(json.ssaToken != null) ? json.ssaToken : '',
(json.ipAddr != null) ? json.ipAddr : undefined,
(json.signed != null) ? json.signed : false,
(json.signedOn != null) ? json.signedOn : undefined,
(json.generatedOn != null) ? json.generatedOn : '',
(json.agreementUrl != null) ? json.agreementUrl : '',
(json.status != null) ? AGREEMENT_STATUS.fromJson(json.status) : AGREEMENT_STATUS.DEFAULT_VALUE,
(json.previewUrl != null) ? json.previewUrl : ''
        );
    }



  


   
    constructor(
        public clientFile: ClientFileBasicInfo = new ClientFileBasicInfo(),
public ssaToken: string = '',
public ipAddr: string|undefined = undefined,
public signed: boolean = false,
public signedOn: string|undefined = undefined,
public generatedOn: string = '',
public agreementUrl: string = '',
public status: AGREEMENT_STATUS = AGREEMENT_STATUS.DEFAULT_VALUE,
public previewUrl: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFile: (this.clientFile != null) ? this.clientFile.toJson() : undefined,
ssaToken: (this.ssaToken != null) ? this.ssaToken : undefined,
ipAddr: (this.ipAddr != null) ? this.ipAddr : undefined,
signed: (this.signed != null) ? this.signed : undefined,
signedOn: (this.signedOn != null) ? this.signedOn : undefined,
generatedOn: (this.generatedOn != null) ? this.generatedOn : undefined,
agreementUrl: (this.agreementUrl != null) ? this.agreementUrl : undefined,
status: (this.status != null) ?  AGREEMENT_STATUS.copyMe(this.status).toJson() : undefined,
previewUrl: (this.previewUrl != null) ? this.previewUrl : undefined
        };
    }
    



}



export class SendAgreementInput {

   
    public static fromJsonString(jsonString: string): SendAgreementInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SendAgreementInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SendAgreementInput {
        const json: any = o ;
        return new SendAgreementInput(
         (json.token != null) ? json.token : '',
(json.byEmail != null) ? json.byEmail : false,
(json.byWhatsapp != null) ? json.byWhatsapp : false,
(json.bySMS != null) ? json.bySMS : false
        );
    }



  


   
    constructor(
        public token: string = '',
public byEmail: boolean = false,
public byWhatsapp: boolean = false,
public bySMS: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          token: (this.token != null) ? this.token : undefined,
byEmail: (this.byEmail != null) ? this.byEmail : undefined,
byWhatsapp: (this.byWhatsapp != null) ? this.byWhatsapp : undefined,
bySMS: (this.bySMS != null) ? this.bySMS : undefined
        };
    }
    



}



export class EMandateBankInfo {

   
    public static fromJsonString(jsonString: string): EMandateBankInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return EMandateBankInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): EMandateBankInfo {
        const json: any = o ;
        return new EMandateBankInfo(
         (json.nupayBankId != null) ? json.nupayBankId : 0,
(json.accountNumber != null) ? json.accountNumber : '',
(json.accountHolderName != null) ? json.accountHolderName : '',
(json.accountType != null) ? ACCOUNT_TYPE.fromJson(json.accountType) : ACCOUNT_TYPE.DEFAULT_VALUE,
(json.ifscCode != null) ? json.ifscCode : ''
        );
    }



  


   
    constructor(
        public nupayBankId: number = 0,
public accountNumber: string = '',
public accountHolderName: string = '',
public accountType: ACCOUNT_TYPE = ACCOUNT_TYPE.DEFAULT_VALUE,
public ifscCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nupayBankId: (this.nupayBankId != null) ? this.nupayBankId : undefined,
accountNumber: (this.accountNumber != null) ? this.accountNumber : undefined,
accountHolderName: (this.accountHolderName != null) ? this.accountHolderName : undefined,
accountType: (this.accountType != null) ?  ACCOUNT_TYPE.copyMe(this.accountType).toJson() : undefined,
ifscCode: (this.ifscCode != null) ? this.ifscCode : undefined
        };
    }
    



}



export class InitiateEMandateInput {

   
    public static fromJsonString(jsonString: string): InitiateEMandateInput {
       const jsonObj: object = JSON.parse(jsonString);
       return InitiateEMandateInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): InitiateEMandateInput {
        const json: any = o ;
        return new InitiateEMandateInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientBankInfo != null) ? EMandateBankInfo.fromJson(json.clientBankInfo) : new EMandateBankInfo(),
(json.collectionAmount != null) ? json.collectionAmount : 0
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientBankInfo: EMandateBankInfo = new EMandateBankInfo(),
public collectionAmount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientBankInfo: (this.clientBankInfo != null) ? this.clientBankInfo.toJson() : undefined,
collectionAmount: (this.collectionAmount != null) ? this.collectionAmount : undefined
        };
    }
    



}



export class SendEmandateInput {

   
    public static fromJsonString(jsonString: string): SendEmandateInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SendEmandateInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SendEmandateInput {
        const json: any = o ;
        return new SendEmandateInput(
         (json.fiEMandateId != null) ? json.fiEMandateId : '',
(json.byEmail != null) ? json.byEmail : false,
(json.byWhatsapp != null) ? json.byWhatsapp : false,
(json.bySMS != null) ? json.bySMS : false
        );
    }



  


   
    constructor(
        public fiEMandateId: string = '',
public byEmail: boolean = false,
public byWhatsapp: boolean = false,
public bySMS: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiEMandateId: (this.fiEMandateId != null) ? this.fiEMandateId : undefined,
byEmail: (this.byEmail != null) ? this.byEmail : undefined,
byWhatsapp: (this.byWhatsapp != null) ? this.byWhatsapp : undefined,
bySMS: (this.bySMS != null) ? this.bySMS : undefined
        };
    }
    



}



export class UpdateExceptionTakenListInput {

   
    public static fromJsonString(jsonString: string): UpdateExceptionTakenListInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateExceptionTakenListInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateExceptionTakenListInput {
        const json: any = o ;
        return new UpdateExceptionTakenListInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.exceptionTakenList != null) ? json.exceptionTakenList : [],
(json.exceptionApprovedBy != null) ? json.exceptionApprovedBy : ''
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public exceptionTakenList: string[] = [],
public exceptionApprovedBy: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
exceptionTakenList: (this.exceptionTakenList != null) ? this.exceptionTakenList : [],
exceptionApprovedBy: (this.exceptionApprovedBy != null) ? this.exceptionApprovedBy : undefined
        };
    }
    



}



export class GetExceptionTakenListOutput {

   
    public static fromJsonString(jsonString: string): GetExceptionTakenListOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return GetExceptionTakenListOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetExceptionTakenListOutput {
        const json: any = o ;
        return new GetExceptionTakenListOutput(
         (json.exceptionTakenList != null) ? json.exceptionTakenList : [],
(json.exceptionApprovedBy != null) ? json.exceptionApprovedBy : undefined
        );
    }



  


   
    constructor(
        public exceptionTakenList: string[] = [],
public exceptionApprovedBy: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          exceptionTakenList: (this.exceptionTakenList != null) ? this.exceptionTakenList : [],
exceptionApprovedBy: (this.exceptionApprovedBy != null) ? this.exceptionApprovedBy : undefined
        };
    }
    



}



}



export namespace Color {
  

export class CLIENT_FILE_STATUS {

     public static get HOLD() { return new CLIENT_FILE_STATUS('HOLD', 'grey'); } 
public static get NO_SERVICE() { return new CLIENT_FILE_STATUS('NO_SERVICE', 'grey'); } 
public static get GRADUATED() { return new CLIENT_FILE_STATUS('GRADUATED', 'green'); } 
public static get AGREEMENT_SIGNED() { return new CLIENT_FILE_STATUS('AGREEMENT_SIGNED', 'green'); } 
public static get ACTIVE() { return new CLIENT_FILE_STATUS('ACTIVE', 'green'); } 
public static get CANCELLED() { return new CLIENT_FILE_STATUS('CANCELLED', 'grey'); } 
public static get ENROLLED() { return new CLIENT_FILE_STATUS('ENROLLED', 'primary'); } 
public static get PENDING_CANCELLED() { return new CLIENT_FILE_STATUS('PENDING_CANCELLED', 'grey'); } 
public static get REJECTED() { return new CLIENT_FILE_STATUS('REJECTED', 'red'); } 
public static get LEAD() { return new CLIENT_FILE_STATUS('LEAD', 'secondary'); } 

     public static get DEFAULT_VALUE() { return CLIENT_FILE_STATUS.LEAD; }

     public static withIdOption(id: string): CLIENT_FILE_STATUS| undefined  {
        try {
            return CLIENT_FILE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CLIENT_FILE_STATUS.withIdOption(id) !== undefined; }
    public static list(): CLIENT_FILE_STATUS[]  { return [CLIENT_FILE_STATUS.CANCELLED, CLIENT_FILE_STATUS.ENROLLED, CLIENT_FILE_STATUS.ACTIVE, CLIENT_FILE_STATUS.REJECTED, CLIENT_FILE_STATUS.NO_SERVICE, CLIENT_FILE_STATUS.PENDING_CANCELLED, CLIENT_FILE_STATUS.GRADUATED, CLIENT_FILE_STATUS.AGREEMENT_SIGNED, CLIENT_FILE_STATUS.LEAD, CLIENT_FILE_STATUS.HOLD]; }
    public static idList(): string[]  { return ['LEAD', 'REJECTED', 'NO_SERVICE', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'ENROLLED']; }
    public static nameList(): string[]  { return ['secondary', 'green', 'grey', 'red', 'primary']; }


    public static withId(id: string): CLIENT_FILE_STATUS  {
        switch (id) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'REJECTED': return CLIENT_FILE_STATUS.REJECTED; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'ENROLLED': return CLIENT_FILE_STATUS.ENROLLED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'NO_SERVICE': return CLIENT_FILE_STATUS.NO_SERVICE; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + id);
        }
    }

    public static withName(name: string): CLIENT_FILE_STATUS  {
        switch (name) {
            case 'green': return CLIENT_FILE_STATUS.GRADUATED; 
case 'red': return CLIENT_FILE_STATUS.REJECTED; 
case 'grey': return CLIENT_FILE_STATUS.NO_SERVICE; 
case 'grey': return CLIENT_FILE_STATUS.HOLD; 
case 'green': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'secondary': return CLIENT_FILE_STATUS.LEAD; 
case 'grey': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'green': return CLIENT_FILE_STATUS.ACTIVE; 
case 'primary': return CLIENT_FILE_STATUS.ENROLLED; 
case 'grey': return CLIENT_FILE_STATUS.CANCELLED; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + name);
        }
    }

    public static copyMe(given: CLIENT_FILE_STATUS): CLIENT_FILE_STATUS  {
            return CLIENT_FILE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): CLIENT_FILE_STATUS { return CLIENT_FILE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CLIENT_FILE_STATUS.list().indexOf(this); }
}
 

export class FICREDITOR_STATUS {

     public static get PARTIALLY_SETTLED() { return new FICREDITOR_STATUS('PARTIALLY_SETTLED', 'green'); } 
public static get DRAFT() { return new FICREDITOR_STATUS('DRAFT', 'default'); } 
public static get ACTIVE() { return new FICREDITOR_STATUS('ACTIVE', 'primary'); } 
public static get SETTLEMENT_PLANNED() { return new FICREDITOR_STATUS('SETTLEMENT_PLANNED', 'primary'); } 
public static get SETTLED() { return new FICREDITOR_STATUS('SETTLED', 'green'); } 
public static get ARCHIVED() { return new FICREDITOR_STATUS('ARCHIVED', 'red'); } 

     public static get DEFAULT_VALUE() { return FICREDITOR_STATUS.DRAFT; }

     public static withIdOption(id: string): FICREDITOR_STATUS| undefined  {
        try {
            return FICREDITOR_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return FICREDITOR_STATUS.withIdOption(id) !== undefined; }
    public static list(): FICREDITOR_STATUS[]  { return [FICREDITOR_STATUS.ACTIVE, FICREDITOR_STATUS.SETTLEMENT_PLANNED, FICREDITOR_STATUS.SETTLED, FICREDITOR_STATUS.ARCHIVED, FICREDITOR_STATUS.DRAFT, FICREDITOR_STATUS.PARTIALLY_SETTLED]; }
    public static idList(): string[]  { return ['SETTLEMENT_PLANNED', 'PARTIALLY_SETTLED', 'ARCHIVED', 'ACTIVE', 'SETTLED', 'DRAFT']; }
    public static nameList(): string[]  { return ['default', 'green', 'primary', 'red']; }


    public static withId(id: string): FICREDITOR_STATUS  {
        switch (id) {
            case 'SETTLEMENT_PLANNED': return FICREDITOR_STATUS.SETTLEMENT_PLANNED; 
case 'SETTLED': return FICREDITOR_STATUS.SETTLED; 
case 'ACTIVE': return FICREDITOR_STATUS.ACTIVE; 
case 'PARTIALLY_SETTLED': return FICREDITOR_STATUS.PARTIALLY_SETTLED; 
case 'ARCHIVED': return FICREDITOR_STATUS.ARCHIVED; 
case 'DRAFT': return FICREDITOR_STATUS.DRAFT; 
            default: throw new Error('Invalid id for FICREDITOR_STATUS: ' + id);
        }
    }

    public static withName(name: string): FICREDITOR_STATUS  {
        switch (name) {
            case 'primary': return FICREDITOR_STATUS.SETTLEMENT_PLANNED; 
case 'green': return FICREDITOR_STATUS.PARTIALLY_SETTLED; 
case 'green': return FICREDITOR_STATUS.SETTLED; 
case 'red': return FICREDITOR_STATUS.ARCHIVED; 
case 'default': return FICREDITOR_STATUS.DRAFT; 
case 'primary': return FICREDITOR_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for FICREDITOR_STATUS: ' + name);
        }
    }

    public static copyMe(given: FICREDITOR_STATUS): FICREDITOR_STATUS  {
            return FICREDITOR_STATUS.withId(given.id)
    }

    public static fromJson(json: string): FICREDITOR_STATUS { return FICREDITOR_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return FICREDITOR_STATUS.list().indexOf(this); }
}
 

export class PS_ENTRY_STATUS {

     public static get PAID() { return new PS_ENTRY_STATUS('PAID', 'green'); } 
public static get SKIPPED() { return new PS_ENTRY_STATUS('SKIPPED', 'default'); } 
public static get SCHEDULED() { return new PS_ENTRY_STATUS('SCHEDULED', 'primary'); } 
public static get PRESENTED() { return new PS_ENTRY_STATUS('PRESENTED', 'primary'); } 
public static get NSF() { return new PS_ENTRY_STATUS('NSF', 'secondary'); } 
public static get REJECTED() { return new PS_ENTRY_STATUS('REJECTED', 'red'); } 
public static get NSF_RESCHEDULED() { return new PS_ENTRY_STATUS('NSF_RESCHEDULED', 'default'); } 

     public static get DEFAULT_VALUE() { return PS_ENTRY_STATUS.SCHEDULED; }

     public static withIdOption(id: string): PS_ENTRY_STATUS| undefined  {
        try {
            return PS_ENTRY_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PS_ENTRY_STATUS.withIdOption(id) !== undefined; }
    public static list(): PS_ENTRY_STATUS[]  { return [PS_ENTRY_STATUS.NSF_RESCHEDULED, PS_ENTRY_STATUS.REJECTED, PS_ENTRY_STATUS.SCHEDULED, PS_ENTRY_STATUS.SKIPPED, PS_ENTRY_STATUS.PRESENTED, PS_ENTRY_STATUS.NSF, PS_ENTRY_STATUS.PAID]; }
    public static idList(): string[]  { return ['NSF_RESCHEDULED', 'PAID', 'REJECTED', 'SKIPPED', 'SCHEDULED', 'PRESENTED', 'NSF']; }
    public static nameList(): string[]  { return ['secondary', 'green', 'default', 'red', 'primary']; }


    public static withId(id: string): PS_ENTRY_STATUS  {
        switch (id) {
            case 'PAID': return PS_ENTRY_STATUS.PAID; 
case 'REJECTED': return PS_ENTRY_STATUS.REJECTED; 
case 'SKIPPED': return PS_ENTRY_STATUS.SKIPPED; 
case 'SCHEDULED': return PS_ENTRY_STATUS.SCHEDULED; 
case 'PRESENTED': return PS_ENTRY_STATUS.PRESENTED; 
case 'NSF_RESCHEDULED': return PS_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'NSF': return PS_ENTRY_STATUS.NSF; 
            default: throw new Error('Invalid id for PS_ENTRY_STATUS: ' + id);
        }
    }

    public static withName(name: string): PS_ENTRY_STATUS  {
        switch (name) {
            case 'green': return PS_ENTRY_STATUS.PAID; 
case 'default': return PS_ENTRY_STATUS.NSF_RESCHEDULED; 
case 'default': return PS_ENTRY_STATUS.SKIPPED; 
case 'red': return PS_ENTRY_STATUS.REJECTED; 
case 'primary': return PS_ENTRY_STATUS.SCHEDULED; 
case 'secondary': return PS_ENTRY_STATUS.NSF; 
case 'primary': return PS_ENTRY_STATUS.PRESENTED; 
            default: throw new Error('Invalid id for PS_ENTRY_STATUS: ' + name);
        }
    }

    public static copyMe(given: PS_ENTRY_STATUS): PS_ENTRY_STATUS  {
            return PS_ENTRY_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PS_ENTRY_STATUS { return PS_ENTRY_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PS_ENTRY_STATUS.list().indexOf(this); }
}
 

export class PAYMENT_STATUS {

     public static get CANCELLED() { return new PAYMENT_STATUS('CANCELLED', 'red'); } 
public static get FAILED() { return new PAYMENT_STATUS('FAILED', 'red'); } 
public static get DRAFT() { return new PAYMENT_STATUS('DRAFT', 'default'); } 
public static get FUND_SPLIT_REQUESTED() { return new PAYMENT_STATUS('FUND_SPLIT_REQUESTED', 'secondary'); } 
public static get REJECTED() { return new PAYMENT_STATUS('REJECTED', 'red'); } 
public static get FUND_SPLIT_REJECTED() { return new PAYMENT_STATUS('FUND_SPLIT_REJECTED', 'red'); } 
public static get PRESENTED() { return new PAYMENT_STATUS('PRESENTED', 'primary'); } 
public static get NOT_REACHABLE() { return new PAYMENT_STATUS('NOT_REACHABLE', 'default'); } 
public static get RECEIVED() { return new PAYMENT_STATUS('RECEIVED', 'green'); } 
public static get SETTLED() { return new PAYMENT_STATUS('SETTLED', 'green'); } 

     public static get DEFAULT_VALUE() { return PAYMENT_STATUS.DRAFT; }

     public static withIdOption(id: string): PAYMENT_STATUS| undefined  {
        try {
            return PAYMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PAYMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): PAYMENT_STATUS[]  { return [PAYMENT_STATUS.FUND_SPLIT_REJECTED, PAYMENT_STATUS.NOT_REACHABLE, PAYMENT_STATUS.SETTLED, PAYMENT_STATUS.CANCELLED, PAYMENT_STATUS.REJECTED, PAYMENT_STATUS.RECEIVED, PAYMENT_STATUS.FUND_SPLIT_REQUESTED, PAYMENT_STATUS.FAILED, PAYMENT_STATUS.PRESENTED, PAYMENT_STATUS.DRAFT]; }
    public static idList(): string[]  { return ['REJECTED', 'FUND_SPLIT_REQUESTED', 'CANCELLED', 'FAILED', 'FUND_SPLIT_REJECTED', 'SETTLED', 'DRAFT', 'PRESENTED', 'RECEIVED', 'NOT_REACHABLE']; }
    public static nameList(): string[]  { return ['secondary', 'green', 'default', 'red', 'primary']; }


    public static withId(id: string): PAYMENT_STATUS  {
        switch (id) {
            case 'RECEIVED': return PAYMENT_STATUS.RECEIVED; 
case 'NOT_REACHABLE': return PAYMENT_STATUS.NOT_REACHABLE; 
case 'FAILED': return PAYMENT_STATUS.FAILED; 
case 'REJECTED': return PAYMENT_STATUS.REJECTED; 
case 'CANCELLED': return PAYMENT_STATUS.CANCELLED; 
case 'FUND_SPLIT_REJECTED': return PAYMENT_STATUS.FUND_SPLIT_REJECTED; 
case 'SETTLED': return PAYMENT_STATUS.SETTLED; 
case 'DRAFT': return PAYMENT_STATUS.DRAFT; 
case 'PRESENTED': return PAYMENT_STATUS.PRESENTED; 
case 'FUND_SPLIT_REQUESTED': return PAYMENT_STATUS.FUND_SPLIT_REQUESTED; 
            default: throw new Error('Invalid id for PAYMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): PAYMENT_STATUS  {
        switch (name) {
            case 'primary': return PAYMENT_STATUS.PRESENTED; 
case 'red': return PAYMENT_STATUS.REJECTED; 
case 'green': return PAYMENT_STATUS.SETTLED; 
case 'green': return PAYMENT_STATUS.RECEIVED; 
case 'red': return PAYMENT_STATUS.FUND_SPLIT_REJECTED; 
case 'red': return PAYMENT_STATUS.CANCELLED; 
case 'default': return PAYMENT_STATUS.DRAFT; 
case 'red': return PAYMENT_STATUS.FAILED; 
case 'default': return PAYMENT_STATUS.NOT_REACHABLE; 
case 'secondary': return PAYMENT_STATUS.FUND_SPLIT_REQUESTED; 
            default: throw new Error('Invalid id for PAYMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: PAYMENT_STATUS): PAYMENT_STATUS  {
            return PAYMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PAYMENT_STATUS { return PAYMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PAYMENT_STATUS.list().indexOf(this); }
}
 

export class EMANDATE_STATUS {

     public static get ACTIVE() { return new EMANDATE_STATUS('ACTIVE', 'green'); } 
public static get PENDING() { return new EMANDATE_STATUS('PENDING', 'secondary'); } 
public static get EXPIRED() { return new EMANDATE_STATUS('EXPIRED', 'default'); } 
public static get REJECTED() { return new EMANDATE_STATUS('REJECTED', 'red'); } 
public static get CANCELLED() { return new EMANDATE_STATUS('CANCELLED', 'red'); } 

     public static get DEFAULT_VALUE() { return EMANDATE_STATUS.PENDING; }

     public static withIdOption(id: string): EMANDATE_STATUS| undefined  {
        try {
            return EMANDATE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return EMANDATE_STATUS.withIdOption(id) !== undefined; }
    public static list(): EMANDATE_STATUS[]  { return [EMANDATE_STATUS.REJECTED, EMANDATE_STATUS.PENDING, EMANDATE_STATUS.EXPIRED, EMANDATE_STATUS.ACTIVE, EMANDATE_STATUS.CANCELLED]; }
    public static idList(): string[]  { return ['REJECTED', 'CANCELLED', 'ACTIVE', 'PENDING', 'EXPIRED']; }
    public static nameList(): string[]  { return ['green', 'red', 'default', 'secondary']; }


    public static withId(id: string): EMANDATE_STATUS  {
        switch (id) {
            case 'EXPIRED': return EMANDATE_STATUS.EXPIRED; 
case 'REJECTED': return EMANDATE_STATUS.REJECTED; 
case 'ACTIVE': return EMANDATE_STATUS.ACTIVE; 
case 'CANCELLED': return EMANDATE_STATUS.CANCELLED; 
case 'PENDING': return EMANDATE_STATUS.PENDING; 
            default: throw new Error('Invalid id for EMANDATE_STATUS: ' + id);
        }
    }

    public static withName(name: string): EMANDATE_STATUS  {
        switch (name) {
            case 'green': return EMANDATE_STATUS.ACTIVE; 
case 'secondary': return EMANDATE_STATUS.PENDING; 
case 'red': return EMANDATE_STATUS.REJECTED; 
case 'red': return EMANDATE_STATUS.CANCELLED; 
case 'default': return EMANDATE_STATUS.EXPIRED; 
            default: throw new Error('Invalid id for EMANDATE_STATUS: ' + name);
        }
    }

    public static copyMe(given: EMANDATE_STATUS): EMANDATE_STATUS  {
            return EMANDATE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): EMANDATE_STATUS { return EMANDATE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return EMANDATE_STATUS.list().indexOf(this); }
}
 

export class AGREEMENT_STATUS {

     public static get SIGNED() { return new AGREEMENT_STATUS('SIGNED', 'green'); } 
public static get CANCELLED() { return new AGREEMENT_STATUS('CANCELLED', 'red'); } 
public static get ACTIVE() { return new AGREEMENT_STATUS('ACTIVE', 'default'); } 

     public static get DEFAULT_VALUE() { return AGREEMENT_STATUS.SIGNED; }

     public static withIdOption(id: string): AGREEMENT_STATUS| undefined  {
        try {
            return AGREEMENT_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return AGREEMENT_STATUS.withIdOption(id) !== undefined; }
    public static list(): AGREEMENT_STATUS[]  { return [AGREEMENT_STATUS.SIGNED, AGREEMENT_STATUS.CANCELLED, AGREEMENT_STATUS.ACTIVE]; }
    public static idList(): string[]  { return ['SIGNED', 'CANCELLED', 'ACTIVE']; }
    public static nameList(): string[]  { return ['green', 'red', 'default']; }


    public static withId(id: string): AGREEMENT_STATUS  {
        switch (id) {
            case 'SIGNED': return AGREEMENT_STATUS.SIGNED; 
case 'CANCELLED': return AGREEMENT_STATUS.CANCELLED; 
case 'ACTIVE': return AGREEMENT_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for AGREEMENT_STATUS: ' + id);
        }
    }

    public static withName(name: string): AGREEMENT_STATUS  {
        switch (name) {
            case 'green': return AGREEMENT_STATUS.SIGNED; 
case 'red': return AGREEMENT_STATUS.CANCELLED; 
case 'default': return AGREEMENT_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for AGREEMENT_STATUS: ' + name);
        }
    }

    public static copyMe(given: AGREEMENT_STATUS): AGREEMENT_STATUS  {
            return AGREEMENT_STATUS.withId(given.id)
    }

    public static fromJson(json: string): AGREEMENT_STATUS { return AGREEMENT_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return AGREEMENT_STATUS.list().indexOf(this); }
}
 

export class PARTNER_STATUS {

     public static get INACTIVE() { return new PARTNER_STATUS('INACTIVE', 'red'); } 
public static get ONBOARDING_REQUESTED() { return new PARTNER_STATUS('ONBOARDING_REQUESTED', 'primary'); } 
public static get ACTIVE() { return new PARTNER_STATUS('ACTIVE', 'green'); } 
public static get REJECTED() { return new PARTNER_STATUS('REJECTED', 'red'); } 
public static get SIGNED_UP() { return new PARTNER_STATUS('SIGNED_UP', 'default'); } 

     public static get DEFAULT_VALUE() { return PARTNER_STATUS.SIGNED_UP; }

     public static withIdOption(id: string): PARTNER_STATUS| undefined  {
        try {
            return PARTNER_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PARTNER_STATUS.withIdOption(id) !== undefined; }
    public static list(): PARTNER_STATUS[]  { return [PARTNER_STATUS.REJECTED, PARTNER_STATUS.INACTIVE, PARTNER_STATUS.ONBOARDING_REQUESTED, PARTNER_STATUS.ACTIVE, PARTNER_STATUS.SIGNED_UP]; }
    public static idList(): string[]  { return ['REJECTED', 'ONBOARDING_REQUESTED', 'ACTIVE', 'SIGNED_UP', 'INACTIVE']; }
    public static nameList(): string[]  { return ['green', 'red', 'primary', 'default']; }


    public static withId(id: string): PARTNER_STATUS  {
        switch (id) {
            case 'REJECTED': return PARTNER_STATUS.REJECTED; 
case 'ONBOARDING_REQUESTED': return PARTNER_STATUS.ONBOARDING_REQUESTED; 
case 'INACTIVE': return PARTNER_STATUS.INACTIVE; 
case 'SIGNED_UP': return PARTNER_STATUS.SIGNED_UP; 
case 'ACTIVE': return PARTNER_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for PARTNER_STATUS: ' + id);
        }
    }

    public static withName(name: string): PARTNER_STATUS  {
        switch (name) {
            case 'red': return PARTNER_STATUS.INACTIVE; 
case 'default': return PARTNER_STATUS.SIGNED_UP; 
case 'primary': return PARTNER_STATUS.ONBOARDING_REQUESTED; 
case 'red': return PARTNER_STATUS.REJECTED; 
case 'green': return PARTNER_STATUS.ACTIVE; 
            default: throw new Error('Invalid id for PARTNER_STATUS: ' + name);
        }
    }

    public static copyMe(given: PARTNER_STATUS): PARTNER_STATUS  {
            return PARTNER_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PARTNER_STATUS { return PARTNER_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PARTNER_STATUS.list().indexOf(this); }
}
 

export class PS_PLAN_STATUS {

     public static get DRAFT() { return new PS_PLAN_STATUS('DRAFT', 'default'); } 
public static get ACTIVE() { return new PS_PLAN_STATUS('ACTIVE', 'green'); } 
public static get HOLD() { return new PS_PLAN_STATUS('HOLD', 'default'); } 

     public static get DEFAULT_VALUE() { return PS_PLAN_STATUS.DRAFT; }

     public static withIdOption(id: string): PS_PLAN_STATUS| undefined  {
        try {
            return PS_PLAN_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return PS_PLAN_STATUS.withIdOption(id) !== undefined; }
    public static list(): PS_PLAN_STATUS[]  { return [PS_PLAN_STATUS.DRAFT, PS_PLAN_STATUS.ACTIVE, PS_PLAN_STATUS.HOLD]; }
    public static idList(): string[]  { return ['DRAFT', 'ACTIVE', 'HOLD']; }
    public static nameList(): string[]  { return ['default', 'green']; }


    public static withId(id: string): PS_PLAN_STATUS  {
        switch (id) {
            case 'DRAFT': return PS_PLAN_STATUS.DRAFT; 
case 'ACTIVE': return PS_PLAN_STATUS.ACTIVE; 
case 'HOLD': return PS_PLAN_STATUS.HOLD; 
            default: throw new Error('Invalid id for PS_PLAN_STATUS: ' + id);
        }
    }

    public static withName(name: string): PS_PLAN_STATUS  {
        switch (name) {
            case 'default': return PS_PLAN_STATUS.DRAFT; 
case 'green': return PS_PLAN_STATUS.ACTIVE; 
case 'default': return PS_PLAN_STATUS.HOLD; 
            default: throw new Error('Invalid id for PS_PLAN_STATUS: ' + name);
        }
    }

    public static copyMe(given: PS_PLAN_STATUS): PS_PLAN_STATUS  {
            return PS_PLAN_STATUS.withId(given.id)
    }

    public static fromJson(json: string): PS_PLAN_STATUS { return PS_PLAN_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return PS_PLAN_STATUS.list().indexOf(this); }
}
 

export class SERVICE_STATUS {

     public static get NO_SERVICE() { return new SERVICE_STATUS('NO_SERVICE', 'red'); } 
public static get IN_SERVICE() { return new SERVICE_STATUS('IN_SERVICE', 'green'); } 

     public static get DEFAULT_VALUE() { return SERVICE_STATUS.NO_SERVICE; }

     public static withIdOption(id: string): SERVICE_STATUS| undefined  {
        try {
            return SERVICE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return SERVICE_STATUS.withIdOption(id) !== undefined; }
    public static list(): SERVICE_STATUS[]  { return [SERVICE_STATUS.NO_SERVICE, SERVICE_STATUS.IN_SERVICE]; }
    public static idList(): string[]  { return ['NO_SERVICE', 'IN_SERVICE']; }
    public static nameList(): string[]  { return ['red', 'green']; }


    public static withId(id: string): SERVICE_STATUS  {
        switch (id) {
            case 'NO_SERVICE': return SERVICE_STATUS.NO_SERVICE; 
case 'IN_SERVICE': return SERVICE_STATUS.IN_SERVICE; 
            default: throw new Error('Invalid id for SERVICE_STATUS: ' + id);
        }
    }

    public static withName(name: string): SERVICE_STATUS  {
        switch (name) {
            case 'red': return SERVICE_STATUS.NO_SERVICE; 
case 'green': return SERVICE_STATUS.IN_SERVICE; 
            default: throw new Error('Invalid id for SERVICE_STATUS: ' + name);
        }
    }

    public static copyMe(given: SERVICE_STATUS): SERVICE_STATUS  {
            return SERVICE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): SERVICE_STATUS { return SERVICE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return SERVICE_STATUS.list().indexOf(this); }
}
 


  




}



export namespace TaskList {
  

export class CommentLevel {

     public static get Flow() { return new CommentLevel('Flow', 'Flow'); } 
public static get Task() { return new CommentLevel('Task', 'Task'); } 

     public static get DEFAULT_VALUE() { return CommentLevel.Flow; }

     public static withIdOption(id: string): CommentLevel| undefined  {
        try {
            return CommentLevel.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CommentLevel.withIdOption(id) !== undefined; }
    public static list(): CommentLevel[]  { return [CommentLevel.Flow, CommentLevel.Task]; }
    public static idList(): string[]  { return ['Flow', 'Task']; }
    public static nameList(): string[]  { return ['Flow', 'Task']; }


    public static withId(id: string): CommentLevel  {
        switch (id) {
            case 'Flow': return CommentLevel.Flow; 
case 'Task': return CommentLevel.Task; 
            default: throw new Error('Invalid id for CommentLevel: ' + id);
        }
    }

    public static withName(name: string): CommentLevel  {
        switch (name) {
            case 'Flow': return CommentLevel.Flow; 
case 'Task': return CommentLevel.Task; 
            default: throw new Error('Invalid id for CommentLevel: ' + name);
        }
    }

    public static copyMe(given: CommentLevel): CommentLevel  {
            return CommentLevel.withId(given.id)
    }

    public static fromJson(json: string): CommentLevel { return CommentLevel.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CommentLevel.list().indexOf(this); }
}
 


  


export class CountSummary {

   
    public static fromJsonString(jsonString: string): CountSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return CountSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CountSummary {
        const json: any = o ;
        return new CountSummary(
         (json.startedCount != null) ? json.startedCount : 0,
(json.allocatedCount != null) ? json.allocatedCount : 0,
(json.completedCount != null) ? json.completedCount : 0
        );
    }



  


   
    constructor(
        public startedCount: number = 0,
public allocatedCount: number = 0,
public completedCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          startedCount: (this.startedCount != null) ? this.startedCount : undefined,
allocatedCount: (this.allocatedCount != null) ? this.allocatedCount : undefined,
completedCount: (this.completedCount != null) ? this.completedCount : undefined
        };
    }
    



}



export class BenchTaskSummary {

   
    public static fromJsonString(jsonString: string): BenchTaskSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return BenchTaskSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): BenchTaskSummary {
        const json: any = o ;
        return new BenchTaskSummary(
         (json.countSummary != null) ? CountSummary.fromJson(json.countSummary) : new CountSummary(),
(json.cfTaskList != null) ? (json.cfTaskList as object[]).map((x) => GetTaskListByCidGrid.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public countSummary: CountSummary = new CountSummary(),
public cfTaskList: GetTaskListByCidGrid[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          countSummary: (this.countSummary != null) ? this.countSummary.toJson() : undefined,
cfTaskList: (this.cfTaskList != null) ? this.cfTaskList.map((x) => x.toJson()) : []
        };
    }
    



}



export class MyTaskDashboardSummary {

   
    public static fromJsonString(jsonString: string): MyTaskDashboardSummary {
       const jsonObj: object = JSON.parse(jsonString);
       return MyTaskDashboardSummary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyTaskDashboardSummary {
        const json: any = o ;
        return new MyTaskDashboardSummary(
         (json.myActiveTaskCount != null) ? json.myActiveTaskCount : 0,
(json.myCompletedTaskCount != null) ? json.myCompletedTaskCount : 0,
(json.mySuspendedTaskCount != null) ? json.mySuspendedTaskCount : 0,
(json.myTotalTaskCount != null) ? json.myTotalTaskCount : 0
        );
    }



  


   
    constructor(
        public myActiveTaskCount: number = 0,
public myCompletedTaskCount: number = 0,
public mySuspendedTaskCount: number = 0,
public myTotalTaskCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          myActiveTaskCount: (this.myActiveTaskCount != null) ? this.myActiveTaskCount : undefined,
myCompletedTaskCount: (this.myCompletedTaskCount != null) ? this.myCompletedTaskCount : undefined,
mySuspendedTaskCount: (this.mySuspendedTaskCount != null) ? this.mySuspendedTaskCount : undefined,
myTotalTaskCount: (this.myTotalTaskCount != null) ? this.myTotalTaskCount : undefined
        };
    }
    



}



export class MyTaskStore {

   
    public static fromJsonString(jsonString: string): MyTaskStore {
       const jsonObj: object = JSON.parse(jsonString);
       return MyTaskStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MyTaskStore {
        const json: any = o ;
        return new MyTaskStore(
         (json.myActiveTaskList != null) ? (json.myActiveTaskList as object[]).map((x) => GetActiveTaskListAllocatedGrid.fromJson(x)) : [],
(json.myCompletedTaskList != null) ? (json.myCompletedTaskList as object[]).map((x) => CompletedTaskGrid.fromJson(x)) : [],
(json.mySuspendedTaskList != null) ? (json.mySuspendedTaskList as object[]).map((x) => SuspendedTaskAllocated.fromJson(x)) : [],
(json.myTaskDashboardSummary != null) ? MyTaskDashboardSummary.fromJson(json.myTaskDashboardSummary) : new MyTaskDashboardSummary(),
(json.taskCommentList != null) ? (json.taskCommentList as object[]).map((x) => Comment.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public myActiveTaskList: GetActiveTaskListAllocatedGrid[] = [],
public myCompletedTaskList: CompletedTaskGrid[] = [],
public mySuspendedTaskList: SuspendedTaskAllocated[] = [],
public myTaskDashboardSummary: MyTaskDashboardSummary = new MyTaskDashboardSummary(),
public taskCommentList: Comment[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          myActiveTaskList: (this.myActiveTaskList != null) ? this.myActiveTaskList.map((x) => x.toJson()) : [],
myCompletedTaskList: (this.myCompletedTaskList != null) ? this.myCompletedTaskList.map((x) => x.toJson()) : [],
mySuspendedTaskList: (this.mySuspendedTaskList != null) ? this.mySuspendedTaskList.map((x) => x.toJson()) : [],
myTaskDashboardSummary: (this.myTaskDashboardSummary != null) ? this.myTaskDashboardSummary.toJson() : undefined,
taskCommentList: (this.taskCommentList != null) ? this.taskCommentList.map((x) => x.toJson()) : []
        };
    }
    



}



export class Tag {

   
    public static fromJsonString(jsonString: string): Tag {
       const jsonObj: object = JSON.parse(jsonString);
       return Tag.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Tag {
        const json: any = o ;
        return new Tag(
         (json.key != null) ? json.key : '',
(json.valueList != null) ? json.valueList : []
        );
    }



  


   
    constructor(
        public key: string = '',
public valueList: string[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          key: (this.key != null) ? this.key : undefined,
valueList: (this.valueList != null) ? this.valueList : []
        };
    }
    



}



export class ToBePulledTaskGrid {

   
    public static fromJsonString(jsonString: string): ToBePulledTaskGrid {
       const jsonObj: object = JSON.parse(jsonString);
       return ToBePulledTaskGrid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ToBePulledTaskGrid {
        const json: any = o ;
        return new ToBePulledTaskGrid(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.priority != null) ? json.priority : 0,
(json.readyTime != null) ? json.readyTime : '',
(json.taskInput != null) ? json.taskInput : '',
(json.tagList != null) ? (json.tagList as object[]).map((x) => Tag.fromJson(x)) : [],
(json.isSuspended != null) ? json.isSuspended : false,
(json.pull != null) ? json.pull : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public priority: number = 0,
public readyTime: string = '',
public taskInput: string = '',
public tagList: Tag[] = [],
public isSuspended: boolean = false,
public pull: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
priority: (this.priority != null) ? this.priority : undefined,
readyTime: (this.readyTime != null) ? this.readyTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
tagList: (this.tagList != null) ? this.tagList.map((x) => x.toJson()) : [],
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined,
pull: (this.pull != null) ? this.pull : undefined
        };
    }
    



}



export class TaskListByCid {

   
    public static fromJsonString(jsonString: string): TaskListByCid {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskListByCid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskListByCid {
        const json: any = o ;
        return new TaskListByCid(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? json.taskType : '',
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? json.taskState : '',
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? json.readyTime : '',
(json.allocatedTime != null) ? json.allocatedTime : undefined,
(json.startedTime != null) ? json.startedTime : undefined,
(json.endTime != null) ? json.endTime : undefined,
(json.lastUserActivityTime != null) ? json.lastUserActivityTime : undefined,
(json.taskInput != null) ? json.taskInput : {},
(json.taskOutput != null) ? json.taskOutput : {}
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: string = '',
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: string = '',
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: string = '',
public allocatedTime: string|undefined = undefined,
public startedTime: string|undefined = undefined,
public endTime: string|undefined = undefined,
public lastUserActivityTime: string|undefined = undefined,
public taskInput: object = {},
public taskOutput: object = {}
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ? this.taskType : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
endTime: (this.endTime != null) ? this.endTime : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined
        };
    }
    



}



export class GetTaskListByCidGrid {

   
    public static fromJsonString(jsonString: string): GetTaskListByCidGrid {
       const jsonObj: object = JSON.parse(jsonString);
       return GetTaskListByCidGrid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetTaskListByCidGrid {
        const json: any = o ;
        return new GetTaskListByCidGrid(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? json.taskType : '',
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? json.taskState : '',
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? json.readyTime : '',
(json.allocatedTime != null) ? json.allocatedTime : undefined,
(json.startedTime != null) ? json.startedTime : undefined,
(json.endTime != null) ? json.endTime : undefined,
(json.lastUserActivityTime != null) ? json.lastUserActivityTime : undefined,
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutput != null) ? json.taskOutput : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: string = '',
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: string = '',
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: string = '',
public allocatedTime: string|undefined = undefined,
public startedTime: string|undefined = undefined,
public endTime: string|undefined = undefined,
public lastUserActivityTime: string|undefined = undefined,
public taskInput: string = '',
public taskOutput: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ? this.taskType : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
endTime: (this.endTime != null) ? this.endTime : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined
        };
    }
    



}



export class GetActiveTaskListAllocatedGrid {

   
    public static fromJsonString(jsonString: string): GetActiveTaskListAllocatedGrid {
       const jsonObj: object = JSON.parse(jsonString);
       return GetActiveTaskListAllocatedGrid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): GetActiveTaskListAllocatedGrid {
        const json: any = o ;
        return new GetActiveTaskListAllocatedGrid(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? json.taskType : '',
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? json.taskState : '',
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? json.readyTime : '',
(json.allocatedTime != null) ? json.allocatedTime : undefined,
(json.startedTime != null) ? json.startedTime : undefined,
(json.endTime != null) ? json.endTime : undefined,
(json.lastUserActivityTime != null) ? json.lastUserActivityTime : undefined,
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutput != null) ? json.taskOutput : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: string = '',
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: string = '',
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: string = '',
public allocatedTime: string|undefined = undefined,
public startedTime: string|undefined = undefined,
public endTime: string|undefined = undefined,
public lastUserActivityTime: string|undefined = undefined,
public taskInput: string = '',
public taskOutput: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ? this.taskType : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
endTime: (this.endTime != null) ? this.endTime : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined
        };
    }
    



}



export class MISAccountBalanceEntry {

   
    public static fromJsonString(jsonString: string): MISAccountBalanceEntry {
       const jsonObj: object = JSON.parse(jsonString);
       return MISAccountBalanceEntry.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MISAccountBalanceEntry {
        const json: any = o ;
        return new MISAccountBalanceEntry(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientName != null) ? json.clientName : '',
(json.mobile != null) ? json.mobile : '',
(json.numberOfSPATxn != null) ? json.numberOfSPATxn : 0,
(json.totalSPACollected != null) ? json.totalSPACollected : 0,
(json.numberOfMSFTxn != null) ? json.numberOfMSFTxn : 0,
(json.totalMSFCollected != null) ? json.totalMSFCollected : 0,
(json.numberOfSettlmentTxn != null) ? json.numberOfSettlmentTxn : 0,
(json.totalSettlementAmt != null) ? json.totalSettlementAmt : 0,
(json.numberOfRefundTxn != null) ? json.numberOfRefundTxn : 0,
(json.totalRefundAmt != null) ? json.totalRefundAmt : 0,
(json.numberOfKFSFeeTxn != null) ? json.numberOfKFSFeeTxn : 0,
(json.totalKFSFee != null) ? json.totalKFSFee : 0,
(json.totalInTxnSum != null) ? json.totalInTxnSum : 0,
(json.totalOutTxnSum != null) ? json.totalOutTxnSum : 0,
(json.accountBalance != null) ? json.accountBalance : 0
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientName: string = '',
public mobile: string = '',
public numberOfSPATxn: number = 0,
public totalSPACollected: number = 0,
public numberOfMSFTxn: number = 0,
public totalMSFCollected: number = 0,
public numberOfSettlmentTxn: number = 0,
public totalSettlementAmt: number = 0,
public numberOfRefundTxn: number = 0,
public totalRefundAmt: number = 0,
public numberOfKFSFeeTxn: number = 0,
public totalKFSFee: number = 0,
public totalInTxnSum: number = 0,
public totalOutTxnSum: number = 0,
public accountBalance: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientName: (this.clientName != null) ? this.clientName : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
numberOfSPATxn: (this.numberOfSPATxn != null) ? this.numberOfSPATxn : undefined,
totalSPACollected: (this.totalSPACollected != null) ? this.totalSPACollected : undefined,
numberOfMSFTxn: (this.numberOfMSFTxn != null) ? this.numberOfMSFTxn : undefined,
totalMSFCollected: (this.totalMSFCollected != null) ? this.totalMSFCollected : undefined,
numberOfSettlmentTxn: (this.numberOfSettlmentTxn != null) ? this.numberOfSettlmentTxn : undefined,
totalSettlementAmt: (this.totalSettlementAmt != null) ? this.totalSettlementAmt : undefined,
numberOfRefundTxn: (this.numberOfRefundTxn != null) ? this.numberOfRefundTxn : undefined,
totalRefundAmt: (this.totalRefundAmt != null) ? this.totalRefundAmt : undefined,
numberOfKFSFeeTxn: (this.numberOfKFSFeeTxn != null) ? this.numberOfKFSFeeTxn : undefined,
totalKFSFee: (this.totalKFSFee != null) ? this.totalKFSFee : undefined,
totalInTxnSum: (this.totalInTxnSum != null) ? this.totalInTxnSum : undefined,
totalOutTxnSum: (this.totalOutTxnSum != null) ? this.totalOutTxnSum : undefined,
accountBalance: (this.accountBalance != null) ? this.accountBalance : undefined
        };
    }
    



}



export class MISAccountBalanceEntryObj {

   
    public static fromJsonString(jsonString: string): MISAccountBalanceEntryObj {
       const jsonObj: object = JSON.parse(jsonString);
       return MISAccountBalanceEntryObj.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): MISAccountBalanceEntryObj {
        const json: any = o ;
        return new MISAccountBalanceEntryObj(
         (json.misAccountBalanceEntryList != null) ? (json.misAccountBalanceEntryList as object[]).map((x) => MISAccountBalanceEntry.fromJson(x)) : [],
(json.totalRecordCount != null) ? json.totalRecordCount : 0
        );
    }



  


   
    constructor(
        public misAccountBalanceEntryList: MISAccountBalanceEntry[] = [],
public totalRecordCount: number = 0
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          misAccountBalanceEntryList: (this.misAccountBalanceEntryList != null) ? this.misAccountBalanceEntryList.map((x) => x.toJson()) : [],
totalRecordCount: (this.totalRecordCount != null) ? this.totalRecordCount : undefined
        };
    }
    



}



export class AllocatedTaskGrid {

   
    public static fromJsonString(jsonString: string): AllocatedTaskGrid {
       const jsonObj: object = JSON.parse(jsonString);
       return AllocatedTaskGrid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AllocatedTaskGrid {
        const json: any = o ;
        return new AllocatedTaskGrid(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.priority != null) ? json.priority : 0,
(json.allocatedTime != null) ? json.allocatedTime : '',
(json.taskInput != null) ? json.taskInput : '',
(json.isSuspended != null) ? json.isSuspended : false,
(json.start != null) ? json.start : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public priority: number = 0,
public allocatedTime: string = '',
public taskInput: string = '',
public isSuspended: boolean = false,
public start: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined,
start: (this.start != null) ? this.start : undefined
        };
    }
    



}



export class StartedTaskGrid {

   
    public static fromJsonString(jsonString: string): StartedTaskGrid {
       const jsonObj: object = JSON.parse(jsonString);
       return StartedTaskGrid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): StartedTaskGrid {
        const json: any = o ;
        return new StartedTaskGrid(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.priority != null) ? json.priority : 0,
(json.taskState != null) ? json.taskState : '',
(json.startedTime != null) ? json.startedTime : '',
(json.lastUserActivityTime != null) ? json.lastUserActivityTime : '',
(json.taskInput != null) ? json.taskInput : '',
(json.isSuspended != null) ? json.isSuspended : false
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public priority: number = 0,
public taskState: string = '',
public startedTime: string = '',
public lastUserActivityTime: string = '',
public taskInput: string = '',
public isSuspended: boolean = false
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
priority: (this.priority != null) ? this.priority : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined
        };
    }
    



}



export class CompletedTaskGrid {

   
    public static fromJsonString(jsonString: string): CompletedTaskGrid {
       const jsonObj: object = JSON.parse(jsonString);
       return CompletedTaskGrid.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CompletedTaskGrid {
        const json: any = o ;
        return new CompletedTaskGrid(
         (json.taskId != null) ? json.taskId : '',
(json.displayId != null) ? json.displayId : '',
(json.cid != null) ? json.cid : '',
(json.taskName != null) ? json.taskName : '',
(json.startedTime != null) ? json.startedTime : '',
(json.completedTime != null) ? json.completedTime : '',
(json.timeTakenToComplete != null) ? json.timeTakenToComplete : '',
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutput != null) ? json.taskOutput : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public displayId: string = '',
public cid: string = '',
public taskName: string = '',
public startedTime: string = '',
public completedTime: string = '',
public timeTakenToComplete: string = '',
public taskInput: string = '',
public taskOutput: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
completedTime: (this.completedTime != null) ? this.completedTime : undefined,
timeTakenToComplete: (this.timeTakenToComplete != null) ? this.timeTakenToComplete : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined
        };
    }
    



}



export class SuspendedTaskAllocated {

   
    public static fromJsonString(jsonString: string): SuspendedTaskAllocated {
       const jsonObj: object = JSON.parse(jsonString);
       return SuspendedTaskAllocated.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SuspendedTaskAllocated {
        const json: any = o ;
        return new SuspendedTaskAllocated(
         (json.taskId != null) ? json.taskId : '',
(json.taskName != null) ? json.taskName : '',
(json.taskType != null) ? json.taskType : '',
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? json.taskState : '',
(json.priority != null) ? json.priority : 0,
(json.allocatedTo != null) ? json.allocatedTo : undefined,
(json.readyTime != null) ? json.readyTime : '',
(json.allocatedTime != null) ? json.allocatedTime : undefined,
(json.startedTime != null) ? json.startedTime : undefined,
(json.endTime != null) ? json.endTime : undefined,
(json.lastUserActivityTime != null) ? json.lastUserActivityTime : undefined,
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutput != null) ? json.taskOutput : '',
(json.resumeOn != null) ? json.resumeOn : undefined,
(json.reminderId != null) ? json.reminderId : undefined
        );
    }



  


   
    constructor(
        public taskId: string = '',
public taskName: string = '',
public taskType: string = '',
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: string = '',
public priority: number = 0,
public allocatedTo: string|undefined = undefined,
public readyTime: string = '',
public allocatedTime: string|undefined = undefined,
public startedTime: string|undefined = undefined,
public endTime: string|undefined = undefined,
public lastUserActivityTime: string|undefined = undefined,
public taskInput: string = '',
public taskOutput: string = '',
public resumeOn: string|undefined = undefined,
public reminderId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined,
taskType: (this.taskType != null) ? this.taskType : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
priority: (this.priority != null) ? this.priority : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined,
readyTime: (this.readyTime != null) ? this.readyTime : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
endTime: (this.endTime != null) ? this.endTime : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined,
resumeOn: (this.resumeOn != null) ? this.resumeOn : undefined,
reminderId: (this.reminderId != null) ? this.reminderId : undefined
        };
    }
    



}



export class Disposition {

   
    public static fromJsonString(jsonString: string): Disposition {
       const jsonObj: object = JSON.parse(jsonString);
       return Disposition.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Disposition {
        const json: any = o ;
        return new Disposition(
         (json.dispositionType != null) ? json.dispositionType : '',
(json.dispositionDetails != null) ? json.dispositionDetails : undefined
        );
    }



  


   
    constructor(
        public dispositionType: string = '',
public dispositionDetails: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          dispositionType: (this.dispositionType != null) ? this.dispositionType : undefined,
dispositionDetails: (this.dispositionDetails != null) ? this.dispositionDetails : undefined
        };
    }
    



}



export class SuspendTaskInput {

   
    public static fromJsonString(jsonString: string): SuspendTaskInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SuspendTaskInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SuspendTaskInput {
        const json: any = o ;
        return new SuspendTaskInput(
         (json.taskId != null) ? json.taskId : '',
(json.resumeOn != null) ? json.resumeOn : undefined,
(json.reason != null) ? json.reason : undefined,
(json.disposition != null) ? Disposition.fromJson(json.disposition) : new Disposition()
        );
    }



  


   
    constructor(
        public taskId: string = '',
public resumeOn: string|undefined = undefined,
public reason: string|undefined = undefined,
public disposition: Disposition = new Disposition()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
resumeOn: (this.resumeOn != null) ? this.resumeOn : undefined,
reason: (this.reason != null) ? this.reason : undefined,
disposition: (this.disposition != null) ? this.disposition.toJson() : undefined
        };
    }
    



}



export class Summary {

   
    public static fromJsonString(jsonString: string): Summary {
       const jsonObj: object = JSON.parse(jsonString);
       return Summary.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Summary {
        const json: any = o ;
        return new Summary(
         (json.taskDetails != null) ? ExecutiveTaskDetails.fromJson(json.taskDetails) : new ExecutiveTaskDetails()
        );
    }



  


   
    constructor(
        public taskDetails: ExecutiveTaskDetails = new ExecutiveTaskDetails()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskDetails: (this.taskDetails != null) ? this.taskDetails.toJson() : undefined
        };
    }
    



}



export class TaskExceptionDetails {

   
    public static fromJsonString(jsonString: string): TaskExceptionDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return TaskExceptionDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TaskExceptionDetails {
        const json: any = o ;
        return new TaskExceptionDetails(
         (json.exceptionSummary != null) ? json.exceptionSummary : '',
(json.exceptionDetails != null) ? json.exceptionDetails : '',
(json.exceptionTime != null) ? json.exceptionTime : ''
        );
    }



  


   
    constructor(
        public exceptionSummary: string = '',
public exceptionDetails: string = '',
public exceptionTime: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          exceptionSummary: (this.exceptionSummary != null) ? this.exceptionSummary : undefined,
exceptionDetails: (this.exceptionDetails != null) ? this.exceptionDetails : undefined,
exceptionTime: (this.exceptionTime != null) ? this.exceptionTime : undefined
        };
    }
    



}



export class ExecutiveTaskDetails {

   
    public static fromJsonString(jsonString: string): ExecutiveTaskDetails {
       const jsonObj: object = JSON.parse(jsonString);
       return ExecutiveTaskDetails.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ExecutiveTaskDetails {
        const json: any = o ;
        return new ExecutiveTaskDetails(
         (json.taskName != null) ? json.taskName : '',
(json.flowName != null) ? json.flowName : '',
(json.flowId != null) ? json.flowId : '',
(json.cid != null) ? json.cid : '',
(json.displayId != null) ? json.displayId : '',
(json.taskState != null) ? json.taskState : '',
(json.taskType != null) ? json.taskType : '',
(json.priority != null) ? json.priority : 0,
(json.readyTime != null) ? json.readyTime : '',
(json.allocatedTime != null) ? json.allocatedTime : undefined,
(json.startedTime != null) ? json.startedTime : undefined,
(json.endTime != null) ? json.endTime : undefined,
(json.lastUserActivityTime != null) ? json.lastUserActivityTime : undefined,
(json.taskInputSchema != null) ? json.taskInputSchema : '',
(json.taskInput != null) ? json.taskInput : '',
(json.taskOutputSchema != null) ? json.taskOutputSchema : '{}',
(json.taskOutput != null) ? json.taskOutput : '',
(json.isSuspended != null) ? json.isSuspended : false,
(json.exceptionInfo != null) ? TaskExceptionDetails.fromJson(json.exceptionInfo) : undefined,
(json.outputJson != null) ? json.outputJson : {},
(json.inputJson != null) ? json.inputJson : {},
(json.isOutputEmpty != null) ? json.isOutputEmpty : false,
(json.isInputEmpty != null) ? json.isInputEmpty : false,
(json.isActionable != null) ? json.isActionable : false,
(json.allocatedTo != null) ? json.allocatedTo : ''
        );
    }



  


   
    constructor(
        public taskName: string = '',
public flowName: string = '',
public flowId: string = '',
public cid: string = '',
public displayId: string = '',
public taskState: string = '',
public taskType: string = '',
public priority: number = 0,
public readyTime: string = '',
public allocatedTime: string|undefined = undefined,
public startedTime: string|undefined = undefined,
public endTime: string|undefined = undefined,
public lastUserActivityTime: string|undefined = undefined,
public taskInputSchema: string = '',
public taskInput: string = '',
public taskOutputSchema: string = '{}',
public taskOutput: string = '',
public isSuspended: boolean = false,
public exceptionInfo: TaskExceptionDetails|undefined = undefined,
public outputJson: object = {},
public inputJson: object = {},
public isOutputEmpty: boolean = false,
public isInputEmpty: boolean = false,
public isActionable: boolean = false,
public allocatedTo: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskName: (this.taskName != null) ? this.taskName : undefined,
flowName: (this.flowName != null) ? this.flowName : undefined,
flowId: (this.flowId != null) ? this.flowId : undefined,
cid: (this.cid != null) ? this.cid : undefined,
displayId: (this.displayId != null) ? this.displayId : undefined,
taskState: (this.taskState != null) ? this.taskState : undefined,
taskType: (this.taskType != null) ? this.taskType : undefined,
priority: (this.priority != null) ? this.priority : undefined,
readyTime: (this.readyTime != null) ? this.readyTime : undefined,
allocatedTime: (this.allocatedTime != null) ? this.allocatedTime : undefined,
startedTime: (this.startedTime != null) ? this.startedTime : undefined,
endTime: (this.endTime != null) ? this.endTime : undefined,
lastUserActivityTime: (this.lastUserActivityTime != null) ? this.lastUserActivityTime : undefined,
taskInputSchema: (this.taskInputSchema != null) ? this.taskInputSchema : undefined,
taskInput: (this.taskInput != null) ? this.taskInput : undefined,
taskOutputSchema: (this.taskOutputSchema != null) ? this.taskOutputSchema : undefined,
taskOutput: (this.taskOutput != null) ? this.taskOutput : undefined,
isSuspended: (this.isSuspended != null) ? this.isSuspended : undefined,
exceptionInfo: (this.exceptionInfo != null) ? this.exceptionInfo.toJson() : undefined,
outputJson: (this.outputJson != null) ? this.outputJson : undefined,
inputJson: (this.inputJson != null) ? this.inputJson : undefined,
isOutputEmpty: (this.isOutputEmpty != null) ? this.isOutputEmpty : undefined,
isInputEmpty: (this.isInputEmpty != null) ? this.isInputEmpty : undefined,
isActionable: (this.isActionable != null) ? this.isActionable : undefined,
allocatedTo: (this.allocatedTo != null) ? this.allocatedTo : undefined
        };
    }
    



}



export class SaveCompleteForm {

   
    public static fromJsonString(jsonString: string): SaveCompleteForm {
       const jsonObj: object = JSON.parse(jsonString);
       return SaveCompleteForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SaveCompleteForm {
        const json: any = o ;
        return new SaveCompleteForm(
         (json.taskId != null) ? json.taskId : '',
(json.outputJson != null) ? json.outputJson : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public outputJson: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
outputJson: (this.outputJson != null) ? this.outputJson : undefined
        };
    }
    



}



export class SaveForm {

   
    public static fromJsonString(jsonString: string): SaveForm {
       const jsonObj: object = JSON.parse(jsonString);
       return SaveForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SaveForm {
        const json: any = o ;
        return new SaveForm(
         (json.taskId != null) ? json.taskId : '',
(json.dataJson != null) ? json.dataJson : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public dataJson: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
dataJson: (this.dataJson != null) ? this.dataJson : undefined
        };
    }
    



}



export class ExitCheckWithOutputForm {

   
    public static fromJsonString(jsonString: string): ExitCheckWithOutputForm {
       const jsonObj: object = JSON.parse(jsonString);
       return ExitCheckWithOutputForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ExitCheckWithOutputForm {
        const json: any = o ;
        return new ExitCheckWithOutputForm(
         (json.taskId != null) ? json.taskId : '',
(json.outputData != null) ? json.outputData : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public outputData: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
outputData: (this.outputData != null) ? this.outputData : undefined
        };
    }
    



}



export class ExitCheckWithOutputConstraint {

   
    public static fromJsonString(jsonString: string): ExitCheckWithOutputConstraint {
       const jsonObj: object = JSON.parse(jsonString);
       return ExitCheckWithOutputConstraint.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ExitCheckWithOutputConstraint {
        const json: any = o ;
        return new ExitCheckWithOutputConstraint(
         (json.key != null) ? json.key : '',
(json.value != null) ? json.value : ''
        );
    }



  


   
    constructor(
        public key: string = '',
public value: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          key: (this.key != null) ? this.key : undefined,
value: (this.value != null) ? this.value : undefined
        };
    }
    



}



export class CancelFlowAndCancelTaskInput {

   
    public static fromJsonString(jsonString: string): CancelFlowAndCancelTaskInput {
       const jsonObj: object = JSON.parse(jsonString);
       return CancelFlowAndCancelTaskInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): CancelFlowAndCancelTaskInput {
        const json: any = o ;
        return new CancelFlowAndCancelTaskInput(
         (json.taskId != null) ? json.taskId : '',
(json.reason != null) ? json.reason : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public reason: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
reason: (this.reason != null) ? this.reason : undefined
        };
    }
    



}



export class Comment {

   
    public static fromJsonString(jsonString: string): Comment {
       const jsonObj: object = JSON.parse(jsonString);
       return Comment.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): Comment {
        const json: any = o ;
        return new Comment(
         (json.commentedBy != null) ? json.commentedBy : '',
(json.createdTime != null) ? json.createdTime : '',
(json.commentLevel != null) ? CommentLevel.fromJson(json.commentLevel) : CommentLevel.DEFAULT_VALUE,
(json.comment != null) ? json.comment : '',
(json.taskName != null) ? json.taskName : undefined
        );
    }



  


   
    constructor(
        public commentedBy: string = '',
public createdTime: string = '',
public commentLevel: CommentLevel = CommentLevel.DEFAULT_VALUE,
public comment: string = '',
public taskName: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          commentedBy: (this.commentedBy != null) ? this.commentedBy : undefined,
createdTime: (this.createdTime != null) ? this.createdTime : undefined,
commentLevel: (this.commentLevel != null) ?  CommentLevel.copyMe(this.commentLevel).toJson() : undefined,
comment: (this.comment != null) ? this.comment : undefined,
taskName: (this.taskName != null) ? this.taskName : undefined
        };
    }
    



}



export class AddCommentForm {

   
    public static fromJsonString(jsonString: string): AddCommentForm {
       const jsonObj: object = JSON.parse(jsonString);
       return AddCommentForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddCommentForm {
        const json: any = o ;
        return new AddCommentForm(
         (json.taskId != null) ? json.taskId : '',
(json.comment != null) ? json.comment : ''
        );
    }



  


   
    constructor(
        public taskId: string = '',
public comment: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          taskId: (this.taskId != null) ? this.taskId : undefined,
comment: (this.comment != null) ? this.comment : undefined
        };
    }
    



}



}



export namespace Client {
  

export class CLIENT_FILE_STATUS {

     public static get HOLD() { return new CLIENT_FILE_STATUS('HOLD', 'HOLD'); } 
public static get PENDING_CANCELLED() { return new CLIENT_FILE_STATUS('PENDING_CANCELLED', 'PENDING_CANCELLED'); } 
public static get AGREEMENT_SIGNED() { return new CLIENT_FILE_STATUS('AGREEMENT_SIGNED', 'AGREEMENT_SIGNED'); } 
public static get ENROLLED() { return new CLIENT_FILE_STATUS('ENROLLED', 'ENROLLED'); } 
public static get ACTIVE() { return new CLIENT_FILE_STATUS('ACTIVE', 'ACTIVE'); } 
public static get CANCELLED() { return new CLIENT_FILE_STATUS('CANCELLED', 'CANCELLED'); } 
public static get NO_SERVICE() { return new CLIENT_FILE_STATUS('NO_SERVICE', 'NO_SERVICE'); } 
public static get GRADUATED() { return new CLIENT_FILE_STATUS('GRADUATED', 'GRADUATED'); } 
public static get LEAD() { return new CLIENT_FILE_STATUS('LEAD', 'LEAD'); } 

     public static get DEFAULT_VALUE() { return CLIENT_FILE_STATUS.LEAD; }

     public static withIdOption(id: string): CLIENT_FILE_STATUS| undefined  {
        try {
            return CLIENT_FILE_STATUS.withId(id);
          } catch (e) {
            return undefined;
          }
    }
    public static existsId(id: string): boolean { return CLIENT_FILE_STATUS.withIdOption(id) !== undefined; }
    public static list(): CLIENT_FILE_STATUS[]  { return [CLIENT_FILE_STATUS.CANCELLED, CLIENT_FILE_STATUS.ENROLLED, CLIENT_FILE_STATUS.ACTIVE, CLIENT_FILE_STATUS.NO_SERVICE, CLIENT_FILE_STATUS.PENDING_CANCELLED, CLIENT_FILE_STATUS.GRADUATED, CLIENT_FILE_STATUS.AGREEMENT_SIGNED, CLIENT_FILE_STATUS.LEAD, CLIENT_FILE_STATUS.HOLD]; }
    public static idList(): string[]  { return ['LEAD', 'NO_SERVICE', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'ENROLLED']; }
    public static nameList(): string[]  { return ['LEAD', 'NO_SERVICE', 'CANCELLED', 'ACTIVE', 'AGREEMENT_SIGNED', 'HOLD', 'GRADUATED', 'PENDING_CANCELLED', 'ENROLLED']; }


    public static withId(id: string): CLIENT_FILE_STATUS  {
        switch (id) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'ENROLLED': return CLIENT_FILE_STATUS.ENROLLED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'NO_SERVICE': return CLIENT_FILE_STATUS.NO_SERVICE; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + id);
        }
    }

    public static withName(name: string): CLIENT_FILE_STATUS  {
        switch (name) {
            case 'ACTIVE': return CLIENT_FILE_STATUS.ACTIVE; 
case 'LEAD': return CLIENT_FILE_STATUS.LEAD; 
case 'ENROLLED': return CLIENT_FILE_STATUS.ENROLLED; 
case 'AGREEMENT_SIGNED': return CLIENT_FILE_STATUS.AGREEMENT_SIGNED; 
case 'GRADUATED': return CLIENT_FILE_STATUS.GRADUATED; 
case 'PENDING_CANCELLED': return CLIENT_FILE_STATUS.PENDING_CANCELLED; 
case 'CANCELLED': return CLIENT_FILE_STATUS.CANCELLED; 
case 'NO_SERVICE': return CLIENT_FILE_STATUS.NO_SERVICE; 
case 'HOLD': return CLIENT_FILE_STATUS.HOLD; 
            default: throw new Error('Invalid id for CLIENT_FILE_STATUS: ' + name);
        }
    }

    public static copyMe(given: CLIENT_FILE_STATUS): CLIENT_FILE_STATUS  {
            return CLIENT_FILE_STATUS.withId(given.id)
    }

    public static fromJson(json: string): CLIENT_FILE_STATUS { return CLIENT_FILE_STATUS.withId(json); }

    constructor(public id: string, public name: string) {}

    public toJson(): string  { return this.id; }

    get index(): number  { return CLIENT_FILE_STATUS.list().indexOf(this); }
}
 


  


export class ClientFileBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientFileBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientFileBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientFileBasicInfo {
        const json: any = o ;
        return new ClientFileBasicInfo(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.clientFileNumber != null) ? json.clientFileNumber : '',
(json.clientFileStatus != null) ? CLIENT_FILE_STATUS.fromJson(json.clientFileStatus) : CLIENT_FILE_STATUS.DEFAULT_VALUE,
(json.clientBasicInfo != null) ? ClientBasicInfo.fromJson(json.clientBasicInfo) : new ClientBasicInfo()
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public clientFileNumber: string = '',
public clientFileStatus: CLIENT_FILE_STATUS = CLIENT_FILE_STATUS.DEFAULT_VALUE,
public clientBasicInfo: ClientBasicInfo = new ClientBasicInfo()
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
clientFileNumber: (this.clientFileNumber != null) ? this.clientFileNumber : undefined,
clientFileStatus: (this.clientFileStatus != null) ?  CLIENT_FILE_STATUS.copyMe(this.clientFileStatus).toJson() : undefined,
clientBasicInfo: (this.clientBasicInfo != null) ? this.clientBasicInfo.toJson() : undefined
        };
    }
    



}



export class ClientBasicInfo {

   
    public static fromJsonString(jsonString: string): ClientBasicInfo {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientBasicInfo.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientBasicInfo {
        const json: any = o ;
        return new ClientBasicInfo(
         (json.clientId != null) ? json.clientId : '',
(json.firstName != null) ? json.firstName : '',
(json.lastName != null) ? json.lastName : '',
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public firstName: string = '',
public lastName: string = '',
public emailId: string = '',
public mobile: string = '',
public leadSource: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined
        };
    }
    



}



export class RegisterClientForm {

   
    public static fromJsonString(jsonString: string): RegisterClientForm {
       const jsonObj: object = JSON.parse(jsonString);
       return RegisterClientForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): RegisterClientForm {
        const json: any = o ;
        return new RegisterClientForm(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : undefined
        );
    }



  


   
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public leadSource: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined
        };
    }
    



}



export class UpdateContactInfoInput {

   
    public static fromJsonString(jsonString: string): UpdateContactInfoInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateContactInfoInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateContactInfoInput {
        const json: any = o ;
        return new UpdateContactInfoInput(
         (json.clientId != null) ? json.clientId : '',
(json.newEmailId != null) ? json.newEmailId : undefined,
(json.newMobile != null) ? json.newMobile : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public newEmailId: string|undefined = undefined,
public newMobile: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
newEmailId: (this.newEmailId != null) ? this.newEmailId : undefined,
newMobile: (this.newMobile != null) ? this.newMobile : undefined
        };
    }
    



}



export class UpdateClientDetailsInput {

   
    public static fromJsonString(jsonString: string): UpdateClientDetailsInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UpdateClientDetailsInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UpdateClientDetailsInput {
        const json: any = o ;
        return new UpdateClientDetailsInput(
         (json.clientId != null) ? json.clientId : '',
(json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.newEmailId != null) ? json.newEmailId : undefined,
(json.newMobile != null) ? json.newMobile : undefined,
(json.leadSource != null) ? json.leadSource : "INH"
        );
    }



  


   
    constructor(
        public clientId: string = '',
public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public newEmailId: string|undefined = undefined,
public newMobile: string|undefined = undefined,
public leadSource: string = "INH"
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
newEmailId: (this.newEmailId != null) ? this.newEmailId : undefined,
newMobile: (this.newMobile != null) ? this.newMobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined
        };
    }
    



}



export class RegisterAndAddClientFileForm {

   
    public static fromJsonString(jsonString: string): RegisterAndAddClientFileForm {
       const jsonObj: object = JSON.parse(jsonString);
       return RegisterAndAddClientFileForm.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): RegisterAndAddClientFileForm {
        const json: any = o ;
        return new RegisterAndAddClientFileForm(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : "INH"
        );
    }



  


   
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public leadSource: string = "INH"
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined
        };
    }
    



}



export class RegisterAndEnrollInput {

   
    public static fromJsonString(jsonString: string): RegisterAndEnrollInput {
       const jsonObj: object = JSON.parse(jsonString);
       return RegisterAndEnrollInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): RegisterAndEnrollInput {
        const json: any = o ;
        return new RegisterAndEnrollInput(
         (json.firstName != null) ? json.firstName : '',
(json.middleName != null) ? json.middleName : undefined,
(json.lastName != null) ? json.lastName : undefined,
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.leadSource != null) ? json.leadSource : "KFS",
(json.mode != null) ? json.mode : 'MAN',
(json.orgCode != null) ? json.orgCode : ''
        );
    }



  


   
    constructor(
        public firstName: string = '',
public middleName: string|undefined = undefined,
public lastName: string|undefined = undefined,
public emailId: string = '',
public mobile: string = '',
public leadSource: string = "KFS",
public mode: string = 'MAN',
public orgCode: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          firstName: (this.firstName != null) ? this.firstName : undefined,
middleName: (this.middleName != null) ? this.middleName : undefined,
lastName: (this.lastName != null) ? this.lastName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
leadSource: (this.leadSource != null) ? this.leadSource : undefined,
mode: (this.mode != null) ? this.mode : undefined,
orgCode: (this.orgCode != null) ? this.orgCode : undefined
        };
    }
    



}



export class ClientSearchStore {

   
    public static fromJsonString(jsonString: string): ClientSearchStore {
       const jsonObj: object = JSON.parse(jsonString);
       return ClientSearchStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): ClientSearchStore {
        const json: any = o ;
        return new ClientSearchStore(
         (json.searchCriteria != null) ? SearchClientInput.fromJson(json.searchCriteria) : new SearchClientInput(),
(json.searchResultList != null) ? (json.searchResultList as object[]).map((x) => SearchClientOutput.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public searchCriteria: SearchClientInput = new SearchClientInput(),
public searchResultList: SearchClientOutput[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          searchCriteria: (this.searchCriteria != null) ? this.searchCriteria.toJson() : undefined,
searchResultList: (this.searchResultList != null) ? this.searchResultList.map((x) => x.toJson()) : []
        };
    }
    



}



export class SearchClientInput {

   
    public static fromJsonString(jsonString: string): SearchClientInput {
       const jsonObj: object = JSON.parse(jsonString);
       return SearchClientInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SearchClientInput {
        const json: any = o ;
        return new SearchClientInput(
         (json.nameContains != null) ? json.nameContains : undefined,
(json.mobileContains != null) ? json.mobileContains : undefined,
(json.emailId != null) ? json.emailId : undefined
        );
    }



  


   
    constructor(
        public nameContains: string|undefined = undefined,
public mobileContains: string|undefined = undefined,
public emailId: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          nameContains: (this.nameContains != null) ? this.nameContains : undefined,
mobileContains: (this.mobileContains != null) ? this.mobileContains : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined
        };
    }
    



}



export class SearchClientOutput {

   
    public static fromJsonString(jsonString: string): SearchClientOutput {
       const jsonObj: object = JSON.parse(jsonString);
       return SearchClientOutput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): SearchClientOutput {
        const json: any = o ;
        return new SearchClientOutput(
         (json.clientId != null) ? json.clientId : '',
(json.fullName != null) ? json.fullName : '',
(json.emailId != null) ? json.emailId : '',
(json.mobile != null) ? json.mobile : '',
(json.city != null) ? json.city : undefined,
(json.state != null) ? json.state : undefined
        );
    }



  


   
    constructor(
        public clientId: string = '',
public fullName: string = '',
public emailId: string = '',
public mobile: string = '',
public city: string|undefined = undefined,
public state: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientId: (this.clientId != null) ? this.clientId : undefined,
fullName: (this.fullName != null) ? this.fullName : undefined,
emailId: (this.emailId != null) ? this.emailId : undefined,
mobile: (this.mobile != null) ? this.mobile : undefined,
city: (this.city != null) ? this.city : undefined,
state: (this.state != null) ? this.state : undefined
        };
    }
    



}



}



export namespace FiNote {
  



  


export class FiNoteStore {

   
    public static fromJsonString(jsonString: string): FiNoteStore {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNoteStore.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNoteStore {
        const json: any = o ;
        return new FiNoteStore(
         (json.fiNoteList != null) ? (json.fiNoteList as object[]).map((x) => FiNote.fromJson(x)) : [],
(json.fiHighlightedNoteList != null) ? (json.fiHighlightedNoteList as object[]).map((x) => FiNote.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public fiNoteList: FiNote[] = [],
public fiHighlightedNoteList: FiNote[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          fiNoteList: (this.fiNoteList != null) ? this.fiNoteList.map((x) => x.toJson()) : [],
fiHighlightedNoteList: (this.fiHighlightedNoteList != null) ? this.fiHighlightedNoteList.map((x) => x.toJson()) : []
        };
    }
    



}



export class FiNote {

   
    public static fromJsonString(jsonString: string): FiNote {
       const jsonObj: object = JSON.parse(jsonString);
       return FiNote.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): FiNote {
        const json: any = o ;
        return new FiNote(
         (json.noteId != null) ? json.noteId : '',
(json.noteMesssage != null) ? json.noteMesssage : '',
(json.tagList != null) ? (json.tagList as object[]).map((x) => NoteTag.fromJson(x)) : [],
(json.createdOn != null) ? new Date(json.createdOn) : new Date(),
(json.createdBy != null) ? json.createdBy : '',
(json.updatedBy != null) ? json.updatedBy : undefined
        );
    }



  


   
    constructor(
        public noteId: string = '',
public noteMesssage: string = '',
public tagList: NoteTag[] = [],
public createdOn: Date = new Date(),
public createdBy: string = '',
public updatedBy: string|undefined = undefined
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined,
noteMesssage: (this.noteMesssage != null) ? this.noteMesssage : undefined,
tagList: (this.tagList != null) ? this.tagList.map((x) => x.toJson()) : [],
createdOn: (this.createdOn != null) ? this.createdOn.toISOString() : undefined,
createdBy: (this.createdBy != null) ? this.createdBy : undefined,
updatedBy: (this.updatedBy != null) ? this.updatedBy : undefined
        };
    }
    



}



export class AddNoteInput {

   
    public static fromJsonString(jsonString: string): AddNoteInput {
       const jsonObj: object = JSON.parse(jsonString);
       return AddNoteInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): AddNoteInput {
        const json: any = o ;
        return new AddNoteInput(
         (json.clientFileId != null) ? json.clientFileId : '',
(json.noteMessage != null) ? json.noteMessage : '',
(json.byWhom != null) ? json.byWhom : '',
(json.noteTagList != null) ? (json.noteTagList as object[]).map((x) => NoteTag.fromJson(x)) : []
        );
    }



  


   
    constructor(
        public clientFileId: string = '',
public noteMessage: string = '',
public byWhom: string = '',
public noteTagList: NoteTag[] = []
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          clientFileId: (this.clientFileId != null) ? this.clientFileId : undefined,
noteMessage: (this.noteMessage != null) ? this.noteMessage : undefined,
byWhom: (this.byWhom != null) ? this.byWhom : undefined,
noteTagList: (this.noteTagList != null) ? this.noteTagList.map((x) => x.toJson()) : []
        };
    }
    



}



export class NoteTag {

   
    public static fromJsonString(jsonString: string): NoteTag {
       const jsonObj: object = JSON.parse(jsonString);
       return NoteTag.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): NoteTag {
        const json: any = o ;
        return new NoteTag(
         (json.tagKey != null) ? json.tagKey : '',
(json.tagValue != null) ? json.tagValue : ''
        );
    }



  


   
    constructor(
        public tagKey: string = '',
public tagValue: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          tagKey: (this.tagKey != null) ? this.tagKey : undefined,
tagValue: (this.tagValue != null) ? this.tagValue : undefined
        };
    }
    



}



export class TagNoteInput {

   
    public static fromJsonString(jsonString: string): TagNoteInput {
       const jsonObj: object = JSON.parse(jsonString);
       return TagNoteInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): TagNoteInput {
        const json: any = o ;
        return new TagNoteInput(
         (json.noteId != null) ? json.noteId : '',
(json.byWhom != null) ? json.byWhom : '',
(json.tagKey != null) ? json.tagKey : '',
(json.tagValue != null) ? json.tagValue : ''
        );
    }



  


   
    constructor(
        public noteId: string = '',
public byWhom: string = '',
public tagKey: string = '',
public tagValue: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined,
byWhom: (this.byWhom != null) ? this.byWhom : undefined,
tagKey: (this.tagKey != null) ? this.tagKey : undefined,
tagValue: (this.tagValue != null) ? this.tagValue : undefined
        };
    }
    



}



export class UnTagNoteInput {

   
    public static fromJsonString(jsonString: string): UnTagNoteInput {
       const jsonObj: object = JSON.parse(jsonString);
       return UnTagNoteInput.fromJson(jsonObj);
    }
    // !=null checks for both null and undefined
    public static fromJson(o: object): UnTagNoteInput {
        const json: any = o ;
        return new UnTagNoteInput(
         (json.noteId != null) ? json.noteId : '',
(json.byWhom != null) ? json.byWhom : '',
(json.tagKey != null) ? json.tagKey : '',
(json.tagValue != null) ? json.tagValue : ''
        );
    }



  


   
    constructor(
        public noteId: string = '',
public byWhom: string = '',
public tagKey: string = '',
public tagValue: string = ''
    ) {}


    // !=null checks for both null and undefined
    public toJson(): object {
        return {
          noteId: (this.noteId != null) ? this.noteId : undefined,
byWhom: (this.byWhom != null) ? this.byWhom : undefined,
tagKey: (this.tagKey != null) ? this.tagKey : undefined,
tagValue: (this.tagValue != null) ? this.tagValue : undefined
        };
    }
    



}



}



